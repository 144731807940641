import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from 'react-redux-i18n';
import AdvancedInput from '../../../../../../components/shared/AdvancedInput/AdvancedInput';
import PaymentOrderFormReceivers from '../../receivers/PaymentOrderFormReceivers';
import { ViewType } from '../../../../../../app/enum/viewType';
import AdvancedButton from '../../../../../../components/shared/AdvancedButton/AdvancedButton';

class PaymentOrderFormBankSlip extends React.Component {
  render() {
    const { viewType, bankSlip, openModal, checkBilletBalance } = this.props;
    return (
      <>
        <Row gutter={16}>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.bankSlip.expirationDate.label')}
              value={bankSlip.expirationDate}
              type="date"
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.bankSlip.instructions.label')}
              value={bankSlip.instructions}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.bankSlip.amount.label')}
              value={bankSlip.amount}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
        </Row>

        {bankSlip.receivers && <PaymentOrderFormReceivers receivers={bankSlip.receivers} viewType={viewType} />}

        <div style={{display: 'flex', justifyContent: 'flex-end', gap: '20px'}}>
        <AdvancedButton
                 onClick={() => openModal("SALDO", ["SALDO"])}
                 text={"Pagar com saldo disponível"}
             />
             <AdvancedButton
                 disabled={checkBilletBalance()}
                 onClick={() => openModal("BOLETO", ["BOLETO", "SALDO"])}
                 text={"Bloquear saldo e emitir boleto"}
             />
        </div>
      </>
    )
  }
}

export default PaymentOrderFormBankSlip;