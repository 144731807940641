import React from 'react';
import {
  Input,
} from 'antd';

const AdvancedInput = (
  {
    label,
    placeholder,
    onChange,
    type,
    value,
    disabled,
    isPassword,
    isTextarea,
    name,
    required,
    maxLength,
    max
  },
) => (
  <div className="advanced-input">
    <label className="advanced-input__label">
      <span className="advanced-input__label__inner">{label}</span>
      {
        isPassword && (
          <Input.Password
            placeholder={placeholder}
            onChange={(ev) => onChange(ev.currentTarget.value)}
            type={type || 'text'}
            value={value}
            disabled={disabled || false}
            name={name}
          />
        )
      }
      {
        isTextarea && (
          <Input.TextArea
            placeholder={placeholder}
            onChange={(ev) => onChange(ev.currentTarget.value)}
            type={type || 'text'}
            value={value}
            disabled={disabled || false}
            autoSize={{ minRows: 4, maxRows: 10 }}
            allowClear
            name={name}
            maxLength={maxLength}
          />
        )
      }
      {
        !isTextarea && !isPassword && (
          <Input
            placeholder={placeholder}
            onChange={(ev) => onChange(ev.currentTarget.value)}
            type={type || 'text'}
            value={value}
            disabled={disabled || false}
            name={name}
            required={required}
            min={0}
            max={max}
          />
        )
      }
    </label>
  </div>
);

export default AdvancedInput;
