import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider,
  Layout, message, Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { DashboardOutlined } from '@ant-design/icons';
import { ApiActions } from '../../../../app/redux/actions';
import { CommandSelectors, LoadingSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import CommandForm from '../form/CommandForm';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_COMMAND_DETAILS } from '../../../../app/redux/actions/command';
import { GetIcon } from '../../../../app/enum/iconList';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';

class CommandDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      dataDetails: null,
    };
  }

  async componentDidMount() {
    const { getById, id } = this.props;

    this.setState({
      viewType: id !== 'add' ? ViewType.EDIT : ViewType.ADD,
    });

    try {
      await getById(id !== 'add' ? id : null);
      this.setState({ dataDetails: this.props.dataDetails });
    } catch (error) {
      //
    }
  }

  async submit(id, data) {
    const { update, create } = this.props;

    try {
      if (this.state.viewType === ViewType.ADD) {
        await create(data, () => {
          message.success(I18n.t('shared.messages.success.addSuccess'));
          navigate(I18n.t('routes.panel.admin.items.command.url'));
        });
      } else {
        await update(id, data, () => {
          message.success(I18n.t('shared.messages.success.successEdit'));
          navigate(I18n.t('routes.panel.admin.items.command.url'));
        });
      }
    } catch (e) {
      //
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType, dataDetails } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.admin.items.command.url')}>
                <span>{I18n.t('routes.panel.admin.items.command.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.admin.items.command.pageTitleAdd'
                      : 'routes.panel.admin.items.command.pageTitleView',
                  )
                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        {viewType === ViewType.EDIT && (
          <Content className="panel__layout__content panel__layout__content--breadcrumb">
            <div className="command-details">
              <div className="panel__layout__content__title">
                <h6 className="panel__layout__content__title__value">
                  {
                    I18n.t(
                      'routes.panel.admin.items.command.details.form.actions.label',
                    )
                  }
                </h6>
              </div>

              <div className="panel__layout__content__title__value__description">
                <Row>
                  <Col md={4}>
                    <AdvancedButton
                      type="button"
                      text={
                        I18n.t(
                          'routes.panel.admin.items.command.details.form.actions.commandChange'
                        )
                      }
                      onClick={() =>
                        navigate(`${I18n.t('routes.panel.admin.items.commandChange.url')}/${id}/alter-command`)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <AdvancedButton
                      type="button"
                      text={
                        I18n.t(
                          'routes.panel.admin.items.command.details.form.actions.auditCommand'
                        )
                      }
                      onClick={() =>
                        navigate(`${I18n.t('routes.panel.admin.items.commandAudit.url')}/${id}/audit-command`)
                      }
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
        )}


        <Content className="panel__layout__content">
          <div className="command-details">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.admin.items.command.icon'),
                      { fill: '#F37223', width: 24, height: 24 },
                    )}
                  </span>
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.admin.items.command.pageTitleAdd'
                        : 'routes.panel.admin.items.command.pageTitleView',
                    )
                  }
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.admin.items.command.pageDescriptionAdd'
                        : 'routes.panel.admin.items.command.pageDescriptionView',
                    )
                  }
                </p>
              </div>
              <Divider />
              <div>
                <Row>
                  <Col span={24}>
                    {(dataDetails || viewType === ViewType.ADD) && (
                      <CommandForm
                        id={id}
                        data={dataDetails}
                        submitFunction={(dataId, data) => this.submit(dataId, data)}
                        viewType={viewType}
                        loading={loading}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataDetails: CommandSelectors.getDataDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getById: (id) => dispatch(ApiActions.getById(id, ENDPOINTS.commands, ACTION_SAVE_COMMAND_DETAILS)),
  update: (id, data, callback) => dispatch(ApiActions.put(id, data, ENDPOINTS.commands, callback)),
  create: (data, callback) => dispatch(ApiActions.post(data, ENDPOINTS.commands, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommandDetails);
