import Immutable from 'seamless-immutable';
import { FeePayerActions } from '../actions';

const initialState = Immutable({
  dataPaginated: null,
  dataDetails: null,
});

export default function feePayer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case FeePayerActions.ACTION_SAVE_FEE_PAYER_PAGINATED:
      state = {
        ...state,
        dataPaginated: action.payload,
      };
      return state;
    case FeePayerActions.ACTION_SAVE_FEE_PAYER_DETAILS:
      state = {
        ...state,
        dataDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getDataPaginated(state) {
  return state.feePayer.dataPaginated;
}

export function getDataDetails(state) {
  return state.feePayer.dataDetails;
}
