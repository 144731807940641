import React from 'react';
import {
  Breadcrumb,
  Col,
  Layout, message, Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { DashboardOutlined } from '@ant-design/icons';
import { ApiActions } from '../../../../app/redux/actions';
import { LoadingSelectors, QrCodeSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { GetIcon } from '../../../../app/enum/iconList';
import QrCodeForm from '../form/qrCodeForm';
import { ACTION_SAVE_QR_CODE_DETAILS } from '../../../../app/redux/actions/qrCode';


class PaymentOrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.reprocessConciliation = this.reprocessConciliation.bind(this);
    this.state = {
      viewType: ViewType.EDIT,
      dataDetails: null,
    };
  }

  async reprocessConciliation(id){
    const { reconciliate } = this.props;
    try {
      await reconciliate(id);
      message.success('Reprocessamento de conciliação iniciado com sucesso');
    } catch (error) {
      message.error('Erro ao iniciar reprocessamento de conciliação');
    }
  }
  

  async componentDidMount() {
    const { getById, id, clean, getBalance, getUserById } = this.props;

    this.setState({
      viewType: id !== 'add' ? ViewType.EDIT : ViewType.ADD,
    });

    try {

      await getById(id !== 'add' ? id : null);

      this.setState({ dataDetails: this.props.dataDetails });

    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType, dataDetails } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <>
        <Content id="content" className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.admin.items.qrCode.url')}> 
                <span>{I18n.t('routes.panel.admin.items.qrCode.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.admin.items.qrCode.pageTitleAdd'
                      : 'routes.panel.admin.items.qrCode.pageTitleView',
                  )
                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="payment-order-details">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.admin.items.paymentOrder.icon'),
                      { fill: '#F37223', width: 24, height: 24 },
                    )}
                  </span>
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.admin.items.qrCode.pageTitleAdd'
                        : 'routes.panel.admin.items.qrCode.pageTitleView',
                    )
                  }
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.admin.items.qrCode.pageDescriptionAdd'
                        : 'routes.panel.admin.items.qrCode.pageDescriptionView',
                    )
                  }
                </p>
              </div>
              <div>
                <Row>
                  <Col span={24}>
                  {dataDetails && (
                        <QrCodeForm id={id} data={dataDetails} reprocessConciliation={this.reprocessConciliation} />
                  )}

                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataDetails: QrCodeSelectors.getDataDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getById: (id) => dispatch(ApiActions.getById(id, ENDPOINTS.pix, ACTION_SAVE_QR_CODE_DETAILS)),
  reconciliate: (id) => dispatch(ApiActions.post(null, `${ENDPOINTS.pix}/${id}/requeue-conciliation`)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentOrderDetails);
