import React from 'react';
import
Icon, {
  DownOutlined, MenuUnfoldOutlined, MenuFoldOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Menu,
  Row,
  Col,
  Avatar,
  Dropdown,
  notification,
  Button,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Helmet } from 'react-helmet';
import { Link } from '@reach/router';
import { getInitials, slugify } from '../utils/string';
import * as AuthSelectors from '../redux/reducers/auth';
import AdvancedButton from '../../components/shared/AdvancedButton';
import { AuthActions } from '../redux/actions';
import { GetIcon } from '../enum/iconList';
import keycloak from '../../keycloak';

const { SubMenu } = Menu;

class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownVisible: false,
      openKeys:
        (!this.props.collapsed
          && [window.location.pathname.replace(/\/([^/]*)$/, ' $1').split(' ')[0]])
        || [],
    };
    if (!this.props.me) {
      this.props.getMe();
    }
    this.menuItems = Object.values(I18n.t('routes.panel')).filter((o) => o.sidebarVisible === 'y');
    this.rootSubmenuKeys = this.menuItems.map((item) => item.url);


  }

  componentDidMount() {
    window.scrollTo({ top: 0 });
  }

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find((key) => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  }

  getCurrent = () => window.location.pathname;

  getCurrentSubMenu() {
    const path = window.location.pathname;
    const pathSplitted = path.split('/');

    let theIndex = null;

    if (pathSplitted.length >= 4) {
      this.menuItems.forEach((o, i) => {
        const sidebarValue = slugify(o.sidebarTitle.toLowerCase());
        const pathValue = pathSplitted[2].replace(/-/g, ' ').toLowerCase();

        if (sidebarValue === pathValue) {
          theIndex = i.toString();
        }
      });
    }

    return theIndex;
  }

  openNotification() {
    try {
      const { logout } = this.props;
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            logout();
            notification.close(key);
          }}
        >
          {I18n.t('shared.logout.confirmButton')}
        </Button>
      );
      notification.open({
        message: I18n.t('shared.logout.confirmation'),
        btn,
        key,
      });
    } catch (err) {
      //
    }
  }

  logout(){
    keycloak.logout();
    this.props.logout();
  }

  render() {
    const {
      title,
      container,
      id,
      me,
      collapsed,
      toggle,
    } = this.props;
    const { dropdownVisible } = this.state;
    const { Header, Sider } = Layout;

    const menuItems = Object.values(I18n.t('routes.panel')).filter((o) => o.sidebarVisible === 'y');
    return (
      <Layout className="panel__layout">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width="260"
        >
          <div className="logo" />

          <Menu
            theme="dark"
            mode="inline"
            className="panel__layout__menu"
            selectedKeys={[this.getCurrent()]}
            openKeys={(this.state.openKeys) || []}
            onOpenChange={this.onOpenChange}
          >
            <div className="panel__layout__menu__img__wrapper">
              <img
                alt="sidebar logo"
                className="panel__layout__menu__img"
                src="/assets/img/logo_white.svg"
              />
            </div>
            <div className="panel__layout__userinfo">
              <div className="panel__layout__userinfo__name">
                <p>{me && me.name}</p>
              </div>
              <div className="panel__layout__userinfo__company">
                <p>{me && me.company ? me.company.name : I18n.t('enum.profileType.admin')}</p>
              </div>
            </div>

            {menuItems.map((item, index) => (
              
              <SubMenu
                key={item.url || index}
                className="panel__layout__menu__submenu"
                title={(
                  <span className="panel__layout__menu__submenu__inner">
                    {GetIcon(item.icon)}
                    <span className="panel__layout__menu__submenu__inner__title">{item.sidebarTitle}</span>
                  </span>
                )}
              >
                {Object.values(item.items)
                .filter((o) => o.sidebarVisible !== 'n')
                .map((subItem) => (
                  <Menu.Item key={subItem.url}>
                    <Link to={subItem.url}>
                      <span className="panel__layout__menu__submenu__item">
                        {subItem.sidebarTitle}
                      </span>
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        </Sider>
        <Layout className="panel__layout__wrapper">
          <Header className="panel__layout__header">
            <Row>
              <Col span={18}>
                <Icon
                  component={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
                  className="panel__trigger_button"
                  onClick={() => {
                    if (!collapsed) {
                      this.setState({ openKeys: [] });
                    } else {
                      this.setState({
                        openKeys:
                          [window.location.pathname.replace(/\/([^/]*)$/, ' $1').split(' ')[0]],
                      });
                    }
                    toggle();
                  }}
                />
              </Col>
              <Col span={6}>
                <div className="panel__layout__header__avatar">
                  <Dropdown
                    onVisibleChange={(ev) => this.setState({ dropdownVisible: ev })}
                    overlay={(
                      <Menu>
                        <Menu.Item>
                          <div className="panel__layout__header__avatar__userdata">
                            <strong>
                              {me && me.name}
                              <br />
                            </strong>
                            <small>{me && me.email}</small>
                            {me && me.company && (
                              <span className="panel__layout__header__avatar__userdata__company">
                                {me.company.name}
                              </span>
                            )}
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <AdvancedButton
                            onClick={() => this.logout()}
                            text={I18n.t('shared.logout.menuText')}
                            type="link"
                          />
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Avatar
                      className="panel__layout__header__avatar__item"
                      size={32}
                    >
                      {me && getInitials(me.name)}
                    </Avatar>
                  </Dropdown>
                  <DownOutlined
                    className="panel__layout__header__avatar__down"
                    rotate={dropdownVisible ? 180 : 0}
                  />
                </div>
              </Col>
            </Row>
          </Header>

          {(me || true) && (id ? React.cloneElement(container, { id }) : container)}

          <div className="panel__layout__content__footer">
            {I18n.t('application.footer.poweredBy')}&nbsp;
            <a
              href={I18n.t('application.footer.poweredByBrandUrl')}
              target="_blank"
              rel="noopener noreferrer"
              type="link"
            >
              {I18n.t('application.footer.poweredByBrand')}
            </a>
            - {I18n.t('application.version.title')}: {I18n.t('application.version.number')}
          </div>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: AuthSelectors.isAuthenticated(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: (user) => dispatch(AuthActions.logout(user)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Panel);
