import React from 'react';
import { Breadcrumb, Spin, message
} from 'antd';
import { I18n } from 'react-redux-i18n';
import {
DashboardOutlined
} from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { ApiActions } from '../../../app/redux/actions';
import { ENDPOINTS } from '../../../app/endpoints/endpoints';
import { connect } from 'react-redux';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import ErrorSuccessTables from './TableResult';

class AdjustmentConciliation extends React.Component {
  constructor(props) {
    super(props);

    this.handleUpload = this.handleUpload.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.state = {
        file: null,
        data: null,
        loading: false,
        filter: null,
        data: [],
        currentMessageIndex: 0,
        polling: false,
    }

  }
  
  handleFileChange(e) {
    if(e.target.files) {
        this.setState({ file: e.target.files[0] })
    }
  }
  
  handleFilter(e){
    this.setState({filter: e.target.value});
  }

   async handleUpload(){
    const { sendFileConciliation } = this.props;

    try {
      this.setState({loading: true});
      const { file, filter } = this.state;
      const formData = new FormData();
      formData.append('file', file);

      const response = await sendFileConciliation(formData, filter);
      message.success('Arquivo enviado com sucesso');

      this.setState({data: response});


    } catch (error) {
      console.log(error);
      message.error(I18n.t('shared.messages.errors.generic'));
      this.setState({loading: false})
    }finally {
      this.setState({loading: false})
    }
  }

  render() {

    const { loading, filter, file, data } = this.state;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.admin.items.adjustmentConciliation.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        {loading ? <Spin size='default'></Spin> :
          <Content className="panel__layout__content">
            <h2>Escolha o tipo de arquivo de conciliação</h2> 
            <div style={{display: 'flex', gap: "12px", alignItems: 'center'}}>
              <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <input style={{width: '16px', height: '16px'}} checked={filter === "CARTAO_CREDITO"} type='checkbox' id='card' name="card" value={"CARTAO_CREDITO"} onChange={(e) => this.handleFilter(e)}></input>
                <label style={{margin: '0px', fontSize: '16px'}} for="card">Cartão de Crédito</label>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <input style={{width: '16px', height: '16px'}} checked={filter === "PIX_QR_CODE"} type='checkbox' id='pix' name="pix" value={"PIX_QR_CODE"} onChange={(e) => this.handleFilter(e)}></input>
                <label style={{margin: '0px', fontSize: '16px'}} for="pix">PIX</label>
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '12px'}}>
                <label style={{fontSize: '16px', margin: '0px'}} for='file'>Insira arquivo de conciliacão</label>
                <input disabled={!filter} type='file' id='file' name='file' accept='.csv' onChange={this.handleFileChange} ></input>

                <AdvancedButton
                  text={'Enviar'}
                  disabled={!filter || !file } 
                  style={{width: '100px'}}
                  onClick={this.handleUpload}
                />
            </div>
            {data && data.length > 0 &&
                <ErrorSuccessTables data={data} filter={filter} />
            }
        </Content>
        }
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sendFileConciliation: (file, filter, callback) => dispatch(ApiActions.sendFileConciliation(file, `${ENDPOINTS.reprocessConciliation}/${filter}`, callback)),
});


export default connect(
  null,
  mapDispatchToProps,
)(AdjustmentConciliation);
