import React from 'react';
import { Breadcrumb, Col, Divider, Layout, message, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { DashboardOutlined } from '@ant-design/icons';
import { ApiActions } from '../../../../app/redux/actions';
import { LoadingSelectors, UserSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import AdministratorForm from '../form/AdministratorForm';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_USER_DETAILS } from '../../../../app/redux/actions/user';
import { GetIcon } from '../../../../app/enum/iconList';

class AdministratorDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      dataDetails: null,
    };
  }

  async componentDidMount() {
    const { getById, id } = this.props;

    this.setState({
      viewType: id !== 'add' ? ViewType.EDIT : ViewType.ADD,
    });

    try {
      await getById(id !== 'add' ? id : null);
      this.setState({ dataDetails: this.props.dataDetails });
    } catch (error) {
      //
    }
  }

  async submit(id, data) {
    const { update, create } = this.props;

    delete data.passwordConfirmation;

    try {
      if (this.state.viewType === ViewType.ADD) {
        await create(data, () => {
          message.success(I18n.t('shared.messages.success.addSuccess'));
          navigate(I18n.t('routes.panel.admin.items.administrator.url'));
        });
      } else {
        await update(id, data, () => {
          message.success(I18n.t('shared.messages.success.successEdit'));
          navigate(I18n.t('routes.panel.admin.items.administrator.url'));
        });
      }
    } catch (e) {
      //
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType, dataDetails } = this.state;
    const { loading, id } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.admin.items.administrator.url')}>
                <span>{I18n.t('routes.panel.admin.items.administrator.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {I18n.t(
                  viewType === ViewType.ADD
                    ? 'routes.panel.admin.items.administrator.pageTitleAdd'
                    : 'routes.panel.admin.items.administrator.pageTitleEdit'
                )}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrator-details">
            <Spin spinning={loading > 0} tip={I18n.t('shared.loading')}>
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(I18n.t('routes.panel.admin.items.administrator.icon'), {
                      fill: '#F37223',
                      width: 24,
                      height: 24,
                    })}
                  </span>
                  {I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.admin.items.administrator.pageTitleAdd'
                      : 'routes.panel.admin.items.administrator.pageTitleEdit'
                  )}
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.admin.items.administrator.pageDescriptionAdd'
                      : 'routes.panel.admin.items.administrator.pageDescriptionEdit'
                  )}
                </p>
              </div>
              <Divider />
              <div>
                <Row>
                  <Col span={24}>
                    {(dataDetails || viewType === ViewType.ADD) && (
                      <AdministratorForm
                        id={id}
                        data={dataDetails}
                        submitFunction={(dataId, data) => this.submit(dataId, data)}
                        viewType={viewType}
                        loading={loading}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataDetails: UserSelectors.getDataDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getById: (id) => dispatch(ApiActions.getById(id, ENDPOINTS.suser, ACTION_SAVE_USER_DETAILS)),
  update: (id, data, callback) => dispatch(ApiActions.put(id, data, ENDPOINTS.suser, callback)),
  create: (data, callback) => dispatch(ApiActions.post(data, `${ENDPOINTS.suser}`, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorDetails);
