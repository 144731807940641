import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, message, Divider } from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import { ViewType } from '../../../../app/enum/viewType';


class SettingsForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {
				key: null,
				value: null,

			},
		};
	}

	componentDidMount() {
		const { data, id } = this.props;
		if (data && id !== 'add') {
			this.setState({

				form: {
					key: data && data.key,
					value: data && data.value,

				},
			});
		}
	}

	onSubmit(e) {
		e.preventDefault();

		const { form } = this.state;

		const scheman = yup.object().shape({
			key: yup.string().nullable({ isNullable: false }).required(I18n.t('shared.messages.errors.invalid_key')),
			value: yup
				.string()
				.nullable({ isNullable: false })
				.required(I18n.t('shared.messages.errors.invalid_value')),
		});

		scheman
			.validate(form)
			.then(() => {
				const { data, submitFunction } = this.props;
				try {
					submitFunction(data ? data.id : null, form);
				} catch (error) {
					//
				}
			})
			.catch((error) => {
				message.error(error.message);
			});
	}



	fieldChange(name, value) {
		const { form } = this.state;
		form[name] = value;
		this.setState({ form });
	}

	render() {
		const { loading, viewType } = this.props;
		const { form } = this.state;
		return (
			<Row>
				{loading === 0 && (
					<Col span={24}>
						<form name="settingsForm" onSubmit={(ev) => this.onSubmit(ev)}>
							<Row gutter={16}>
								<Col span={12}>
									<AdvancedInput
										label={I18n.t('routes.panel.admin.items.settings.details.form.key')}
										value={form && form.key}
										disabled={viewType === ViewType.EDIT || viewType === ViewType.VIEW}
										onChange={(val) => this.fieldChange('key', val)}
									/>
								</Col>
								<Col span={12}>
									<AdvancedInput
										label={I18n.t('routes.panel.admin.items.settings.details.form.value')}
										value={form && form.value}
										disabled={viewType === ViewType.VIEW}
										onChange={(val) => this.fieldChange('value', val)}

									/>
								</Col>
							</Row>

							<Divider />

							<Row>
								<Col span={24} className="text-right">
									<AdvancedButton
										type="link"
										text={I18n.t('shared.cancel')}
										href={I18n.t('routes.panel.admin.items.settings.url')}
									/>
									<Divider className="form-button-divider" type="vertical" />

									<AdvancedButton htmlType="submit" text={I18n.t('shared.save')} icon={<SaveOutlined />}
										disabled={viewType === ViewType.VIEW} />
								</Col>
							</Row>
						</form>
					</Col>
				)}
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: LoadingSelectors.getLoading(state),
});

export default connect(mapStateToProps, null)(SettingsForm);
