export const SafeBalanceStatus = {
  LOCKED: 1,
  TRANSFER: 2,
  PENDING: 3,
  UNLOCK: 4,
  RESERVED: 5,
};

export const SafeBalanceStatusMap = new Map([
  [SafeBalanceStatus.LOCKED, 'locked'],
  [SafeBalanceStatus.TRANSFER, 'transfer'],
  [SafeBalanceStatus.PENDING, 'pending'],
  [SafeBalanceStatus.UNLOCK, 'unlocked'],
  [SafeBalanceStatus.RESERVED, 'reserved'],
]);
