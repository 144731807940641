import Axios from 'axios';
import AuthServices from '../services/auth';
import { handleAxiosError } from '../utils/api';
import config from '../../app.config';

function getFinancialInstance(noTimeout, isFile) {
  const auth = AuthServices.get();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: auth && auth.token ? `Bearer ${auth.token}` : undefined,
    'api-access-key': '789f0155-07f7-442e-baf5-acdf602b9890'
  };

  const axiosInstance = Axios.create({
    baseURL: config.apiFinancial,
    timeout: noTimeout ? 0 : 30000,
    responseType: isFile ? 'arraybuffer' : 'json',
    headers,
  });

  axiosInstance.interceptors.response.use((response) => response,
    async (err) => {
      return Promise.reject(handleAxiosError(err));
    });

  return axiosInstance;
}

export default getFinancialInstance;
