import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {ReactComponent as UploadIcon} from '../../../app/assets/img/upload_purple.svg';


const Dropzone = ({ onDropComplete, options, files, ...props }) => {

  const onDrop = useCallback(file => {

    onDropComplete(file);
  }, [])


  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, ...options })

  return (
      <div
        {...getRootProps()}
        {...props}
      >
        <input {...getInputProps()} />

        <div className='financial__dropzone'>
                  <UploadIcon />
                  <span className='financial__modalsubTitle'>Arraste e solte arquivos ou <span className='financial__purpleText'>selecione em seu computador</span></span>
              </div>
      </div>
  )
}

export default Dropzone