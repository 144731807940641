import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message, Breadcrumb, Collapse, Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  DeleteOutlined, SearchOutlined, EnterOutlined, DashboardOutlined, PlusCircleOutlined, RetweetOutlined
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import DataTable from '../../../../components/shared/DataTable';
import { ApiActions } from '../../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, CommandSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedRadio from '../../../../components/shared/AdvancedRadio/AdvancedRadio';
import AdvancedRangePicker from '../../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_COMMANDS_PAGINATED } from '../../../../app/redux/actions/command';
import { GetIcon } from '../../../../app/enum/iconList';
import { CommandStatusMap } from '../../../../app/enum/commandStatus';
import {
  getCommandProcessedOptions, getCommandStatusOptions
} from '../../../../app/utils/radioOptions';

class CommandList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        id: null,
        commandProcessed: null,
        status: null,
        sourceOp: null,
        transferAt: null,

        transferAtRange: null,
      },
    };
    this.dataTableRef = React.createRef();
  }

  validityChange(key, keyRange, dates) {
    const { params } = this.state;

    const value = dates[0].format('YYYY-MM-DD').toString().concat(',',
      dates[1].format('YYYY-MM-DD').toString()
    );

    this.setState({
      params: {
        ...params,
        [key]: value,
        [keyRange]: dates,
      },
    });
  }

  getPaginated(params) {
    params = {
      ...params,
    };
    const { getPaginated } = this.props;
    this.setState({ params: { ...params } });
    getPaginated(params);
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('shared.messages.success.deleteSuccess'));
      this.dataTableRef.current.reset();
    });
  }

  resend(id) {
    this.props.resend(id, () => {
      message.success(I18n.t('shared.messages.success.resendSuccess'));
      this.dataTableRef.current.reset();
    });
  }

  resendRe(id) {
    this.props.resendRe(id, () => {
      message.success(I18n.t('shared.messages.success.resendSuccess'));
      this.dataTableRef.current.reset();
    });
  }
  resendAll() {
    const { transferAt } = this.state.params
    const params = {}
    if (transferAt) {
      const [createdAt, endAt] = transferAt.split(',')
      params.createdAt = createdAt
      params.endAt = endAt
    }
    this.props.resendAll(params, (res) => {
      if (res === true) {
        message.success(I18n.t('shared.messages.success.resendSuccess'))
      } else {
        message.error(I18n.t('shared.messages.errors.resendError'))
      }

      this.dataTableRef.current.reset();
    });
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const { dataPaginated, loading, me } = this.props;
    const { params } = this.state;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.admin.items.command.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={I18n.t('shared.advancedFilters.header')}
              key="0"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedRadio
                    label={I18n.t('shared.advancedFilters.commandProcessed')}
                    value={params && params.commandProcessed}
                    onChange={(val) => this.fieldChange('commandProcessed', val)}
                    options={getCommandProcessedOptions()}
                    optionType="default"
                  />
                </Col>
                <Col span={8}>
                  <AdvancedRadio
                    label={I18n.t('shared.advancedFilters.status')}
                    value={params && params.status}
                    onChange={(val) => this.fieldChange('status', val)}
                    options={getCommandStatusOptions()}
                    optionType="default"
                  />
                </Col>
                <Col span={8}>
                  <AdvancedRangePicker
                    label={I18n.t('shared.advancedFilters.transferAt')}
                    value={params && params.transferAtRange}
                    onChange={(val) => this.validityChange('transferAt', 'transferAtRange', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.sourceOp')}
                    value={params && params.sourceOp}
                    onChange={(val) => this.fieldChange('sourceOp', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.id')}
                    value={params && params.id}
                    onChange={(val) => this.fieldChange('id', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilters.clearButtonText')}
                    onClick={() => this.getPaginated()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilters.filterButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.getPaginated({ ...params, offset: 0 })}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.admin.items.command.icon'),
                      { fill: '#F37223', width: 24, height: 24 },
                    )}
                  </span>
                  {I18n.t('routes.panel.admin.items.command.pageTitle')}
                </h2>
              </Col>
              <Col className="text-right" span={12}>
                <AdvancedButton
                  text={I18n.t('routes.panel.admin.items.command.resendAllButton')}
                  icon={GetIcon(I18n.t('routes.panel.admin.items.command.icon'))}
                  onClick={() => this.resendAll()}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginated(parameters)}
                data={dataPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                params={params}
                showSizeChanger
                pageSizeOptions={
                  dataPaginated
                    && dataPaginated.count
                    && dataPaginated.count > 100
                    ? ['10', '50', '100', dataPaginated.count.toString()] : ['10', '20', '50', '100']
                }
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.admin.items.command.dataTable.columns.externalId.key'),
                      title: I18n.t('routes.panel.admin.items.command.dataTable.columns.externalId.title'),
                      render: (value) => (value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.command.dataTable.columns.operation.key'),
                      title: I18n.t('routes.panel.admin.items.command.dataTable.columns.operation.type.title'),
                      render: (value) => (value && value.type) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.command.dataTable.columns.sourceOp.key'),
                      title: I18n.t('routes.panel.admin.items.command.dataTable.columns.sourceOp.title'),
                      render: (value) => (value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.command.dataTable.columns.status.key'),
                      title: I18n.t('routes.panel.admin.items.command.dataTable.columns.status.title'),
                      render: (value) => (value && CommandStatusMap.get(value)) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.command.dataTable.columns.commandProcessed.key'),
                      title: I18n.t('routes.panel.admin.items.command.dataTable.columns.commandProcessed.title'),
                      render: (value) => (value ? I18n.t('shared.yes') : I18n.t('shared.no')) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.command.dataTable.columns.transferAt.key'),
                      title: I18n.t('routes.panel.admin.items.command.dataTable.columns.transferAt.title'),
                      render: (value) => (value && DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm')) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.command.dataTable.columns.commandProcessedAt.key'),
                      title: I18n.t('routes.panel.admin.items.command.dataTable.columns.commandProcessedAt.title'),
                      render: (value) => (value && DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm')) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.command.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.admin.items.command.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.command.dataTable.columns.actions.key'),
                      title: '',
                      render: (id, status) => (
                        <div className="dataTable__item--right">
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.remove(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                            disabled={me && me.id === id}
                          >
                            <Tooltip
                              title={I18n.t('shared.remove')}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <Button
                                type="link"
                                icon={<DeleteOutlined />}
                                disabled={me && me.id === id}
                              />
                            </Tooltip>
                          </Popconfirm>
                          <Tooltip
                            title={I18n.t('shared.details')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<PlusCircleOutlined />}
                              onClick={() => navigate(`${I18n.t('routes.panel.admin.items.command.url')}/${id}`)}
                            />
                          </Tooltip>
                          <Tooltip
                            title={I18n.t('shared.Webhook.command')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<RetweetOutlined />}
                              disabled={me && me.id === id}
                              onClick={() => this.resend(id)}
                            />
                          </Tooltip>
                          {status.status === 2 &&
                            <Tooltip
                              title={I18n.t('shared.Webhook.commandRe')}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <Button
                                type="link"
                                icon={<EnterOutlined />}
                                disabled={me && me.id === id}
                                onClick={() => this.resendRe(id)}
                              />
                            </Tooltip>
                          }
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: CommandSelectors.getDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(
    ApiActions.getPaginated(parameters, ENDPOINTS.commands, ACTION_SAVE_COMMANDS_PAGINATED),
  ),
  remove: (id, callback) => dispatch(ApiActions.remove(id, ENDPOINTS.commands, callback)),
  resend: (id, callback) => dispatch(ApiActions.resendCommand(id, ENDPOINTS.commands, callback)),
  resendRe: (id, callback) => dispatch(ApiActions.resendReCommand(id, ENDPOINTS.commands, callback)),
  resendAll: (params, callback) => dispatch(ApiActions.resendAllCommand(params, ENDPOINTS.commands, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommandList);
