import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider } from 'antd';
import { I18n } from 'react-redux-i18n';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import { PaymentOrderStatus } from '../../../../app/enum/paymentOrderStatus';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import AdvancedTabs, { TabPane } from '../../../../components/shared/AdvancedTabs/AdvancedTabs';
import UsersFormGeneral from '../../users/form/general/UsersFormGeneral';
import UserFormPaymentOrder from '../form/paymentOrders/UserFormPaymentOrder'
class UserSearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        externalId: null,
          accountId:null,
          linkedAccountId: null,
          name: null,
          photoUrl: null,
          email: null,
          cellphone: null,
          taxIdentifier:null,
          personType: null,
          mothersName:null,
          birthDate: null,
          gender: null,
          city: null,
          complement: null,
          country:null,
          district: null,
          number: null,
          state: null,
          street: null,
          uf: null,
          zipcode: null,
          accountType: null
      },
      formOp: {
        code: null,
        status: PaymentOrderStatus.WAITING_PAYMENT,
        externalIdentifier: null,
        message: null,
        totalAmount: null,
        currency: null,
        expirationDate: null,
        feePayer: null,
        selectPaymentAt: null,
        customerSelectedInstallments: null,
        selectPaymentMethod: null,
        safeBalance: null,
        items: null,
        workflow: null,
        customer: null,
        metadata: null,
        config: null,
        transactions: null,
        paymentOrderList: null,
      },
      activeGeneralTab: '1',
      activePaymentOrderTab: '1',
    };
  }

  componentDidMount() {
    const { data, id, dataType } = this.props;
    if (data && id !== 'add') {
      this.setState({
        form: {
          id: data && data.id,
          externalId: data && data.externalId,
          accountId: data && data.accountId,
          linkedAccountId: data && data.linkedAccountId,
          name: data && data.name,
          photoUrl: data && data.photoUrl,
          email: data && data.email,
          cellphone: data && data.cellphone,
          taxIdentifier: data && data.taxIdentifier,
          personType: data && data.personType,
          mothersName: data && data.mothersName,
          birthDate: data && data.birthDate,
          gender: data && data.gender,
          city: data && data.address.city,
          complement: data && data.address.complement,
          country: data && data.address.countryName,
          district: data && data.address.district,
          number: data && data.address.number,
          state: data && data.address.state,
          street: data && data.address.street,
          uf: data && data.address.uf,
          zipcode: data && data.address.zipCode,
          accountType: dataType && dataType
        },
        paymentOrder: {
          country: data && data.paymentOrderList.country,
          code: data && data.paymentOrderList.code,
          status: data && data.paymentOrderList.status,
          externalIdentifier: data && data.paymentOrderList.externalIdentifier,
          message: data && data.paymentOrderList.message,
          totalAmount: data && data.paymentOrderList.totalAmount,
          currency: data && data.paymentOrderList.currency,
          expirationDate: data && data.paymentOrderList.expirationDate,
          feePayer: data && data.paymentOrderList.feePayer,
          selectPaymentAt: data && data.paymentOrderList.selectPaymentAt,
          customerSelectedInstallments: data && data.paymentOrderList.customerSelectedInstallments,
          selectPaymentMethod: data && data.paymentOrderList.selectPaymentMethod,
          safeBalance: data && data.paymentOrderList.safeBalance,
          customerId: data && data.paymentOrderList.customerId,
          dateConclusionSale: data && data.paymentOrderList.dateConclusionSale,
          name: data && data.paymentOrderList.name,
          taxIdentifier: data && data.paymentOrderList.taxIdentifier,
        },
        activeGeneralTab: '1',
        activePaymentOrderTab: '1',
      });
    }
  }

  render() {
    const { viewType, data } = this.props;
    const {  form, activeGeneralTab, activePaymentOrderTab } = this.state;
    return (
      <Row>
          <Col span={24}>
            <form name="userSearchForm" onSubmit={(ev) => this.onSubmit(ev)}>
                <AdvancedTabs
                  active={activeGeneralTab}
                  onChange={(val) => {
                  this.setState({ activeGeneralTab: val });
                }}
                >
                <TabPane key="1" tab={I18n.t('routes.panel.admin.items.search.details.form.tabs.general')}>
                  <UsersFormGeneral general={form} viewType={viewType} />
                </TabPane>
                <TabPane key="2" tab={I18n.t('routes.panel.admin.items.search.details.form.tabs.paymentOrder')}>
                  <AdvancedTabs
                    active={activePaymentOrderTab}
                    onChange={(val) => {
                      this.setState({ activePaymentOrderTab: val });
                    }}
                  >
                    <UserFormPaymentOrder general={form} paymentOrder={data.paymentOrderList} viewType={viewType}/>
                  </AdvancedTabs>
                </TabPane>
              </AdvancedTabs>
              <Divider />
              <Row gutter={16}>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.cancel')}
                    href={I18n.t('routes.panel.admin.items.search.url')}
                  />
                </Col>
              </Row>
            </form>
          </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

export default connect(
  mapStateToProps,
  null,
)(
  UserSearchForm,
);
