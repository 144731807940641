import React from 'react';
import {
  Popconfirm,
  Button,
  Row,
  Col,
  message, Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  DeleteOutlined, RetweetOutlined, PlusCircleOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import DataTable from '../../../../../components/shared/DataTable';
import { ApiActions } from '../../../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, PaymentOrderSelectors } from '../../../../../app/redux/reducers';
import * as DateUtils from '../../../../../app/utils/date';
import { ENDPOINTS } from '../../../../../app/endpoints/endpoints';
import { ACTION_CLEAN_PAYMENT_ORDER, ACTION_SAVE_USER_PAYMENT_ORDERS_PAGINATED } from '../../../../../app/redux/actions/paymentOrder';
import { GetIcon } from '../../../../../app/enum/iconList';
import { PaymentOrderStatusMap } from '../../../../../app/enum/paymentOrderStatus';
import { maskCpfOrCnpj } from '../../../../../app/utils/mask';

class UserFormPaymentOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentOrder: {
          id: null,
        code: null,
        externalIdentifier: null,
        userName: null,
        userTaxIdentifier: null,
        selectPaymentMethod: null,
        selectPaymentAt: null,
        status: null,
        expirationDate: null,
        createdAt: null,
        safeBalance: null,
        feePayer: null,
        expirationDateRange: null,
        createdAtRange: null,
        selectPaymentAtRange: null,
      },
    };
    this.dataTableRef = React.createRef();
  }

  async componentDidMount() {
    const { clean } = this.props;
    await clean();
  }

  validityChange(key, keyRange, dates) {
    const { params } = this.state;

    const value = dates[0].format('YYYY-MM-DD').toString().concat(',',
      dates[1].format('YYYY-MM-DD').toString()
    );

    this.setState({
      params: {
        ...params,
        [key]: value,
        [keyRange]: dates,
      },
    });
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  getPaginated(params) {
    params = {
      ...params,
    };
    const { getPaginated } = this.props;
    this.setState({ params: { ...params } });
    getPaginated(params);
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('shared.messages.success.deleteSuccess'));
      this.dataTableRef.current.reset();
    });
  }

  resend(id) {
    this.props.resend(id, () => {
      message.success(I18n.t('shared.messages.success.resendSuccess'));
      this.dataTableRef.current.reset();
    });
  }

  render() {
    const {  loading, me, paymentOrder, general } = this.props;

    const data = paymentOrder.map((obj) =>( {
      ...obj,
      customer: {
        accountId: general.accountId,
        name: general.name ,
        taxIdentifier: general.taxIdentifier,
    },
    }));

    const userPaymentOrders = {
      count: data.length,
      rows: data,
    }
    return (
      <>
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.admin.items.paymentOrder.icon'),
                      { fill: '#F37223', width: 24, height: 24 },
                    )}
                  </span>
                  {I18n.t('routes.panel.admin.items.paymentOrder.pageTitle')}
                </h2>
              </Col>
            </Row>
            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginated(parameters)}
                data={userPaymentOrders}
                loading={loading > 0}
                ref={this.dataTableRef}
                params={null}
                showSizeChanger
                pageSizeOptions={
                  userPaymentOrders
                    && userPaymentOrders.count
                    && userPaymentOrders.count > 100
                    ? ['10', '50', '100', userPaymentOrders.count.toString()] : ['10', '20', '50', '100']
                }
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.code.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.code.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.externalIdentifier.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.externalIdentifier.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.totalAmount.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.totalAmount.title'),
                      render: (value, row) => `${value} ${row?.currency}` || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.status.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.status.title'),
                      render: (value) => PaymentOrderStatusMap.get(value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.customer.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.customer.name.title'),
                      render: (customer) => (customer?.name) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.customer.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.customer.userTaxIdentifier.title'),
                      render: (customer) => (customer?.taxIdentifier && maskCpfOrCnpj(customer.taxIdentifier)) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.expirationDate.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.expirationDate.title'),
                      render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY'),
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.actions.key'),
                      title: '',
                      render: (id) => (
                        <div className="dataTable__item--right">
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.remove(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                            disabled={me && me.id === id}
                          >
                            <Tooltip
                              title={I18n.t('shared.remove')}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <Button
                                type="link"
                                icon={<DeleteOutlined />}
                                disabled={me && me.id === id}
                              />
                            </Tooltip>
                          </Popconfirm>
                          <Tooltip
                            title={I18n.t('shared.details')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<PlusCircleOutlined />}
                              onClick={() => navigate(`${I18n.t('routes.panel.admin.items.paymentOrder.url')}/${id}`)}
                            />
                          </Tooltip>
                          <Tooltip
                            title={I18n.t('shared.Webhook.op')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<RetweetOutlined />}
                              disabled={me && me.id === id}
                              onClick={() => this.resend(id)}
                            />
                          </Tooltip>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  dataUserPaginated: PaymentOrderSelectors.getUserDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(
    ApiActions.getPaginated(parameters, ENDPOINTS.paymentOrders, ACTION_SAVE_USER_PAYMENT_ORDERS_PAGINATED),
  ),
  remove: (id, callback) => dispatch(ApiActions.remove(id, ENDPOINTS.paymentOrders, callback)),
  clean: () => dispatch(ApiActions.clean(ACTION_CLEAN_PAYMENT_ORDER)),
  resend: (id, callback) => dispatch(ApiActions.sendWebhook(id, ENDPOINTS.paymentOrders, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  UserFormPaymentOrder,
);
