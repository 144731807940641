import React from "react"

function statusText(status) {
    switch(status){
        case 1: 
        return(
            <span 
             style={{backgroundColor: '#FFEFD6',
             padding: '2px 8px', 
             borderRadius: '8px'}}
             >Em progresso
             </span>
        )
        case 2:
        return (
            <span 
            style={{
            backgroundColor: '#DEF6ED', 
            padding: '2px 8px', 
            borderRadius: '8px'}}
            >Autorizado
            </span>
        )
        case 3:
        return(
            <span 
            style={{
            backgroundColor: '#DEF6ED',
            padding: '2px 8px', 
            borderRadius: '8px'}}
            >Pago
            </span>    
        )
        
        case 4:
        return (
            <span 
            style={{
            backgroundColor: '#F2D7D7', 
            padding: '2px 8px', 
            borderRadius: '8px'}}
            >Cancelado
            </span>  
        )
        case 5:
        return (
            <span 
            style={{
            backgroundColor: '#DEF6ED', 
            padding: '2px 8px', 
            borderRadius: '8px'}}
            >Concluído
            </span> 
        )
 
    }
} 


function getTransactionOptions(){
    return [
        {
          title: 'Em progresso',
          id: 1,
        },
        {
          title: 'Autorizado',
          id: 2,
        },
        {
          title: 'Pago',
          id: 3,
        },
        {
          title: 'Cancelado',
          id: 4,
        },
        { 
          title: 'Concluído',
          id: 5  
        }
      ];
}


export { statusText, getTransactionOptions}
