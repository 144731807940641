import React from 'react';
import {
  Breadcrumb,
  Col,
  Button,
  Tooltip,
  Layout,
  Row,
  Spin,
} from 'antd';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_USERS_PAGINATED } from '../../../../app/redux/actions/user';
import { ApiActions } from '../../../../app/redux/actions';

import { connect } from 'react-redux';
import DataTable from '../../../../components/shared/DataTable';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { DashboardOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { UserSelectors, LoadingSelectors, AuthSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import { unmaskField, maskCpfOrCnpj  } from '../../../../app/utils/mask';

import { SearchOutlined } from '@ant-design/icons';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import { GetIcon } from '../../../../app/enum/iconList';

class UserSearchList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      dataDetails: null,
      reponseData: [],
      form: {
        userId: null,
        accountId: null,
        name: null,
        email: null,
        taxIdentifier: null
      },
      busca: false
    };
    this.dataTableRef = React.createRef();
  }

  getPaginated(params) {
    params = {
      ...params,
    };
    const { getPaginated } = this.props;
    this.setState({ form: { ...params } });
    getPaginated(params);
  }

  fieldChange(name, value) {
    const { form } = this.state;
    this.state.form[name] = value || null;
    this.setState({ form });
  }

  render() {
    const { Content } = Layout;
    const {
      loading,
      dataPaginated,
    } = this.props;

    const params = this.state.form;
    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t('routes.panel.admin.items.search.pageTitleSearch')

                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="command-details">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.admin.items.command.icon'),
                      { fill: '#F37223', width: 24, height: 24 },
                    )}
                  </span>
                  {
                    I18n.t(
                      'routes.panel.admin.items.search.pageTitleSearch'
                    )
                  }
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {
                    I18n.t('routes.panel.admin.items.search.pageDescriptionSearch')
                  }
                </p>
              </div>

              <form
              >
                <Row gutter={24}>
                  <Col span={8}>
                    <AdvancedInput
                      type="text"
                      label={I18n.t('routes.panel.admin.items.search.form.label.name')}
                      value={this.state.form && this.state.form.name}
                      onChange={(val) => this.fieldChange('name', val)}
                    />
                  </Col>

                  <Col span={8}>
                    <AdvancedInput
                      type="email"
                      label={I18n.t('routes.panel.admin.items.search.form.label.email')}
                      value={this.state.form && this.state.form.email}
                      onChange={(val) => this.fieldChange('email', val)}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      type="text"
                      label={I18n.t('routes.panel.admin.items.search.form.label.taxIdentifier')}
                      value={
                        this.state.form && 
                        this.state.form.taxIdentifier && 
                        maskCpfOrCnpj(this.state.form.taxIdentifier)
                      }
                      onChange={(val) => this.fieldChange('taxIdentifier', unmaskField(val))}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      type="text"
                      label={I18n.t('routes.panel.admin.items.search.form.label.id')}
                      value={this.state.form && this.state.form.userId}
                      onChange={(val) => this.fieldChange('userId', val)}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      type="text"
                      label={I18n.t('routes.panel.admin.items.search.form.label.accountId')}
                      value={this.state.form && this.state.form.accountId}
                      onChange={(val) => this.fieldChange('accountId', val)}
                    />
                  </Col>

                  <Col span={24} className="text-right">
                    <AdvancedButton
                      type="link"
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.getPaginated()}
                    />
                    <AdvancedButton
                      type="submit"
                      icon={<SearchOutlined />}
                      text={I18n.t('routes.panel.admin.items.search.searchButton')}
                      onClick={() => this.getPaginated({ ...params, offset: 0 })}
                    />
                  </Col>

                </Row>
              </form>
            </Spin>
          </div>
        </Content>

        <Content className="panel__layout__content">
          <DataTable
            getMethod={(parameters) => this.getPaginated(parameters)}
            data={dataPaginated}
            loading={loading > 0}
            ref={this.dataTableRef}
            params={params}
            showSizeChanger
            pageSizeOptions={
              dataPaginated
                && dataPaginated.count
                && dataPaginated.count > 100
                ? ['10', '50', '100', dataPaginated.count.toString()] : ['10', '20', '50', '100']
            }
            columns={[
              {
                key: I18n.t('routes.panel.admin.items.search.form.dataTable.columns.name.key'),
                title: I18n.t('routes.panel.admin.items.search.form.dataTable.columns.name.title'),
                render: (value) => value || '--',
              },
              {
                key: I18n.t('routes.panel.admin.items.search.form.dataTable.columns.email.key'),
                title: I18n.t('routes.panel.admin.items.search.form.dataTable.columns.email.title'),
                render: (value) => value || '--',
              },
              {
                key: I18n.t('routes.panel.admin.items.search.form.dataTable.columns.personType.key'),
                title: I18n.t('routes.panel.admin.items.search.form.dataTable.columns.personType.title'),
                render: (value) => value || '--',
              },
              {
                key: I18n.t('routes.panel.admin.items.command.dataTable.columns.actions.key'),
                title: '',
                render: (id) => (
                  <div className="dataTable__item--right">
                    <Tooltip
                      title={I18n.t('shared.details')}
                      overlayStyle={{ fontSize: 11 }}
                    >
                      <Button
                        type="link"
                        icon={<PlusCircleOutlined />}
                        onClick={() => navigate(`${I18n.t('routes.panel.admin.items.search.url')}/${id}`)}
                      />
                    </Tooltip>
                  </div>
                ),
              },
            ]
            }
          />

          <div className="command-details">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: UserSelectors.getDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(
    ApiActions.getPaginated(parameters, ENDPOINTS.searchUsers, ACTION_SAVE_USERS_PAGINATED),
  ),
  remove: (id, callback) => dispatch(ApiActions.remove(id, ENDPOINTS.searchUsers, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  UserSearchList,
);

