import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message, Breadcrumb, Collapse, Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  DeleteOutlined, SearchOutlined, RetweetOutlined, PlusCircleOutlined, DashboardOutlined,
  ExportOutlined
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import DataTable from '../../../../components/shared/DataTable';
import { ApiActions } from '../../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, PaymentOrderSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import * as FileUtils from '../../../../app/utils/file';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedRadio from '../../../../components/shared/AdvancedRadio/AdvancedRadio';
import AdvancedRangePicker from '../../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';
import AdvancedSelect from '../../../../components/shared/AdvancedSelect/AdvancedSelect';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_CLEAN_PAYMENT_ORDER, ACTION_SAVE_PAYMENT_ORDERS_PAGINATED } from '../../../../app/redux/actions/paymentOrder';
import { GetIcon } from '../../../../app/enum/iconList';
import { PaymentOrderStatusMap } from '../../../../app/enum/paymentOrderStatus';
import { maskCpfOrCnpj, unmaskField } from '../../../../app/utils/mask';
import {
  getPaymentOrderStatusOptions, getPaymentOrderMethodsOptions
} from '../../../../app/utils/selectOptions';
import {
  getPaymentOrderSafeBalanceOptions, getPaymentOrderFeePayerOptions
} from '../../../../app/utils/radioOptions';

class PaymentOrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        id: null,
        code: null,
        externalIdentifier: null,
        userName: null,
        userTaxIdentifier: null,
        selectPaymentMethod: null,
        selectPaymentAt: null,
        status: null,
        expirationDate: null,
        createdAt: null,
        safeBalance: null,
        feePayer: null,

        expirationDateRange: null,
        createdAtRange: null,
        selectPaymentAtRange: null,
      }
    };
    this.dataTableRef = React.createRef();
  }

  async componentDidMount() {
    const { clean } = this.props;
    await clean();
  }

  validityChange(key, keyRange, dates) {
    const { params } = this.state;

    const value = dates[0].format('YYYY-MM-DD').toString().concat(',',
      dates[1].format('YYYY-MM-DD').toString()
    );

    this.setState({
      params: {
        ...params,
        [key]: value,
        [keyRange]: dates,
      },
    });
  }

  getPaginated(params) {
    params = {
      ...params,
    };
    const { getPaginated } = this.props;
    this.setState({ params: { ...params } });
    getPaginated(params);
  }

  exportCSV(params) {
    params = { 
      ...params,
      isDESC: true
    };
    const { exportCSV } = this.props;
    exportCSV(params, (data) => FileUtils.downloadAction(data, 'payment_orders.csv'));
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('shared.messages.success.deleteSuccess'));
      this.dataTableRef.current.reset();
    });
  }

  resend(id) {
    this.props.resend(id, () => {
      message.success(I18n.t('shared.messages.success.resendSuccess'));
      this.dataTableRef.current.reset();
    });
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const { dataPaginated, loading, me } = this.props;
    const { params } = this.state;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.admin.items.paymentOrder.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={I18n.t('shared.advancedFilters.header')}
              key="0"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.code')}
                    value={params && params.code}
                    onChange={(val) => this.fieldChange('code', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.externalIdentifier')}
                    value={params && params.externalIdentifier}
                    onChange={(val) => this.fieldChange('externalIdentifier', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedRadio
                    label={I18n.t('shared.advancedFilters.safeBalance')}
                    value={params && params.safeBalance}
                    onChange={(val) => this.fieldChange('safeBalance', val)}
                    options={getPaymentOrderSafeBalanceOptions()}
                    optionType="default"
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('shared.advancedFilters.selectPaymentMethod')}
                    defaultValue={params && params.selectPaymentMethod}
                    value={params && params.selectPaymentMethod}
                    onChange={(val) => this.fieldChange('selectPaymentMethod', val)}
                    options={getPaymentOrderMethodsOptions()}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedRangePicker
                    label={I18n.t('shared.advancedFilters.selectPaymentAt')}
                    value={params && params.selectPaymentAtRange}
                    onChange={(val) => this.validityChange('selectPaymentAt', 'selectPaymentAtRange', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('shared.advancedFilters.status')}
                    value={params && params.status}
                    onChange={(val) => this.fieldChange('status', val)}
                    options={getPaymentOrderStatusOptions()}
                    defaultValue={params && params.status}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.name')}
                    value={params && params.userName}
                    onChange={(val) => this.fieldChange('userName', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.userTaxIdentifier')}
                    value={params && params.userTaxIdentifier && maskCpfOrCnpj(params.userTaxIdentifier)}
                    onChange={(val) => this.fieldChange('userTaxIdentifier', unmaskField(val))}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedRadio
                    label={I18n.t('shared.advancedFilters.feePayer')}
                    value={params && params.feePayer}
                    onChange={(val) => this.fieldChange('feePayer', val)}
                    options={getPaymentOrderFeePayerOptions()}
                    optionType="default"
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedRangePicker
                    label={I18n.t('shared.advancedFilters.expirationDate')}
                    value={params && params.expirationDateRange}
                    onChange={(val) => this.validityChange('expirationDate', 'expirationDateRange', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedRangePicker
                    label={I18n.t('shared.advancedFilters.createdAtRange')}
                    value={params && params.createdAtRange}
                    onChange={(val) => this.validityChange('createdAt', 'createdAtRange', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.id')}
                    value={params && params.id}
                    onChange={(val) => this.fieldChange('id', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilters.clearButtonText')}
                    onClick={() => this.getPaginated()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilters.filterButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.getPaginated({ ...params, offset: 0 })}
                  />
                </Col>

              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.admin.items.paymentOrder.icon'),
                      { fill: '#F37223', width: 24, height: 24 },
                    )}
                  </span>
                  {I18n.t('routes.panel.admin.items.paymentOrder.pageTitle')}
                </h2>
              </Col>
              <Col span={12} className="panel__layout__content__actions__export-button text-right">
                <AdvancedButton
                  text={I18n.t('shared.panelActions.export')}
                  icon={<ExportOutlined />}
                  onClick={() => this.exportCSV(params)}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginated(parameters)}
                data={dataPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                params={params}
                showSizeChanger
                pageSizeOptions={
                  dataPaginated
                    && dataPaginated.count
                    && dataPaginated.count > 100
                    ? ['10', '50', '100', dataPaginated.count.toString()] : ['10', '20', '50', '100']
                }
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.code.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.code.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.externalIdentifier.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.externalIdentifier.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.totalAmount.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.totalAmount.title'),
                      render: (value, row) => `${value} ${row?.currency}` || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.status.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.status.title'),
                      render: (value) => PaymentOrderStatusMap.get(value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.customer.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.customer.name.title'),
                      render: (customer) => (customer?.name) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.customer.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.customer.userTaxIdentifier.title'),
                      render: (customer) => (customer?.userTaxIdentifier && maskCpfOrCnpj(customer.userTaxIdentifier)) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.expirationDate.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.expirationDate.title'),
                      render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY'),
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.selectedInstallments.key'),
                      title: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.selectedInstallments.title'),
                      render: (selectedInstallments) => (selectedInstallments) || '',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.paymentOrder.dataTable.columns.actions.key'),
                      title: '',
                      render: (id) => (
                        <div className="dataTable__item--right">
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.remove(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                            disabled={me && me.id === id}
                          >
                            <Tooltip
                              title={I18n.t('shared.remove')}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <Button
                                type="link"
                                icon={<DeleteOutlined />}
                                disabled={me && me.id === id}
                              />
                            </Tooltip>
                          </Popconfirm>
                          <Tooltip
                            title={I18n.t('shared.details')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<PlusCircleOutlined />}
                              onClick={() => window.open(`${I18n.t('routes.panel.admin.items.paymentOrder.url')}/${id}`, "_blank", "noreferrer")}
                            />
                          </Tooltip>
                          <Tooltip
                            title={I18n.t('shared.Webhook.op')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<RetweetOutlined />}
                              disabled={me && me.id === id}
                              onClick={() => this.resend(id)}
                            />
                          </Tooltip>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: PaymentOrderSelectors.getDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(
    ApiActions.getPaginated(parameters, ENDPOINTS.paymentOrders, ACTION_SAVE_PAYMENT_ORDERS_PAGINATED),
  ),
  remove: (id, callback) => dispatch(ApiActions.remove(id, ENDPOINTS.paymentOrders, callback)),
  clean: () => dispatch(ApiActions.clean(ACTION_CLEAN_PAYMENT_ORDER)),
  resend: (id, callback) => dispatch(ApiActions.sendWebhook(id, ENDPOINTS.paymentOrders, callback)),
  exportCSV: (parameters, callback) => dispatch(
    ApiActions.customGet(`${ENDPOINTS.paymentOrders}/export-csv`, '', parameters, callback, true),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  PaymentOrderList,
);
