
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { StepContent, makeStyles } from '@material-ui/core';
import { Tooltip } from 'antd';
import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import mountConciliationSteps from '../../../../app/utils/mountConciliationSteps';

const ConciliationSteps = ({ installments, orderDetail, feePayer })  => {
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([]);
    const [stepsStatus, setStepsStatus] = useState([]);


    useEffect(() => {
        if(installments && orderDetail && feePayer){
            let stepsObj = mountConciliationSteps(installments, orderDetail, feePayer);
            setSteps(stepsObj.steps);
            setStepsStatus(stepsObj.stepsStatus);
        }   
    },[installments])


    const useStyles = makeStyles(()=> ({
        root: {
            padding: '0px',
            "& .MuiStepIcon-root": {color: "#BBBDC2" },
            "& .MuiStepIcon-active": { color: "#BBBDC2" },
            "& .MuiStepIcon-text": { fill: '#BBBDC2' },
            "& .MuiStepIcon-completed": { color: "#59AC7C" },
            "& .MuiStepConnector-lineVertical": { minHeight: '20px', borderLeftWidth: '3px'},
            "& .MuiStepConnector-vertical": {padding: '0px', marginLeft: '10.5px'}, 
            '& .MuiStepIcon-root.Mui-error': {color: '#BD3939'},
            "& svg": {display: 'block'}
        }
    }))

    const c = useStyles();

    return (
        <>
            <Box sx={{ width: '100%'}}>
                <Stepper className={c.root} activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={step} {...stepProps} completed={stepsStatus[index]}>
                        <StepLabel 
                        {...labelProps} 
                        >
                            {step}  
                        </StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>
                </Box>
      </>
    )
  }

export default ConciliationSteps;