import React from 'react';
import { Row, Col, Divider } from 'antd';
import { I18n } from 'react-redux-i18n';
import AdvancedInput from '../../../../../../components/shared/AdvancedInput/AdvancedInput';
import PaymentOrderFormReceivers from '../../receivers/PaymentOrderFormReceivers';
import { ViewType } from '../../../../../../app/enum/viewType';

class PaymentOrderFormCreditCard extends React.Component {
  render() {
    const { viewType, creditCard } = this.props;
    return (
      <>
        <Row gutter={16}>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.creditCard.maxInstallments.label')}
              value={creditCard.maxInstallments}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
        </Row>

        {creditCard.intervalConfig?.length && creditCard.intervalConfig.map((o) => (
          <>
            <Divider />

            <label>
              {I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.creditCard.intervalConfig.label')}
            </label>

            <Row gutter={16}>
              {o.installments &&
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.creditCard.intervalConfig.installments.label')}
                    value={o.installments}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
              }
              {o.amount &&
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.creditCard.intervalConfig.amount.label')}
                    value={o.amount}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
              }
              {o.ratio &&
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.creditCard.intervalConfig.ratio.label')}
                    value={o.ratio}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
              }
              {o.feeAmount &&
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.creditCard.intervalConfig.feeAmount.label')}
                    value={o.feeAmount}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
              }
            </Row>

            {o.receivers && (
              <PaymentOrderFormReceivers
                receivers={o.receivers}
                viewType={viewType}
              />
            )}
          </>
        ))}
      </>
    )
  }
}

export default PaymentOrderFormCreditCard;
