import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './reducers/auth';
import userReducer from './reducers/user';
import loadingReducer from './reducers/loading';
import paymentOrderReducer from './reducers/paymentOrder';
import commandReducer from './reducers/command';
import dashboardReducer from './reducers/dashboard';
import utilsReducer from './reducers/utils';
import feePayerReducer from './reducers/fee-payer';
import financialReducer from './reducers/financial';
import chart from './reducers/chart';
import simulationReducer from './reducers/simulations';
import transactionReducer from './reducers/transactions';
import qrCodeReducer from './reducers/qrCode';


const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'i18n',
    'loading',
  ],
};

const persistedReducer = persistReducer(persistConfig, combineReducers({
  i18n: i18nReducer,
  auth: authReducer,
  loading: loadingReducer,
  user: userReducer,
  chartData: chart,
  paymentOrder: paymentOrderReducer,
  command: commandReducer,
  dashboard: dashboardReducer,
  utils: utilsReducer,
  feePayer: feePayerReducer,
  creditOffer: financialReducer,
  simulation: simulationReducer,
  transaction: transactionReducer,
  qrCode: qrCodeReducer,
}));

export default () => {
  const store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ),
  );

  const persistor = persistStore(store);
  return { store, persistor };
};
