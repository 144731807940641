import { PaymentOrderStatus, PaymentOrderStatusMap } from '../enum/paymentOrderStatus';
import { PaymentOrderMethods, PaymentOrderMethodsMap } from '../enum/paymentOrderMethod';
import { QRCodeStatus, QRCodeStatusMap } from '../enum/qrCodeStatus';
import { ConciliationPixStatus, ConciliationPixStatusMap } from '../enum/pixConciliationStatus';

export const getPaymentOrderStatusOptions = () => {
  return [
    {
      title: PaymentOrderStatusMap.get(PaymentOrderStatus.WAITING_PAYMENT),
      id: PaymentOrderStatus.WAITING_PAYMENT,
    },
    {
      title: PaymentOrderStatusMap.get(PaymentOrderStatus.IN_PROGRESS),
      id: PaymentOrderStatus.IN_PROGRESS,
    },
    {
      title: PaymentOrderStatusMap.get(PaymentOrderStatus.AUTHORIZED),
      id: PaymentOrderStatus.AUTHORIZED,
    },
    {
      title: PaymentOrderStatusMap.get(PaymentOrderStatus.PAID),
      id: PaymentOrderStatus.PAID,
    },
    {
      title: PaymentOrderStatusMap.get(PaymentOrderStatus.CANCELED),
      id: PaymentOrderStatus.CANCELED,
    },
    {
      title: PaymentOrderStatusMap.get(PaymentOrderStatus.BALANCE_LOCKED),
      id: PaymentOrderStatus.BALANCE_LOCKED,
    },
    {
      title: PaymentOrderStatusMap.get(PaymentOrderStatus.CANCEL_IN_PROGRESS),
      id: PaymentOrderStatus.CANCEL_IN_PROGRESS,
    },
  ];
};

export const getPaymentOrderMethodsOptions = () => {
  return [
    {
      title: PaymentOrderMethodsMap.get(PaymentOrderMethods.BOLETO),
      id: PaymentOrderMethods.BOLETO,
    },
    {
      title: PaymentOrderMethodsMap.get(PaymentOrderMethods.SALDO),
      id: PaymentOrderMethods.SALDO,
    },
    {
      title: PaymentOrderMethodsMap.get(PaymentOrderMethods.CARTAO_CREDITO),
      id: PaymentOrderMethods.CARTAO_CREDITO,
    },
    {
      title: PaymentOrderMethodsMap.get(PaymentOrderMethods.BOLETO_PARCELADO),
      id: PaymentOrderMethods.BOLETO_PARCELADO,
    },
    {
      title: PaymentOrderMethodsMap.get(PaymentOrderMethods.PIX_QR_CODE),
      id: PaymentOrderMethods.PIX_QR_CODE,
    },
  ];
};

export const getQrCodeStatusOptions = () => { 
  return [
    {
      title: QRCodeStatusMap.get(QRCodeStatus.WAITING_PAYMENT),
      id: QRCodeStatus.WAITING_PAYMENT,
    },
    {
      title: QRCodeStatusMap.get(QRCodeStatus.IN_PROGRESS),
      id: QRCodeStatus.IN_PROGRESS,
    },
    {
      title: QRCodeStatusMap.get(QRCodeStatus.EXPIRED),
      id: QRCodeStatus.EXPIRED,
    },
    {
      title: QRCodeStatusMap.get(QRCodeStatus.PAID),
      id: QRCodeStatus.PAID,
    },
    {
      title: QRCodeStatusMap.get(QRCodeStatus.CANCELED),
      id: QRCodeStatus.CANCELED,
    }
    
  ];
}


export const getPixConciliationStatusOptions = () => { 
  return [
    {
      title: ConciliationPixStatusMap.get(ConciliationPixStatus.NOT_STARTED),
      id: ConciliationPixStatus.NOT_STARTED,
    },
    {
      title: ConciliationPixStatusMap.get(ConciliationPixStatus.STARTED),
      id: ConciliationPixStatus.STARTED,
    },
    {
      title: ConciliationPixStatusMap.get(ConciliationPixStatus.TITLE_CREATED),
      id: ConciliationPixStatus.TITLE_CREATED,
    },
    {
      title: ConciliationPixStatusMap.get(ConciliationPixStatus.GROUP_CREATED),
      id: ConciliationPixStatus.GROUP_CREATED,
    },
    {
      title: ConciliationPixStatusMap.get(ConciliationPixStatus.DEPOSIT_CREATED),
      id: ConciliationPixStatus.DEPOSIT_CREATED,
    }
    
  ];
}