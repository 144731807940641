import React from 'react';
import {ReactComponent as PendingIcon} from '../../../app/assets/img/pending.svg';
import {ReactComponent as RefusedIcon} from '../../../app/assets/img/refused.svg';
import {ReactComponent as ProgressIcon} from '../../../app/assets/img/progress.svg';
import {ReactComponent as ApprovedIcon} from '../../../app/assets/img/approved.svg';

export default function CreditStatus (status){

        switch(status) {
            case 0 :
            return (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <ProgressIcon/>
                    <span>Em digitação</span>
                </div>
            )
            case 1 :
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ProgressIcon/>
                        <span>Em análise</span>
                    </div>
                )
            case 2 :
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ApprovedIcon/>
                        <span>Aprovado</span>
                    </div>
            )
            case 3 :
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <RefusedIcon/>
                        <span>Recusado</span>
                    </div>
            )
            case 4 :
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <RefusedIcon/>
                        <span>Cancelado</span>
                    </div>
            )
            case 5 :
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <PendingIcon/>
                        <span>Pendente</span>
                    </div>
            )
            case 6 :
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ApprovedIcon/>
                        <span>Finalizado</span>
                    </div>
            )
            case 7 :
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ApprovedIcon/>
                        <span>Conferido</span>
                    </div>
            )
            case 8 :
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ApprovedIcon/>
                        <span>Liberado</span>
                    </div>
            )
            case 9 :
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ApprovedIcon/>
                        <span>Pago</span>
                    </div>
            )
            case 10 :
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ApprovedIcon/>
                        <span>Cedido</span>
                    </div>
            )
            case 11 :
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <PendingIcon/>
                        <span>Pendente pagamento</span>
                    </div>
            )

        }
}