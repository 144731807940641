export const PaymentOrderStatus = {
  WAITING_PAYMENT: 1,
  IN_PROGRESS: 2,
  AUTHORIZED: 3,
  PAID: 4,
  CANCELED: 5,
  BALANCE_LOCKED: 6,
  CANCEL_IN_PROGRESS: 7,
};

export const PaymentOrderStatusMap = new Map([
  [PaymentOrderStatus.WAITING_PAYMENT, 'waiting_payment'],
  [PaymentOrderStatus.IN_PROGRESS, 'in_progress'],
  [PaymentOrderStatus.AUTHORIZED, 'authorized'],
  [PaymentOrderStatus.PAID, 'paid'],
  [PaymentOrderStatus.CANCELED, 'canceled'],
  [PaymentOrderStatus.CANCEL_IN_PROGRESS, 'cancel_in_progress'],
  [PaymentOrderStatus.BALANCE_LOCKED, 'balance_locked'],
]);
