function typeCommandText(typeId) {
    switch(typeId) {
        case 1: 
        return "BLOQUEIO DE SALDO"
        case 2:
        return "DESBLOQUEIO DE SALDO"
        case 3:
        return "TRANSFERÊNCIA DE SALDO"
    }
} 


export { typeCommandText}