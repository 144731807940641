export const QRCodeStatus = {
    WAITING_PAYMENT: 1,
    IN_PROGRESS: 2,
    EXPIRED: 3,
    PAID: 4,
    CANCELED: 5
  };
  
  export const QRCodeStatusMap = new Map([
    [QRCodeStatus.WAITING_PAYMENT, 'Aguardando pagamento'],
    [QRCodeStatus.IN_PROGRESS, 'Em progresso'],
    [QRCodeStatus.EXPIRED, 'Autorizado'],
    [QRCodeStatus.PAID, 'Pago'],
    [QRCodeStatus.CANCELED, 'Cancelado']
  ]);
  