import { useFormik } from 'formik';
import React, { useMemo, useCallback, useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { ENDPOINTS } from '../../../app/endpoints/endpoints';
import { ApiActions } from '../../../app/redux/actions';
import { ACTION_LOAD_BAR_CHART, ACTION_LOAD_COUNTERS, ACTION_LOAD_RADIAL_CHART } from '../../../app/redux/actions/chart';
import { ChartSelectors, LoadingSelectors } from '../../../app/redux/reducers';
import dayjs from 'dayjs';

import { AnalyticsView } from './View'

const AnalyticsController = ({ getCounterData, getBarChart, getRadialChart, radialChartData, barChartData, loading }) => {
  const [subFilterOptions, setSubFilterOptions] = useState(null);
  const [counters, setCounters] = useState([]);
  const [chartParams, setCharmParams] = useState({
    chartSubject: 'orders',
    begin: dayjs()
      .subtract(process.env.NODE_ENV === 'production' ? 10 : 1000, 'days')
      .format('YYYY/MM/DD')
      .toString(),
    end: dayjs().format('YYYY/MM/DD').toString(),
    keys: 'selectedInstallments',
    methods: 'mode',
  })

  const handleSubmit = useCallback((value) => {
    setCharmParams({
      ...chartParams,
      begin: value.dateFrom,
      end: value.dateTo
    })
  }, []);

  const subjectOptions = useMemo(() => ([
    { value: "orders", label: "Ordens de pagamento" },
    { value: "users", label: "Usuários" },
  ]), []);

  const statusOpOptions = useMemo(() => ([
    { value: "authorized", label: "Autorizadas" },
    { value: "in_progress", label: "Em progresso" },
    { value: "balance_locked", label: "Bloqueadas" },
  ]), []);

  const userOptions = useMemo(() => ([
    { value: "createdAtRange", label: "Data de criação" },
  ]), []);

  const setFieldValue = useCallback((path, value) => {
    filterForm.setFieldValue(path, value);
  }, []);


  const resetForm = useCallback(() => {
    filterForm.setValues({
      values: {
        type: "",
        subType: "",
        dateFrom: "",
        dateTo: "",
        rawDate: "",
      }
    });
  }, []);

  const resetFilter = useCallback(() => {
    resetForm();
    setCharmParams({
      chartSubject: 'orders',
      begin: dayjs()
        .subtract(process.env.NODE_ENV === 'production' ? 10 : 1000, 'days')
        .format('YYYY/MM/DD')
        .toString(),
      end: dayjs().format('YYYY/MM/DD').toString(),
      keys: 'selectedInstallments',
      methods: 'mode',
    });
  }, [resetForm]);

  const filterForm = useFormik({
    initialValues: {
      type: "",
      subType: "",
      dateFrom: "",
      dateTo: "",
      rawDate: "",
    },
    onSubmit: handleSubmit,
    onReset: resetForm
  });

  const handleChangeFilter = useCallback((value) => {
    switch (value) {
      case "users":
        setSubFilterOptions(userOptions)
        setFieldValue("subType", userOptions[0].value)
        break
      case "orders":
        setSubFilterOptions(statusOpOptions);
        setFieldValue("subType", statusOpOptions[0].value)
        break;
      default:
        setSubFilterOptions(null);
        break;
    }
  }, [setFieldValue]);

  const composeHeader = useCallback(async () => {
    const cards = [];

    Object.values(I18n.t('routes.panel.analytics.items.views.counters')).map(counter => {
      getCounterData(counter.params).then(data => {
        cards.push({
          title: counter.title,
          value: data && data.length && data[0] || "Não encontrado"
        })
      });
    })

    setCounters(cards);
  }, []);

  const composeCharts = useCallback(async () => {
    await getRadialChart({
      ...chartParams,
      keys: 'selectPaymentMethod',
      mode: 'count',
    });

    await getBarChart({
      ...chartParams,
      keys: 'selectedInstallments',
      mode: 'count',
    });
  }, [chartParams]);

  const radialChart = useMemo(() => ({
    series: radialChartData.map(data => Number(data.value)),
    chartOptions: {
      labels: radialChartData.map(data => data.label)
    }
  }), [radialChartData]);

  useEffect(() => {
    composeHeader();
  }, []);

  useEffect(() => {
    composeCharts();
  }, [chartParams]);

  return (
    <AnalyticsView
      filterForm={filterForm}
      subjectOptions={subjectOptions}
      setFieldValue={setFieldValue}
      changeField={filterForm.handleChange}
      submitForm={filterForm.handleSubmit}
      selectDefaultFilter={handleChangeFilter}
      subFilterOptions={subFilterOptions}
      formValues={filterForm.values}
      counters={counters}
      radialChart={radialChart}
      resetForm={filterForm.resetForm}
      resetFilter={resetFilter}
      loading={loading}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    loading: LoadingSelectors.getLoading(state),
    chartParams: ChartSelectors.getChartParams(state),
    barChartData: ChartSelectors.getBarData(state),
    radialChartData: ChartSelectors.getRadialData(state),
    counterData: ChartSelectors.getCounterData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCounterData: (params) => dispatch(ApiActions.getChart(ENDPOINTS.dataMiner, params, ACTION_LOAD_COUNTERS)),
  getBarChart: (parameters) => dispatch(ApiActions.getChart(ENDPOINTS.dataMiner, parameters, ACTION_LOAD_BAR_CHART)),
  getRadialChart: (parameters) =>
    dispatch(ApiActions.getChart(ENDPOINTS.dataMiner, parameters, ACTION_LOAD_RADIAL_CHART)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsController);
