import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider,
  Layout, Modal, Row,
  Spin,
  message,
} from 'antd';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { DashboardOutlined } from '@ant-design/icons';
import { ApiActions } from '../../../../app/redux/actions';
import { UserSelectors, LoadingSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import UserSearchForm from '../form/UserSearchForm';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import { ACTION_SAVE_USER_DETAILS, ACTION_SAVE_USER_TYPE } from '../../../../app/redux/actions/user';
import { GetIcon } from '../../../../app/enum/iconList';

class UserSearchDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      dataDetails: null,
      dataType: null,
      loadingButton: false,
    };
    this.confirmOnSubmit = this.confirmOnSubmit.bind(this);
    this.ofuscateUser = this.ofuscateUser.bind(this);
  }

  async componentDidMount() {
    const { getById, getTypeById,  id } = this.props;

    this.setState({
      viewType: id !== 'add' ? ViewType.EDIT : ViewType.ADD,
    });

    let params = {
      accountTypes: "CONTA ADM"
    }

    try {
      await getById(id !== 'add' ? id : null);
      await getTypeById(id !== 'add' ? id : null, params);

      this.setState({ dataDetails: this.props.dataDetails });
      this.setState({ dataType: this.props.dataType });
    } catch (error) {
      //
    }
  }

  handleCreateLinkedAccount = async () => {
    const { createLinkedAccount, id, getById } = this.props;
    try {
      this.setState({ loadingButton: true })
      await createLinkedAccount(id, () => {
        message.success(I18n.t('shared.messages.success.createLinkedAccountSuccess'));  
      });
      await getById(id);
    } catch (e) {
      message.error(I18n.t('shared.messages.error.createLinkedAccountError'));
    }
    finally {
      this.setState({ loadingButton: false })
    }
  }

  ofuscateUser = async () => { 
  const { ofuscateAccount, id } = this.props;
    try {
      await ofuscateAccount(id);
      message.success(I18n.t('shared.messages.success.ofuscateAccountSuccess'));
      navigate(`${I18n.t('routes.panel.admin.items.search.url')}`);
    } catch (e) {
      message.error(I18n.t('shared.messages.errors.ofuscateAccountError'));
    }
  }

  confirmOnSubmit() {

    Modal.confirm({
      title: I18n.t('routes.panel.admin.items.accountChange.details.form.warning.ofuscateAccount'),
      content: (
        <>

        </>
      ),
      onOk: () => this.ofuscateUser(),
    });
  }

  render() {
    const { Content } = Layout;
    const { viewType, dataDetails, dataType, loadingButton } = this.state;
    const {
      loading,
      id,
    } = this.props;
    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.admin.items.search.url')}>
                <span>{I18n.t('routes.panel.admin.items.search.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.admin.items.search.pageTitleAdd'
                      : 'routes.panel.admin.items.search.pageTitleView',
                  )
                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        {viewType === ViewType.EDIT && (
          <Content className="panel__layout__content panel__layout__content--breadcrumb">
            <div className="command-details">
              <div className="panel__layout__content__title">
                <h6 className="panel__layout__content__title__value">
                  {
                    I18n.t(
                      'routes.panel.admin.items.search.details.form.actions.label'
                    )
                  }
                </h6>
              </div>

              <div className="panel__layout__content__title__value__description">
                <Row>
                  <Col md={4} style={{ display: 'flex' }}>
                  <AdvancedButton style={{marginRight: '15px' }}
                      type="button"
                      text={
                        I18n.t(
                          'routes.panel.admin.items.search.details.form.actions.accountClosure'
                        )
                      }
                      onClick={() =>
                        navigate(`${I18n.t('routes.panel.admin.items.accountClosure.url')}/${id}`)
                      }
                    />
                    <AdvancedButton style={{marginRight: '15px' }}
                      type="button"
                      text={
                        I18n.t(
                          'routes.panel.admin.items.search.details.form.actions.accountUpdate'
                        )
                      }
                      onClick={() =>
                        navigate(`${I18n.t('routes.panel.admin.items.accountUpdate.url')}/${id}`)
                      }
                    />
                    <AdvancedButton style={{marginRight: '15px' }}
                      type="button"
                      text={
                        I18n.t(
                          'routes.panel.admin.items.search.details.form.actions.accountChange'
                        )
                      }
                      onClick={() =>
                        navigate(`${I18n.t('routes.panel.admin.items.accountChange.url')}/${id}`)
                      }
                    />
                    <AdvancedButton style={{marginRight: '15px' }}
                      type="button"
                      loading={loadingButton}
                      text={
                        I18n.t(
                          'routes.panel.admin.items.search.details.form.actions.createLinkedAccount'
                        )
                      }
                      disabled={loadingButton}
                      onClick={() =>
                        this.handleCreateLinkedAccount()
                      }
                    />
                    <AdvancedButton
                      type="button"
                      text={
                        I18n.t(
                          'routes.panel.admin.items.search.details.form.actions.ofuscateAccount'
                        )
                      }
                      onClick={() => this.confirmOnSubmit()}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
        )}

        <Content className="panel__layout__content">
          <div className="command-details">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.admin.items.command.icon'),
                      { fill: '#F37223', width: 24, height: 24 },
                    )}
                  </span>
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.admin.items.search.pageTitleAdd'
                        : 'routes.panel.admin.items.search.pageTitleView',
                    )
                  }
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.admin.items.search.pageDescriptionAdd'
                        : 'routes.panel.admin.items.search.pageDescriptionView',
                    )
                  }
                </p>
              </div>
              <Divider />
              <div>
                <Row>
                  <Col span={24}>
                    {(dataDetails || viewType === ViewType.ADD) && (
                      <UserSearchForm
                        id={id}
                        data={dataDetails}
                        dataType={dataType}
                        viewType={viewType}
                        loading={loading}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataDetails: UserSelectors.getDataDetails(state),
  dataType: UserSelectors.getUserType(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getById: (id) => dispatch(ApiActions.getById(id, ENDPOINTS.searchUsers, ACTION_SAVE_USER_DETAILS)),
  getTypeById: (id, params) => dispatch(ApiActions.getById(id, ENDPOINTS.accountType, ACTION_SAVE_USER_TYPE, params)),
  createLinkedAccount: (id, callback) => dispatch(ApiActions.put(id, null, `${ENDPOINTS.searchUsers}/linked-account`, callback)),
  ofuscateAccount: (id) => dispatch(ApiActions.accountClosure(`${ENDPOINTS.searchUsers}/${id}`))
 });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserSearchDetails);
