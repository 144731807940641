import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { I18n } from 'react-redux-i18n';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';

class UserAccountClosureForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        id: null,
      },
    };
  }

  componentDidMount() {
    const { data, id } = this.props;
    if (data && id !== 'add') {
      this.setState({
        form: {
          id: data && data.id,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;
    const { data, submitFunction } = this.props;
    try {
      submitFunction(data ? data.id : null, form);
    } catch (error) {
      //
    }
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render() {
    const { loading } = this.props;
    const { form } = this.state;
    return (
      <Row>
        {loading === 0 && (
          <Col span={24}>
            <form name="accountClosureForm" onSubmit={(ev) => this.onSubmit(ev)}>
              <Row>
                <Col span={24} className="text-right">
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.cancel')}
                    href={I18n.t('routes.panel.admin.items.search.url')}
                  />
                  <AdvancedButton htmlType="submit" text={I18n.t('shared.closure')}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

export default connect(mapStateToProps, null)(UserAccountClosureForm);
