import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider,
  Layout, Row,
  Spin,
  message
} from 'antd';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { DashboardOutlined } from '@ant-design/icons';
import { ApiActions } from '../../../../app/redux/actions';
import { UserSelectors, LoadingSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import UserAccountChangeForm from '../form/UserAccountChangeForm';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_USER_DETAILS } from '../../../../app/redux/actions/user';
import { GetIcon } from '../../../../app/enum/iconList';

class UserAccountChangeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      dataDetails: null,
    };
  }

  async componentDidMount() {
    const { getById, id } = this.props;

    this.setState({
      viewType: ViewType.EDIT,
    });

    try {
      await getById(id);
      this.setState({ dataDetails: this.props.dataDetails });
    } catch (error) {
      //
    }
  }

  async submit(id) {
    const { customDelete } = this.props;

    try {
      await customDelete(id, () => {
        message.success(I18n.t('shared.messages.success.successEdit'));
        navigate(`${I18n.t('routes.panel.admin.items.search.url')}/${id}`);
      });
    } catch (e) {
      //
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType, dataDetails } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.admin.items.search.url')}/${id}`}>
                <span>{I18n.t('routes.panel.admin.items.search.pageTitleView')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t('routes.panel.admin.items.accountChange.pageTitleEdit')
                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="command-details">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.admin.items.command.icon'),
                      { fill: '#F37223', width: 24, height: 24 },
                    )}
                  </span>
                  {
                    I18n.t('routes.panel.admin.items.accountChange.pageTitleEdit')
                  }
                </h2>
              </div>
              <Divider />
              <div>
                <Row>
                  <Col span={24}>
                    {(dataDetails) && (
                      <UserAccountChangeForm
                        id={id}
                        data={dataDetails}
                        submitFunction={(dataId) => this.submit(dataId)}
                        viewType={viewType}
                        loading={loading}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataDetails: UserSelectors.getDataDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getById: (id) => dispatch(ApiActions.getById(id, ENDPOINTS.searchUsers, ACTION_SAVE_USER_DETAILS)),
  customDelete: (id, callback) => dispatch(ApiActions.customDelete(`${ENDPOINTS.searchUsers}/${id}/ofuscate-and-create`, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAccountChangeDetails);
