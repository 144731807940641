import StorageService from './storage';

function isAuthenticated() {

  const auth = StorageService.get('auth');
  return auth && auth.token;
}

function get() {
  if (isAuthenticated()) {
    return StorageService.get('auth');
  }
}

function create(data) {
  StorageService.set('auth', data);
}

function reset() {
  StorageService.reset('auth');
}

export default {
  get,
  isAuthenticated,
  create,
  reset,
};
