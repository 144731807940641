import Immutable from 'seamless-immutable';
import { QrCodeActions } from '../actions';

const initialState = Immutable({
  dataPaginated: null,
  dataDetails: null,

});

export default function qrCode(
  state = initialState,
  action,
) {
  switch (action.type) {
    case QrCodeActions.ACTION_SAVE_QR_CODES_PAGINATED:
      state = {
        ...state,
        dataPaginated: action.payload,
      };
      return state;
    case QrCodeActions.ACTION_SAVE_QR_CODE_DETAILS:
      state = {
        dataDetails: action.payload
      }
    default:
      return state;
  }
}

export function getDataPaginated(state) {
  return state.qrCode.dataPaginated;
}


export function getDataDetails(state) {
  return state.qrCode.dataDetails;
}
