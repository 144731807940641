
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { StepContent, makeStyles } from '@material-ui/core';
import { Tooltip } from 'antd';
import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import mountCardSteps from '../../../../app/utils/mountCardSteps';

const CardSteps = ({isConciliation, data, paymentTry})  => {
    const [activeStep, setActiveStep] = useState(1);
    const [steps, setSteps] = useState([]);
    const [stepsStatus, setStepsStatus] = useState([]);
    const [stepsError, setStepsError] = useState([]);

    useEffect(() => {
        let stepsObj = mountCardSteps(paymentTry);
            setSteps(stepsObj.steps);
            setStepsStatus(stepsObj.stepsStatus);
            setStepsError(stepsObj.stepsError);
    },[])


    const useStyles = makeStyles(()=> ({
        root: {
            padding: '0px',
            "& .MuiStepIcon-root": {color: "#BBBDC2" },
            "& .MuiStepIcon-active": { color: "#BBBDC2" },
            "& .MuiStepIcon-text": { fill: '#BBBDC2' },
            "& .MuiStepIcon-completed": { color: "#59AC7C" },
            "& .MuiStepConnector-lineVertical": { minHeight: '20px', borderLeftWidth: '3px'},
            "& .MuiStepConnector-vertical": {padding: '0px', marginLeft: '10.5px'}, 
            '& .MuiStepIcon-root.Mui-error': {color: '#BD3939'},
            "& svg": {display: 'block'}
        },

        label: {
            '& .MuiStepIcon-root.Mui-error': {color: '#FEBE03'},
            '& .MuiStepLabel-label.Mui-error': { color: '#FEBE03'}
        }
    }))

    const c = useStyles();

    return (
        <>
            <Box sx={{ width: '100%'}}>
                <Stepper className={c.root} activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={step.label} {...stepProps} completed={stepsStatus[index]}>

                        {stepsError[index] === 'challenge standby' ? 
                        <StepLabel
                            className={c.label}
                            {...labelProps}
                            error={stepsError[index]}
                        >
                            {step.label}
                            {stepsStatus[index] && <p className='transaction__dateDisplay'>{step.description}</p> }
                        </StepLabel>
                            :
                            <StepLabel 
                            {...labelProps} 
                            error={stepsError[index]}
                            >
                                {step.label}  
                            {stepsError[index] === true && 
                                <Tooltip color='#FFFFFF' overlayInnerStyle={{color: '#66696F'}} 
                                placement='right' title={data.failureReason}>
                                    <InfoCircleOutlined style={{marginLeft: '4px'}}/>
                                </Tooltip>}
                                {stepsStatus[index] && <p className='transaction__dateDisplay'>{step.description}</p> }
                            </StepLabel>
                        }
                        </Step>
                    );
                    })}
                </Stepper>
                </Box>
      </>
    )
  }

export default CardSteps;