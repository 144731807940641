import React from 'react';
import { Layout, Button, Row, Col, Collapse, Modal, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { ExportOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import DataTable from '../../../../components/shared/DataTable';
import { ApiActions } from '../../../../app/redux/actions';
import { AuthSelectors, SimulationSelectors, LoadingSelectors, TransactionSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_CREDIT_OFFERS_PAGINATED, ACTION_UPLOAD_CREDIT_DOCUMENT, ACTION_GET_SIMULATIONS, ACTION_EXPORT_CSV } from '../../../../app/redux/actions/financial';
import AdvancedRangePicker from '../../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';
import * as FileUtils from '../../../../app/utils/file';
import { parseDate } from '../../../../app/utils/date';
import { ACTION_SAVE_TRANSACTIONS_PAGINATED } from '../../../../app/redux/actions/transactions';
import * as DateUtils from '../../../../app/utils/date';
import { getTransactionOptions, statusText } from '../../../../app/utils/statusTransaction';
import { navigate } from '@reach/router';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../../components/shared/AdvancedSelect/AdvancedSelect';

class TransactionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        createdAt: null,
        createdAtRange: null,
        paymentType: '3',
        id: null,
      },
      individualTransaction: null
    };
    this.dataTableRef = React.createRef();
  }

  validityChange(key, keyRange, dates) {
    const { params } = this.state;
    const startAt = dates[0].format('YYYY-MM-DD').toString();
    const endtAt = dates[1].format('YYYY-MM-DD').toString();
    this.setState({
      params: {
        ...params,
        startAt,
        endtAt,
        [keyRange]: dates,
      },
    });
  }

  getPaginated(params) {
    params = {
      ...params,
      paymentType: 3
    };
    const { getPaginated } = this.props;
    this.setState({ params: { ...params } });
    getPaginated(params);
  }



  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const { dataPaginated, loading } = this.props;
    const { params, openModal } = this.state;
    return (
      <>
      {/* filtro */}
        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse className="advanced-filter">
            <Panel header={I18n.t('shared.advancedFilters.header')} key="0">
              <Row gutter={16}>
                <Col span={8}>
                <AdvancedSelect
                    label={I18n.t('routes.panel.admin.items.transactions.dataTable.columns.status.title')}
                    defaultValue={params && params.status}
                    value={params && params.status}
                    onChange={(val) => this.fieldChange('status', val)}
                    options={getTransactionOptions()}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput  label={I18n.t('routes.panel.admin.items.transactions.dataTable.columns.taxIdentifier.title')} 
                  value={params && params.taxIdentifier}
                  onChange={(val) => this.fieldChange('taxIdentifier', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput  label={I18n.t('routes.panel.admin.items.transactions.dataTable.columns.id.title')} 
                  value={params && params.id}
                  onChange={(val) => this.fieldChange('id', val)}
                  />
                </Col>
                <Col span={8}>
                <AdvancedRangePicker
                    label={I18n.t('shared.advancedFilters.createdAt')}
                    value={params && params.createdAtRange}
                    onChange={(val) => this.validityChange('createdAt', 'createdAtRange', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24} className="advanced-filter__search-button text-right">
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilters.clearButtonText')}
                    onClick={() => this.getPaginated()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilters.filterButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.getPaginated({ ...params, offset: 0 })}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginated(parameters)}
                data={dataPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                params={params}
                showSizeChanger
                pageSizeOptions={
                  dataPaginated && dataPaginated.count && dataPaginated.count > 100
                    ? ['10', '50', '100', dataPaginated.count.toString()]
                    : ['10', '20', '50', '100']
                }
                columns={[
                  {
                    key: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.createdAt.key'),
                    title: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.createdAt.title'),
                    render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm')  || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.value.key'),
                    title: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.value.title'),
                    render: (value) => `${value} BRL` || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.status.key'),
                    title: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.status.title'),
                    render: (status) => statusText(status) || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.taxIdentifier.key'),
                    title: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.taxIdentifier.title'),
                    render: (paymentOrder) => paymentOrder?.customer?.taxIdentifier || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.customer.key'),
                    title: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.customer.title'),
                    render: (paymentOrder) => paymentOrder?.customer?.name || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.id.key'),
                    title: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.id.title'),
                    render: (paymentOrderId) => paymentOrderId || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.transactions.dataTable.columns.transaction.key'),
                    title: '',
                    render: (id) =>                          
                  <Tooltip
                    title={I18n.t('shared.details')}
                    overlayStyle={{ fontSize: 11 }}
                  >
                    <Button
                      type="link"
                      icon={<PlusCircleOutlined />}
                      onClick={() => window.open(`${I18n.t('routes.panel.admin.items.transactions.url')}/${id}`, "_blank", "noreferrer")}
                    />
                  </Tooltip>
                  },
                ]}
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: TransactionSelectors.getDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(ApiActions.getPaginated(parameters, ENDPOINTS.transactions, ACTION_SAVE_TRANSACTIONS_PAGINATED)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
