import dayjs from "dayjs";
import React from "react";

export const ModalBoleto = ({ dataDetails, balance }) => { 

    const dueDate = dayjs().add(3, 'day').format('DD/MM/YYYY');

    return(
        <div style={{textAlign: 'center'}}>
            <h3 style={{fontSize: '18px'}}>Confirme os dados para emissão de boleto</h3>
            <p style={{margin: '0px', fontSize: '16px'}}>{`Valor total: R$ ${dataDetails.totalAmount}`}</p>
            <p style={{margin: '0px',fontSize: '16px'}}>{`Saldo disponível para entrada: R$ ${balance.toFixed(2)}`}</p>
            <p style={{margin: '0px',fontSize: '16px'}}>{`Valor do boleto: R$ ${(parseFloat(dataDetails.totalAmount) - balance).toFixed(2)}`}</p>
            <p style={{margin: '0px',fontSize: '16px'}}>Data de vencimento: {dueDate}</p>
        </div>
    )
};