import React from 'react';
import { Tabs } from 'antd';

export const { TabPane } = Tabs;

class AdvancedTabs extends React.Component {
  render() {
    const { active, onChange, centered, children } = this.props;
    return (
      <div className="advanced-tabs">
        <Tabs
          activeKey={active}
          onChange={onChange}
          centered={centered}
          defaultActiveKey={active}
        >
          {children}
        </Tabs>
      </div>
    );
  }
}

export default AdvancedTabs;