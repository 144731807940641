import Immutable from 'seamless-immutable';
import { TransactionActions } from '../actions';

const initialState = Immutable({
  dataPaginated: null,
  similarTransactions: null,
  dataInstallments: null,
  dataFeePayer: null

});

export default function transaction(
  state = initialState,
  action,
) {
  switch (action.type) {
    case TransactionActions.ACTION_SAVE_TRANSACTIONS_PAGINATED:
      state = {
        ...state,
        dataPaginated: action.payload,
      };
      return state;
    case TransactionActions.ACTION_SAVE_TRANSACTION_DETAIL:
      state = {
        dataPaginated: action.payload
      }
    case TransactionActions.ACTION_SAVE_SIMILAR_TRANSACTIONS:
      state = {
        ...state,
        similarTransactions: action.payload
      }
    case TransactionActions.ACTION_SAVE_TRANSACTION_INSTALLMENTS:
      state = {
        ...state,
        dataInstallments: action.payload
      }
    case TransactionActions.ACTION_SAVE_FEE_PAYER:
      state = {
        ...state,
        dataFeePayer: action.payload
      }
    default:
      return state;
  }
}

export function getDataPaginated(state) {
  return state.transaction.dataPaginated;
}

export function getSimilarTransactions(state) {
  return state.transaction.similarTransactions;
}

export function getDataIntallments(state) {
  return state.transaction.dataInstallments;
}

export function getFeePayer(state) {
  return state.transaction.dataFeePayer;
}