import Immutable from 'seamless-immutable';
import { ChartActions } from '../actions';

const initialState = Immutable({
  chartData: [],
  counterData: [],
});

export default function chart(state = initialState, action) {
  switch (action.type) {
    case ChartActions.ACTION_UPDATE_PARAMS:
      state = {
        ...state,
        params: action.payload,
      };
      return state;
    case ChartActions.ACTION_LOAD_BAR_CHART:
      state = {
        ...state,
        barData: action.payload,
      };

      return state;
    case ChartActions.ACTION_LOAD_COUNTERS:
      state = {
        ...state,
        counterData: action.payload,
      };
      return state;
    case ChartActions.ACTION_LOAD_RADIAL_CHART:
      state = {
        ...state,
        radialData: action.payload,
      };
      return state;
    case ChartActions.ACTION_CHANGE_CHART:
      state = {
        ...state,
      };
      return state;
    default:
      return state;
  }
}

export function getBarData(state) {
  return state.chartData.barData;
}
export function getCounterData(state) {
  return state.chartData.counterData;
}
export function getRadialData(state) {
  return state.chartData.radialData;
}
export function getChartParams(state) {
  return state.chartData.params;
}
