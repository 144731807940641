import { Paper } from "@material-ui/core";
import { Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";



const TransactionValues = ({ orderDetail }) => {
    const [mdr, setMDR] = useState(null);

    useEffect(() => {

        let transactionWithMDR = orderDetail?.transactions.filter(transaction => transaction.installments.length !== 0)[0];

        if(transactionWithMDR){
           let currentMDR = transactionWithMDR.installments.reduce((acc, obj) => {
                return acc + Number(obj.mdrFee)
            }, 0)

            setMDR(currentMDR);
        }
    },[])


    return (
        <>
        <Row gutter={48}>
            <Col span={12}>
                <Paper style={{padding: '16px'}}> 
                    <span className="transaction__title">Ordem de pagamento:</span>
                        {orderDetail?.items.map((item, index) => {
                            return(
                            <div key={index}>
                                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                                    <p className="transaction__value">{item.description}</p>
                                    <span className="transaction__valueMoney">{`R$ ${item.price}`}</span>
                                </div>  
                                <Divider style={{marginTop: '16px', marginBottom: '8px'}} />
                            </div>
                            )
                        })}
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                            <p className="transaction__value">SUBTOTAL</p>
                            <span className="transaction__valueMoney">{`R$ ${orderDetail?.totalAmount}`}</span>
                        </div>  
                </Paper>
            </Col>
            <Col span={12}>
                <Paper style={{padding: '16px'}}> 
                    <span className="transaction__title">Valor total:</span>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                            <p className="transaction__value">Valor Ordem de Pagamento</p>
                            <span className="transaction__valueMoney">{`R$ ${orderDetail?.totalAmount}`}</span>
                        </div>  
                        <Divider style={{marginTop: '16px', marginBottom: '8px'}} />
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                            <p className="transaction__value">Taxa cartão de crédito</p>
                            <span className="transaction__valueMoney">{orderDetail.interestValue && `R$ ${orderDetail.interestValue}`}</span>
                        </div>  
                        <Divider style={{marginTop: '16px', marginBottom: '8px'}} />
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                            <p className="transaction__value">SUBTOTAL</p>
                            <span className="transaction__valueMoney" style={{fontWeight: '700', fontSize: '16px'}}>{orderDetail.grossValue && `R$ ${orderDetail.grossValue}`}</span>
                        </div>  
                        <Divider style={{marginTop: '16px', marginBottom: '8px'}} />
                </Paper>
            </Col>
        </Row>
        {mdr &&         
        <Row gutter={48} style={{marginTop: '35px'}}>
            <Col span={12}>
                <Paper style={{padding: '16px'}}>
                    <div className="transaction__dashedDivider"></div>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                            <span className="transaction__title">MDR (alíquota pós conciliação)</span>  
                            <span className="transaction__valueMoney">{`R$ ${mdr}`}</span>
                    </div> 
  
                </Paper>  
            </Col>
        </Row>}

        </>
    )
}


export default TransactionValues