import React from 'react';
import { Layout, Button, Row, Col, Collapse, Modal } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { ExportOutlined, SearchOutlined } from '@ant-design/icons';
import DataTable from '../../../../components/shared/DataTable';
import { ApiActions } from '../../../../app/redux/actions';
import { AuthSelectors, SimulationSelectors, LoadingSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_CREDIT_OFFERS_PAGINATED, ACTION_UPLOAD_CREDIT_DOCUMENT, ACTION_GET_SIMULATIONS, ACTION_EXPORT_CSV } from '../../../../app/redux/actions/financial';
import AdvancedRangePicker from '../../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';
import * as FileUtils from '../../../../app/utils/file';
import { parseDate } from '../../../../app/utils/date';

class SimulationList extends React.Component {
  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.state = {
      params: {
        createdAt: null,
        createdAtRange: null
      }
    };
    this.dataTableRef = React.createRef();
  }

  validityChange(key, keyRange, dates) {
    const { params } = this.state;
    const startDate = dates[0].format('YYYY-MM-DD').toString();
    const endDate = dates[1].format('YYYY-MM-DD').toString();
    this.setState({
      params: {
        ...params,
        startDate,
        endDate,
        [keyRange]: dates,
      },
    });
  }

  exportCSV(params){
    params = { ...params };
    params['limit'] = params['pageSize'];
    delete params['pageSize'];
    const { exportSimulations } = this.props;
    exportSimulations(params, (data) => FileUtils.downloadAction(data, 'credit_simulations.xlsx'));
  }

  getSimulations(params) {
    params = {
      ...params,
      isDESC: 'true'
    };
    const { getSimulations } = this.props;
    this.setState({ params: { ...params } });
    getSimulations(params);
  }

  onCloseModal(){
    this.setState({ openModal: false });
  }


  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const { dataPaginated, loading } = this.props;
    const { params, openModal } = this.state;
    return (
      <>
      {/* filtro */}
        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse className="advanced-filter">
            <Panel header={I18n.t('shared.advancedFilters.header')} key="0">
              <Row gutter={16}>
                <Col span={8}>
                <AdvancedRangePicker
                    label={I18n.t('shared.advancedFilters.createdAt')}
                    value={params && params.createdAtRange}
                    onChange={(val) => this.validityChange('createdAt', 'createdAtRange', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24} className="advanced-filter__search-button text-right">
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilters.clearButtonText')}
                    onClick={() => this.getSimulations()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilters.filterButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.getSimulations({ ...params, offset: 0 })}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <Col className="panel__layout__content__actions__export-button text-right">
                <AdvancedButton
                  text={I18n.t('shared.panelActions.export')}
                  icon={<ExportOutlined />}     
                  onClick={() => this.exportCSV(params)}
                />
            </Col>
          <div className="administrators">
            <div>
              <DataTable
                getMethod={(parameters) => this.getSimulations(parameters)}
                data={dataPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                params={params}
                showSizeChanger
                pageSizeOptions={
                  dataPaginated && dataPaginated.count && dataPaginated.count > 100
                    ? ['10', '50', '100', dataPaginated.count.toString()]
                    : ['10', '20', '50', '100']
                }
                columns={[
                  {
                    key: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.id.key'),
                    title: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.id.title'),
                    render: (value) => value || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.proponent.key'),
                    title: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.proponent.title'),
                    render: (value) => value || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.document.key'),
                    title: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.document.title'),
                    render: (value) => value  || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.phone.key'),
                    title: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.phone.title'),
                    render: (value) => value  || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.value.key'),
                    title: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.value.title'),
                    render: (value) => value  || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.user.key'),
                    title: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.user.title'),
                    render: (value) => value  || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.entrance.key'),
                    title: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.entrance.title'),
                    render: (value) => value  || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.installment.key'),
                    title: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.installment.title'),
                    render: (value) => value  || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.createdAt.key'),
                    title: I18n.t('routes.panel.admin.items.simulation.dataTable.columns.createdAt.title'),
                    render: (value) => parseDate(value) || '--',
                  },
                ]}
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: SimulationSelectors.getDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getSimulations: (parameters) => dispatch(ApiActions.getSimulations(parameters, ENDPOINTS.simulations, ACTION_GET_SIMULATIONS)),
  exportSimulations: (parameters, callback) => dispatch(ApiActions.getSimulationsCSV(parameters,ENDPOINTS.simulationsCSV, callback, ACTION_EXPORT_CSV))
});

export default connect(mapStateToProps, mapDispatchToProps)(SimulationList);
