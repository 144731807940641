import moment from 'moment-timezone';

function humanizeDate(date, format) {
  try {
    if (date) {
      if (moment(date, 'YYYY-MM-DD').isValid()) {
        date = moment(date).format(format);
      }
    } else {
      date = '--';
    }
    return date;
  } catch (err) {
    //
  }
}

function humanizeDateTime(date, format) {
  try {
    if (date) {
      if (moment(date, 'YYYY-MM-DD HH:mm').isValid()) {
        date = moment(date).format(format);
      }
    } else {
      date = '--';
    }
    return date;
  } catch (err) {
    //
  }
}


function formatDateIsoString(isoString) {
  const date = new Date(isoString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

}

function parseDate(date, fromFormat) {
  try {
    if (date) {
      if (moment(date, fromFormat).isValid()) {
        date = moment(date, fromFormat).format('DD/MM/YYYY');
      }
    }
    return date;
  } catch (err) {
    //
  }
}

function getDuration(startTime, end) {
  try {
    const duration = moment.duration(moment(end).diff(moment(startTime)));
    return duration.asMinutes();
  } catch (err) {
    //
  }
}

export {
  humanizeDate,
  humanizeDateTime,
  parseDate,
  getDuration,
  formatDateIsoString
};
