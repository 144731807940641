import { isCpf } from './document';

export const cpfMask = (value) => (value ? value
  .replace(/\D/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1') : '');

export const cnpjMask = (value) => (value ? value
  .replace(/\D/g, '')
  .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'): '');


export const cepMask = (value) => (value ? value
  .replace(/\D/g, '')
  .replace(/(\d{5})(\d{1,2})/, '$1-$2')
  .replace(/(-\d{3})\d+?$/, '$1') : '');

export const dateOfBirthMask = (value) => (value ? value
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d{1,2})/, '$1/$2')
  .replace(/(\d{2})(\d{1,2})/, '$1/$2')
  .replace(/(\/\d{4})\d+?$/, '$1') : '');

export const phoneMask = (value) => (value ? value
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '($1) $2')
  .replace(/(\d{5}|\d{4})\D*(\d{4})/, '$1-$2')
  .replace(/(-\d{4})\d+?$/, '$1') : '');

export const maskCpfOrCnpj = (value) => isCpf(value) ? cpfMask(value) : cnpjMask(value);

export const unmaskField = (value) => (value ? value
  .replace(/[^A-Za-z0-9]/g, '').replace(/\/s/g, '') : '');
