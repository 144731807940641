import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider } from 'antd';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import { ViewType } from '../../../../app/enum/viewType';
import { CommandStatusMap } from '../../../../app/enum/commandStatus';
import CommandFormOperation from './operation/CommandFormOperation';

class CommandForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        id: null,
        externalId: null,
        sourceOp: null,
        operation: null,
        status: null,
        commandProcessed: null,
        transferAt: null,
        unlockAt: null,
        commandProcessedAt: null,
      },
    };
  }

  componentDidMount() {
    const { data, id } = this.props;
    if (data && id !== 'add') {
      this.setState({
        form: {
          id: data && data.id,
          externalId: data && data.externalId,
          sourceOp: data && data.sourceOp,
          operation: data && data.operation,
          status: data && data.status,
          commandProcessed: data && data.commandProcessed,
          transferAt: data && data.transferAt,
          unlockAt: data && data.unlockAt,
          commandProcessedAt: data && data.commandProcessedAt,
        },
      });
    }
  }

  render() {
    const { loading, viewType } = this.props;
    const { form } = this.state;
    return (
      <Row>
        {loading === 0 && (
          <Col span={24}>
            <form
              name="commandForm"
              onSubmit={(ev) => { }}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.command.details.form.externalId.label')}
                    value={form.externalId}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.command.details.form.status.label')}
                    value={form.status && CommandStatusMap.get(form.status)}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.command.details.form.commandProcessed.label')}
                    value={form.commandProcessed ? I18n.t('shared.yes') : I18n.t('shared.no')}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
              <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.command.details.form.id.label')}
                    value={ form.id }
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.command.details.form.commandProcessedAt.label')}
                    value={
                      form.commandProcessedAt
                      && DateUtils.humanizeDateTime(form.commandProcessedAt, 'DD/MM/YYYY HH:mm')
                    }
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.command.details.form.transferAt.label')}
                    value={
                      form.transferAt
                      && DateUtils.humanizeDateTime(form.transferAt, 'DD/MM/YYYY HH:mm')
                    }
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.command.details.form.unlockAt.label')}
                    value={
                      form.unlockAt
                      && DateUtils.humanizeDateTime(form.unlockAt, 'DD/MM/YYYY HH:mm')
                    }
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>

              </Row>

              {form.sourceOp &&
                <a
                  href={`${I18n.t('routes.panel.admin.items.paymentOrder.url')}/${form.sourceOp}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  type="link"
                >
                  {I18n.t('routes.panel.admin.items.command.details.form.paymentOrder.label')}
                </a>
              }

              {form.operation && <CommandFormOperation operation={form.operation} viewType={viewType} />}

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.cancel')}
                    href={I18n.t('routes.panel.admin.items.command.url')}
                  />
                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />
                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.save')}
                    icon={<SaveOutlined />}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

export default connect(
  mapStateToProps,
  null,
)(
  CommandForm,
);
