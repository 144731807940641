import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider } from 'antd';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import AdvancedTabs, { TabPane } from '../../../../components/shared/AdvancedTabs/AdvancedTabs';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import { PaymentOrderStatus } from '../../../../app/enum/paymentOrderStatus';

import PaymentOrderFormGeneral from './general/PaymentOrderFormGeneral';
import PaymentOrderFormBankSlip from './paymentMethod/bankSlip/PaymentOrderFormBankSlip';
import PaymentOrderFormBankSlipParcel from './paymentMethod/bankSlipParcel/PaymentOrderFormBankSlipParcel';
import PaymentOrderFormBalance from './paymentMethod/balance/PaymentOrderFormBalance';
import PaymentOrderFormCreditCard from './paymentMethod/creditCard/PaymentOrderFormCreditCard';
import PaymentOrderFormTransactions from './transactions/PaymentOrderFormTransactions';
import PaymentOrderFormSafeBalance from './safeBalance/PaymentOrderFormSafeBalance';
import PaymentOrderFormItems from './items/PaymentOrderFormItems';
import PaymentOrderFormWorkFlow from './workflow/WorkflowDetails'
class PaymentOrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        code: null,
        status: PaymentOrderStatus.WAITING_PAYMENT,
        externalIdentifier: null,
        message: null,
        totalAmount: null,
        currency: null,
        expirationDate: null,
        feePayer: null,
        selectPaymentAt: null,
        customerSelectedInstallments: null,
        selectPaymentMethod: null,
        safeBalance: null,
        items: null,
        workflow: null,
        customer: null,
        metadata: null,
        config: null,
        transactions: null,
        version: null
      },
      activeGeneralTab: '1',
      activeConfigTab: '1',
    };
  }

  componentDidMount() {
    const { data, id } = this.props;
    if (data && id !== 'add') {
      this.setState({
        form: {
          code: data && data.code,
          status: data && data.status,
          externalIdentifier: data && data.externalIdentifier,
          message: data && data.message,
          totalAmount: data && data.totalAmount,
          currency: data && data.currency,
          expirationDate: data && data.expirationDate,
          feePayer: data && data.feePayer,
          selectPaymentAt: data && data.selectPaymentAt,
          customerSelectedInstallments: data && data.customerSelectedInstallments,
          selectPaymentMethod: data && data.selectPaymentMethod,
          safeBalance: data && data.safeBalance,
          items: data && data.items,
          customer: data && data.customer,
          metadata: data && data.metadata,
          config: data && data.config,
          transactions: data && data.transactions,
          workflow: data && data.workflow,
          version: data && data.version
        },
        activeGeneralTab: '1',
        activeConfigTab: '1',
      });
    }
  }

  render() {
    const { loading, viewType, id, openModal, checkBilletBalance } = this.props;
    const { form, activeGeneralTab, activeConfigTab } = this.state;

    return (
      <Row>
        {loading === 0 && (
          <Col span={24}>
            <form name="paymentOrderForm" onSubmit={(ev) => this.onSubmit(ev)}>
              <AdvancedTabs
                active={activeGeneralTab}
                onChange={(val) => {
                  this.setState({ activeGeneralTab: val });
                }}
              >
                <TabPane key="1" tab={I18n.t('routes.panel.admin.items.paymentOrder.details.form.tabs.general')}>
                  <PaymentOrderFormGeneral general={form} viewType={viewType} />
                </TabPane>

                <TabPane key="2" tab={I18n.t('routes.panel.admin.items.paymentOrder.details.form.tabs.config')}>
                  <AdvancedTabs
                    active={activeConfigTab}
                    onChange={(val) => {
                      this.setState({ activeConfigTab: val });
                    }}
                  >
                    {form.config?.boleto && (
                      <TabPane key="1" tab={I18n.t('routes.panel.admin.items.paymentOrder.details.form.tabs.bankSlip')}>
                        <PaymentOrderFormBankSlip openModal={openModal} checkBilletBalance={checkBilletBalance} bankSlip={form.config.boleto} viewType={viewType} />
                      </TabPane>
                    )}

                    {form.config?.balance && (
                      <TabPane key="2" tab={I18n.t('routes.panel.admin.items.paymentOrder.details.form.tabs.balance')}>
                        <PaymentOrderFormBalance balance={form.config.balance} viewType={viewType} />
                      </TabPane>
                    )}

                    {form.config?.boletoParcelado && (
                      <TabPane
                        key="3"
                        tab={I18n.t('routes.panel.admin.items.paymentOrder.details.form.tabs.bankSlipParcel')}
                      >
                        <PaymentOrderFormBankSlipParcel
                          bankSlipParcel={form.config.boletoParcelado}
                          viewType={viewType}
                        />
                      </TabPane>
                    )}
                      {form.config?.cartaoCredito && (
                      <TabPane
                        key="4"
                        tab={I18n.t('routes.panel.admin.items.paymentOrder.details.form.tabs.creditCard')}
                      >
                        <PaymentOrderFormCreditCard creditCard={form.config.cartaoCredito} viewType={viewType} />
                      </TabPane>
                    )}
                  </AdvancedTabs>
                </TabPane>

                {form.transactions && (
                  <TabPane key="3" tab={I18n.t('routes.panel.admin.items.paymentOrder.details.form.tabs.transactions')}>
                    <PaymentOrderFormTransactions viewType={viewType} form={form} id={id} />
                  </TabPane>
                )}

                {form.safeBalance && (
                  <TabPane key="4" tab={I18n.t('routes.panel.admin.items.paymentOrder.details.form.tabs.safeBalance')}>
                    <PaymentOrderFormSafeBalance viewType={viewType} id={id} />
                  </TabPane>
                )}

                {form.items && (
                  <TabPane key="5" tab={I18n.t('routes.panel.admin.items.paymentOrder.details.form.tabs.items')}>
                    <PaymentOrderFormItems items={form.items} viewType={viewType} />
                  </TabPane>
                )}

                <TabPane key="6" tab={I18n.t('routes.panel.admin.items.paymentOrder.details.form.tabs.workflow')}>
                  <PaymentOrderFormWorkFlow id={id} />
                </TabPane>

              </AdvancedTabs>


              <Divider />

              <Row>
                <Col span={24} className="text-right">
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.cancel')}
                    href={I18n.t('routes.panel.admin.items.paymentOrder.url')}
                  />
                  <Divider className="form-button-divider" type="vertical" />
                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.save')}
                    icon={<SaveOutlined />}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

export default connect(mapStateToProps, null)(PaymentOrderForm);
