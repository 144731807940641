import React from "react";

export const ModalSaldo = ({ dataDetails, balance }) => { 
    return(
        <div style={{textAlign: 'center'}}>
            <h3 style={{fontSize: '18px'}}>Confirme os dados para pagamento</h3>
            <p style={{margin: '0px', fontSize: '16px'}}>{`Valor total: R$ ${dataDetails.totalAmount}`}</p>
            <p style={{margin: '0px',fontSize: '16px'}}>{`Saldo disponível para pagamento: R$ ${balance.toFixed(2)}`}</p>
            <p style={{color: 'darkred', marginTop: '16px'}}>{balance < parseFloat(dataDetails.totalAmount) && `SALDO INSUFICIENTE PARA PAGAMENTO`}</p>
        </div>
    )
};