import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from 'react-redux-i18n';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import { navigate } from '@reach/router';
import { humanizeDateTime } from '../../../../app/utils/date';
import getBrandImage from '../../../../app/utils/getBrand';


const TransactionData = ({ data, orderDetail, creditCard}) => {
    return (
      <>
        <Row gutter={16}>
          <Col span={8}>
            <p className='transaction__dataLabel'>Código de pagamento:</p>
            <p className='transaction__dataShow'>{orderDetail?.code}</p>
          </Col>
          <Col span={8}>
            <p className='transaction__dataLabel'>Identificador externo:</p>
            <p className='transaction__dataShow'>{orderDetail?.externalIdentifier}</p>
          </Col>
          <Col span={8}>
            <p className='transaction__dataLabel'>Identificação da ordem de pagamento:</p>
            <p className='transaction__dataShow'>{data?.paymentOrder}</p>
          </Col>
        </Row>
        <Row gutter={16} style={{marginTop: '22px'}}>
          <Col span={8}>
            <p className='transaction__dataLabel'>Nome do cliente</p>
            <p className='transaction__dataShow'>{data?.customer?.name}</p>
          </Col>
          <Col span={8}>
            <p className='transaction__dataLabel'>Documento:</p>
            <p className='transaction__dataShow'>{data?.customer?.taxIdentifier}</p>
          </Col>
          <Col span={8}>
          <p className='transaction__dataLabel'>Descrição:</p>
            <p className='transaction__dataShow'>{orderDetail?.message}</p>
          </Col>
        </Row>
        <Row gutter={16} style={{marginTop: '22px'}}>
          <Col span={8}>
            <p className='transaction__dataLabel'>Valor total:</p>
            <p className='transaction__dataShow'>{orderDetail?.totalAmount}</p>
          </Col>
          <Col span={8}>
          <p className='transaction__dataLabel'>Parcelamento:</p>
            <p className='transaction__dataShow'>{orderDetail?.customerSelectedInstallments}</p>
          </Col>
          <Col span={8}>
            <p className='transaction__dataLabel'>Cartão:</p>
            <p className='transaction__dataShow'>{getBrandImage(creditCard?.brand)}&nbsp;{`****${creditCard?.lastFourDigits}`}</p>
          </Col>
        </Row>
        <Row gutter={16} style={{marginTop: '22px'}}>
          <Col span={8}>
            <p className='transaction__dataLabel'>Previsão recebimento SBPAY</p>
            <p className='transaction__dataShow'>{orderDetail?.createdTransactions &&  humanizeDateTime(orderDetail?.createdTransactions[0]?.scheduledInstallments[0]?.date, 'DD/MM/YYYY HH:mm') }</p>
          </Col>
          <Col span={8}>
            <p className='transaction__dataLabel'>NSU</p>
            <p className='transaction__dataShow'>{creditCard?.acquireNSU}</p>
          </Col>
          <Col span={8}>
            <p className='transaction__dataLabel'>Código de autorização:</p>
            <p className='transaction__dataShow'>{creditCard?.authorizationCode}</p>
          </Col>
        </Row>
        <AdvancedButton style={{marginTop: '12px'}} onClick={() => navigate(`${I18n.t('routes.panel.admin.items.paymentOrder.url')}/${data?.paymentOrder}`)} text={"Ver ordem de pagamento"}/>
      </>
    )
}

export default TransactionData;