import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from 'react-redux-i18n';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import { ViewType } from '../../../../../app/enum/viewType';
import { PaymentOrderStatusMap } from '../../../../../app/enum/paymentOrderStatus';
import { maskCpfOrCnpj } from '../../../../../app/utils/mask';

class PaymentOrderFormGeneral extends React.Component {
  render() {
    const { viewType, general } = this.props;
    return (
      <>
        <Row gutter={16}>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.code.label')}
              value={general.code}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.externalIdentifier.label')}
              value={general.externalIdentifier}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.status.label')}
              value={general.status && PaymentOrderStatusMap.get(general.status)}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.totalAmount.label')}
              value={general.totalAmount && general.currency && `${general.totalAmount} ${general.currency}`}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.message.label')}
              value={general.message}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.expirationDate.label')}
              value={general.expirationDate}
              type="date"
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.customer.name.label')}
              value={general.customer?.name}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.customer.taxIdentifier.label')}
              value={general.customer?.taxIdentifier && maskCpfOrCnpj(general.customer.taxIdentifier)}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.safeBalance.label')}
              value={general.safeBalance
                ? I18n.t('shared.active')
                : I18n.t('shared.inactive')
              }
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
        </Row>

        {(general.selectPaymentAt || general.customerSelectedInstallments || general.selectPaymentMethod) &&
          <Row gutter={16}>
            {general.selectPaymentAt &&
              <Col span={8}>
                <AdvancedInput
                  label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.selectPaymentAt.label')}
                  value={general.selectPaymentAt}
                  type="date"
                  disabled={viewType === ViewType.EDIT}
                />
              </Col>
            }
            {general.customerSelectedInstallments &&
              <Col span={8}>
                <AdvancedInput
                  label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.customerSelectedInstallments.label')}
                  value={general.customerSelectedInstallments}
                  disabled={viewType === ViewType.EDIT}
                />
              </Col>
            }
            {general.selectPaymentMethod &&
              <Col span={8}>
                <AdvancedInput
                  label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.selectPaymentMethod.label')}
                  value={general.selectPaymentMethod}
                  disabled={viewType === ViewType.EDIT}
                />
              </Col>
            }
          </Row>
        }

        <Row gutter={16}>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.enableMultiplePaymentMethods.label')}
              value={general.config?.enableMultiplePaymentMethods
                ? I18n.t('shared.yes')
                : I18n.t('shared.no')
              }
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.acceptedPaymentMethods.label')}
              value={general.config?.acceptedPaymentMethods}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
          <Col span={8}>
            <AdvancedInput
                label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.version.label')}
                value={general?.version}
                disabled={viewType === ViewType.EDIT}
              />
          </Col>
        </Row>
      </>
    )
  }
}

export default PaymentOrderFormGeneral;
