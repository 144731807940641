import getFinancialInstance from "./financialConfig";



export default class ApiFinancial {
    static async getChartData(params, chartType) {
        const instance = await getFinancialInstance();
        let response;
        if(params){
            response = await instance.get(`/transaction-history/graph/${chartType}/${params}`)
        }
        else {
            response = await instance.get(`/transaction-history/graph/${chartType}`)
        }
        return response.data;
    }

    static async postDocument(params) {
        const instance  = await getFinancialInstance();
        const response = await instance.post('/transaction-history/import', params)
        return response;
    }
}