import React from 'react';
import {
  Divider,
  Layout,
  Button,
  Row,
  Col,
  message, Breadcrumb, Collapse, Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  SearchOutlined, DashboardOutlined, RetweetOutlined
} from '@ant-design/icons';
import DataTable from '../../../../components/shared/DataTable/DataTable';
import { ApiActions } from '../../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, UtilsSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedRangePicker from '../../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_UTILS_PAGINATED } from '../../../../app/redux/actions/utils';
import { GetIcon } from '../../../../app/enum/iconList';
import { getReprocessedConciliationFileOptions } from '../../../../app/utils/radioOptions';
import AdvancedRadio from '../../../../components/shared/AdvancedRadio/AdvancedRadio';

class ConciliationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        id: null,
        objectLine: null,
        transactionId: null,
        status: null,
        createdAt: null,
        createdAtRange: null,
      },
    };
    this.dataTableRef = React.createRef();
  }
  validityChange(key, keyRange, dates) {
    const { params } = this.state;

    const value = dates[0].format('YYYY-MM-DD').toString().concat(',',
      dates[1].format('YYYY-MM-DD').toString()
    );
    this.setState({
      params: {
        ...params,
        [key]: value,
        [keyRange]: dates,
      },
    });
  }

  getPaginated(params) {
    params = {
      ...params,
    };
    const { getPaginated } = this.props;
    this.setState({ params: { ...params } });
    getPaginated(params);
  }

  resend(transactionId) {
    this.props.resend(transactionId, () => {
      message.success(I18n.t('shared.messages.success.resendSuccess'));
      this.dataTableRef.current.reset();
    });
  }

  resendAllPending() {
    this.props.resendAllPending(() => {
      message.success(I18n.t('shared.messages.success.resendSuccess'));
      this.dataTableRef.current.reset();
    });
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const { dataPaginated, loading, me } = this.props;
    const { params } = this.state;
    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.admin.items.conciliationFile.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={I18n.t('shared.advancedFilters.header')}
              key="0"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedRangePicker
                    label={I18n.t('shared.advancedFilters.createdAt')}
                    value={params && params.createdAtRange}
                    onChange={(val) => this.validityChange('createdAt', 'createdAtRange', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.id')}
                    value={params && params.id}
                    onChange={(val) => this.fieldChange('id', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.transactionId')}
                    value={params && params.transactionId}
                    onChange={(val) => this.fieldChange('transactionId', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedRadio
                    label={I18n.t('shared.advancedFilters.status')}
                    value={params && params.status}
                    onChange={(val) => this.fieldChange('status', val)}
                    options={getReprocessedConciliationFileOptions()}
                    optionType="default"
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilters.clearButtonText')}
                    onClick={() => this.getPaginated()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilters.filterButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.getPaginated({ ...params, offset: 0 })}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.admin.items.conciliationFile.icon'),
                      { fill: '#F37223', width: 24, height: 24 },
                    )}
                  </span>
                  {I18n.t('routes.panel.admin.items.conciliationFile.pageTitle')}
                </h2>
              </Col>
              <Col className="text-right" span={12}>
                <AdvancedButton
                  text={I18n.t('routes.panel.admin.items.command.resendAllButton')}
                  icon={GetIcon(I18n.t('routes.panel.admin.items.command.icon'))}
                  onClick={() => this.resendAllPending()}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginated(parameters)}
                data={dataPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                params={params}
                showSizeChanger
                pageSizeOptions={
                  dataPaginated
                    && dataPaginated.count
                    && dataPaginated.count > 100
                    ? ['10', '50', '100', dataPaginated.count.toString()] : ['10', '20', '50', '100']
                }
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.id.key'),
                      title: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.id.title'),
                      render: (value) => (value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.transactionId.key'),
                      title: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.transactionId.title'),
                      render: (value) => (value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.fileType.key'),
                      title: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.fileType.title'),
                      render: (value) => value === 1 ? value === 3 ? 'Cancelamento' : 'Venda' : 'Movimentação' || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.status.status'),
                      title: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.status.title'),
                      render: (value) => value === 1 ? 'Pendente' : 'Ok' || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.objectLine.key'),
                      title: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.objectLine.title'),
                      render: (value) => (value) || '--',
                    },

                    {
                      key: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.conciliationFile.dataTable.columns.actions.key'),
                      title: '',
                      render: (id) => (
                        <div className="dataTable__item--right">
                          <Tooltip
                            title={I18n.t('shared.reprocessFile.reprocess')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<RetweetOutlined />}
                              disabled={me && me.id === id}
                              onClick={() => this.resend(id)}
                            />
                          </Tooltip>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: UtilsSelectors.getDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(
    ApiActions.getPaginated(parameters, ENDPOINTS.utils, ACTION_SAVE_UTILS_PAGINATED),
  ),
  resend: (id, callback) => dispatch(ApiActions.reprocessConciliationFile(id, ENDPOINTS.utils, callback)),
  resendAllPending: (callback) => dispatch(ApiActions.resendPendinConciliationFiles(`${ENDPOINTS.utils}/reprocess-pending-conciliation-file`, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ConciliationList,
);
