import { I18n } from 'react-redux-i18n';
import { FeePayerType } from '../enum/feePayerType';
import { CommandStatus } from '../enum/commandStatus';
import { ConciliationFileStatus } from '../enum/conciliationFileStatus';

export const getPaymentOrderSafeBalanceOptions = () => {
  return [
    {
      label: I18n.t('shared.all'),
      value: null,
    },
    {
      label: I18n.t('shared.active'),
      value: 'true',
    },
    {
      label: I18n.t('shared.inactive'),
      value: 'false',
    },
  ];
};

export const getPaymentOrderFeePayerOptions = () => {
  return [
    {
      label: I18n.t('shared.all'),
      value: null,
    },
    {
      label: I18n.t('shared.customer'),
      value: FeePayerType.CUSTOMER,
    },
    {
      label: I18n.t('shared.receiver'),
      value: FeePayerType.RECEIVER,
    },
  ];
};

export const getReprocessedConciliationFileOptions = () => {
  return [
    {
      label: I18n.t('shared.all'),
      value: null,
    },
    {
      label: I18n.t('shared.ok'),
      value: ConciliationFileStatus.OK,
    },
    {
      label: I18n.t('shared.pending'),
      value: ConciliationFileStatus.PENDENT,
    },
  ];
};

export const getCommandStatusOptions = () => {
  return [
    {
      label: I18n.t('shared.all'),
      value: null,
    },
    {
      label: I18n.t('shared.ok'),
      value: CommandStatus.Ok,
    },
    {
      label: I18n.t('shared.pending'),
      value: CommandStatus.PENDING,
    },
  ];
};

export const getCommandProcessedOptions = () => {
  return [
    {
      label: I18n.t('shared.all'),
      value: null,
    },
    {
      label: I18n.t('shared.yes'),
      value: 'true',
    },
    {
      label: I18n.t('shared.no'),
      value: 'false',
    },
  ];
};