import React from 'react';
import { Breadcrumb, Col, Divider, Layout, message, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { DashboardOutlined } from '@ant-design/icons';
import { ApiActions } from '../../../../app/redux/actions';
import { LoadingSelectors, UserSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import SettingsForm from '../form/SettingsForm';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_USER_DETAILS } from '../../../../app/redux/actions/user';
import { GetIcon } from '../../../../app/enum/iconList';

class SettingsDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			viewType: null,
			dataDetails: null,
		};
	}


	async componentDidMount() {
		const { getById, id } = this.props;
		try {
			await getById(id !== 'add' ? id : null);
			this.setState({ dataDetails: this.props.dataDetails });
		} catch (error) {
			//
		}
	}
	updateState(viewType) {
		this.setState({ viewType });
	}

	async submit(id, data) {
		const { update, create } = this.props;


		try {
			if (this.state.viewType === ViewType.ADD) {
				await create(data, () => {
					message.success(I18n.t('shared.messages.success.addSuccess'));
					navigate(I18n.t('routes.panel.admin.items.settings.url'));
				});
			} else {
				await update(id, data, () => {
					message.success(I18n.t('shared.messages.success.successEdit'));
					navigate(I18n.t('routes.panel.admin.items.settings.url'));
				});
			}
		} catch (e) {
			//
		}
	}


	render() {
		const urlParams = new URLSearchParams(window.location.search);
		const myParam = urlParams.get('viewType');
		let viewType
		if (myParam === "ADD") {
			viewType = ViewType.ADD

		} else if (myParam === "EDIT") {
			viewType = ViewType.EDIT

		} else {
			viewType = ViewType.VIEW

		}
		if (!this.state.viewType) this.updateState(viewType)


		const { Content } = Layout;
		const { dataDetails } = this.state;
		const { loading, id } = this.props;



		return (
			<>
				<Content className="panel__layout__content panel__layout__content--breadcrumb">
					<Breadcrumb>
						<Breadcrumb.Item>
							<DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<Link to={I18n.t('routes.panel.admin.items.settings.url')}>
								<span>{I18n.t('routes.panel.admin.items.settings.pageTitle')}</span>
							</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<span>
								{I18n.t(
									viewType === ViewType.ADD
										? 'routes.panel.admin.items.settings.pageTitleAdd'
										: viewType === ViewType.VIEW ? 'routes.panel.admin.items.settings.pageTitleView' : 'routes.panel.admin.items.settings.pageTitleEdit'
								)}
							</span>
						</Breadcrumb.Item>
					</Breadcrumb>
				</Content>

				<Content className="panel__layout__content">
					<div className="Settings-details">
						<Spin spinning={loading > 0} tip={I18n.t('shared.loading')}>
							<div className="panel__layout__content__title">
								<h2 className="panel__layout__content__title__value">
									<span className="panel__layout__content__title__value__icon">
										{GetIcon(I18n.t('routes.panel.admin.items.adiministrator.icon'), {
											fill: '#F37223',
											width: 24,
											height: 24,
										})}
									</span>
									{I18n.t(
										viewType === ViewType.ADD
											? 'routes.panel.admin.items.settings.pageTitleAdd'
											: viewType === ViewType.VIEW ? 'routes.panel.admin.items.settings.pageTitleView' : 'routes.panel.admin.items.settings.pageTitleEdit'
									)}
								</h2>
								<p className="panel__layout__content__title__value__description">
									{I18n.t(
										viewType === ViewType.ADD
											? 'routes.panel.admin.items.settings.pageDescriptionAdd' :
											viewType === ViewType.VIEW ? 'routes.panel.admin.items.settings.pageDescriptionView' : 'routes.panel.admin.items.settings.pageDescriptionEdit'
									)}
								</p>
							</div>
							<Divider />
							<div>
								<Row>
									<Col span={24}>
										{(dataDetails || viewType === ViewType.ADD) && (
											<SettingsForm
												id={id}
												data={dataDetails}
												submitFunction={(dataId, data) => this.submit(dataId, data)}
												viewType={viewType}
												loading={loading}
											/>
										)}
									</Col>
								</Row>
							</div>
						</Spin>
					</div>
				</Content>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	dataDetails: UserSelectors.getDataDetails(state),
	loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
	getById: (id) => dispatch(ApiActions.getById(id, ENDPOINTS.settings, ACTION_SAVE_USER_DETAILS)),
	update: (id, data, callback) => dispatch(ApiActions.put(id, data, ENDPOINTS.settings, callback)),
	create: (data, callback) => dispatch(ApiActions.post(data, ENDPOINTS.settings, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDetails);