import React from 'react';
import {ReactComponent as VisaIcon} from '../../app/assets/img/ic_visa.svg';
import {ReactComponent as MasterIcon} from '../../app/assets/img/ic_master.svg';

export default function getBrandImage(brand){

    switch(brand){
        case 'Mastercard':
        return (
            <MasterIcon width={25} height={15} />
        )
        case 'Visa':
        return(
            <VisaIcon width={25} height={15} />
        )
    }
}