import Immutable from 'seamless-immutable';
import { DashboardActions } from '../actions';

const initialState = Immutable({
  systemStatus: null,
  concilieData: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case DashboardActions.ACTION_SAVE_DASHBOARD_SYSTEM_STATUS:
      state = {
        ...state,
        systemStatus: action.payload,
      };
      return state;
    case DashboardActions.ACTION_LOAD_CONCILIE_DATA:
      state = {
        ...state,
        concilieData: action.payload,
      };

      return state;
    default:
      return state;
  }
};

export function getSystemStatus(state) {
  return state.dashboard.systemStatus;
}

export function getConcilieData(state) {
  return state.dashboard.concilieData;
}
