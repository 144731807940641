import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { getWorkFlow } from '../../../../../app/redux/actions/api';
import ReactFlow, {
  removeElements,
  addEdge,
  MiniMap,
  Controls,
  Background,
  ControlButton 
} from 'react-flow-renderer';

import { getDataflow } from './helpers';

const PaymentOrderFormWorkFlow = (props) => {
  const dispatch = useDispatch()
  const { dataWorkFlow } = useSelector(state => state.paymentOrder);
  const [elements, setElements] = useState(getDataflow(dataWorkFlow));
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const onElementsRemove = (elementsToRemove) =>
  setElements((els) => removeElements(elementsToRemove, els));
  const onConnect = (params) => setElements((els) => addEdge(params, els));

  useEffect( () => {
    if(reactFlowInstance) {
      setTimeout(
      () => reactFlowInstance.fitView()
      , 500);
    }
  }, [dataWorkFlow]);

  useEffect( () => {
    if(!dataWorkFlow || dataWorkFlow.id !== props.id){
      dispatch(getWorkFlow(props.id))
    }
  }, []);

  return (
    <div style={{height:'600px'}}>
    <ReactFlow
      elements={elements}
      onElementsRemove={onElementsRemove}
      onConnect={onConnect}
      onLoad={setReactFlowInstance}
      snapToGrid={true}
      snapGrid={[15, 15]}
    >
      <Controls>
      <ControlButton onClick={() => console.log('action')}>
      </ControlButton>
      <ControlButton onClick={() => console.log('another action')}>
      </ControlButton>
      <h1>
      <MiniMap
        nodeStrokeColor={(n) => {
          if (n.style?.background) return n.style.background;
          if (n.type === 'input') return '#0041d0';
          if (n.type === 'output') return '#ff0072';
          if (n.type === 'default') return '#1a192b';

          return '#eee';
        }}
        nodeColor={(n) => {
          if (n.style?.background) return n.style.background;

          return '#fff';
        }}
        nodeBorderRadius={2}
      />
      </h1>
      </Controls>
      <Background color="#aaa" gap={16} />
    </ReactFlow>
    </div>
    )
};
export default PaymentOrderFormWorkFlow