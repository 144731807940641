import Axios from 'axios';
import getInstance from './config';
import config from '../../app.config';
import { handleAxiosError } from '../utils/api';
import { ENDPOINTS } from '../endpoints/endpoints';

export default class AuthApi {
  static async me() {
    const instance = await getInstance();
    const { data } = await instance.get('/suser/me');
    return data;
  }

  static async payInstance(userToken) {
    const headers = {
      'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
    };
  
    const axiosInstance = Axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers,
    });
  
    axiosInstance.interceptors.response.use((response) => response,
      async (err) => {
        return Promise.reject(handleAxiosError(err));
      });
  
    return axiosInstance;
  }

}
