import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Modal } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';

class UserAccountChangeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
          externalId: null,
          name: null,
          accountId: null,
          photoUrl: null,
          email:  null,
          cellphone: null,
          taxIdentifier: null,
          personType: null,
          mothersName: null,
          birthDate: null,
          gender: null,
          city: null,
          complement: null,
          country: null,
          district: null,
          number: null,
          state: null,
          street: null,
          uf: null,
          zipcode: null,
      },
    };
  }

  componentDidMount() {
    const { data, id } = this.props;
    if (data && id !== 'add') {
      this.setState({
        form: {
          externalId: data && data.externalId,
          accountId: data && data.accountId,
          name: data && data.name,
          photoUrl: data && data.photoUrl,
          email: data && data.email,
          cellphone: data && data.cellphone,
          taxIdentifier: data && data.taxIdentifier,
          personType: data && data.personType,
          mothersName: data && data.mothersName,
          birthDate: data && data.birthDate,
          gender: data && data.gender,
          city: data && data.address.city,
          complement: data && data.address.complement,
          country: data && data.address.countryName,
          district: data && data.address.district,
          number: data && data.address.number,
          state: data && data.address.state,
          street: data && data.address.street,
          uf: data && data.address.uf,
          zipcode: data && data.address.zipCode

        },
      });
    }
  }

  confirmOnSubmit(e) {
    e.preventDefault();

    Modal.confirm({
      title: I18n.t('routes.panel.admin.items.accountChange.details.form.warning.confirmation'),
      content: (
        <>
          <ul>
            <li>
              {
                I18n.t(
                  'routes.panel.admin.items.accountChange.details.form.warning.balance'
                )
             }
            </li>
            <li>
              {
                I18n.t(
                  'routes.panel.admin.items.accountChange.details.form.warning.extract'
                )
              }
            </li>
            <li>
              {
                I18n.t(
                  'routes.panel.admin.items.accountChange.details.form.warning.irreversible'
                )
              }
            </li>
          </ul>
        </>
      ),
      onOk: () => this.onSubmit(),
    });
  }

  onSubmit() {
    const { data, submitFunction } = this.props;

    try {
      submitFunction(data.id);
    } catch (error) {
      //
    }
  }

  render() {
    const { loading, id } = this.props;

    return (
      <Row>
        {loading === 0 && (
          <Col span={24}>
            <form
              name="userAccountChangeForm"
              onSubmit={(ev) => this.confirmOnSubmit(ev)}
            >
              <div className="panel__layout__content__details__form">
                <ul>
                  <li>
                    {
                      I18n.t(
                        'routes.panel.admin.items.accountChange.details.form.rules.balance'
                      )
                    }
                  </li>
                </ul>
              </div>

              <Divider/ >

              <Row gutter={16}>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.cancel')}
                    href={`${I18n.t('routes.panel.admin.items.search.url')}/${id}`}
                  />
                  <Divider className="form-button-divider" type="vertical" />
                  <AdvancedButton 
                    htmlType="submit" 
                    text={I18n.t('shared.confirm')} 
                    icon={<SaveOutlined />} 
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

export default connect(
  mapStateToProps,
  null,
)(
  UserAccountChangeForm,
);
