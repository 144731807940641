import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from 'react-redux-i18n';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import { ViewType } from '../../../../../app/enum/viewType';
import { maskCpfOrCnpj } from '../../../../../app/utils/mask';

class PaymentOrderFormReceivers extends React.Component {
  render() {
    const { viewType, receivers } = this.props;
    return (
      <>
        <label>{I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.receivers.label')}</label>
        {receivers.length && receivers.map((o) => (
          <Row gutter={16}>
            <Col span={6}>
              <AdvancedInput
                label={
                  I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.receivers.externalId.label')
                }
                value={o.externalId}
                disabled={viewType === ViewType.EDIT}
              />
            </Col>
            <Col span={6}>
              <AdvancedInput
                label={
                  I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.receivers.amount.label')
                }
                value={o.amount}
                disabled={viewType === ViewType.EDIT}
              />
            </Col>
            <Col span={6}>
              <AdvancedInput
                label={
                  I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.receivers.user.name.label')
                }
                value={o.user?.name}
                disabled={viewType === ViewType.EDIT}
              />
            </Col>
            <Col span={6}>
              <AdvancedInput
                label={
                  I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.receivers.user.userTaxIdentifier.label')
                }
                value={o.user?.userTaxIdentifier && maskCpfOrCnpj(o.user.userTaxIdentifier)}
                disabled={viewType === ViewType.EDIT}
              />
            </Col>
          </Row>
        ))}
      </>
    )
  }
}

export default PaymentOrderFormReceivers;