import React from 'react';
import { Layout, Button, Row, Col, Collapse, Modal } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { SearchOutlined } from '@ant-design/icons';
import DataTable from '../../../../components/shared/DataTable';
import { ApiActions } from '../../../../app/redux/actions';
import { AuthSelectors, FinancialSelectors, LoadingSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_CREDIT_OFFERS_PAGINATED, ACTION_UPLOAD_CREDIT_DOCUMENT } from '../../../../app/redux/actions/financial';
import DocumentModal from '../documentModal';
import {ReactComponent as UploadIcon} from '../../../../app/assets/img/file_upload.svg';
import {ReactComponent as DownloadIcon} from '../../../../app/assets/img/file_download.svg';
import CreditStatus from '../creditStatus';
import AdvancedSelect from '../../../../components/shared/AdvancedSelect/AdvancedSelect';

class FinancialList extends React.Component {
  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.state = {
      params: {
        status: null,
        evento: null,
        document: null
      },
      openModal: false
    };
    this.dataTableRef = React.createRef();
  }

  getFinancialPaginated(params) {
    params = {
      ...params,
    };
    const { getFinancialPaginated } = this.props;
    this.setState({ params: { ...params } });
    getFinancialPaginated(params);
  }

  onCloseModal(){
    this.setState({ openModal: false });
  }


  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const { dataPaginated, loading, uploadCreditDocument } = this.props;
    const { params, openModal } = this.state;

    return (
      <>
      {/* filtro */}
        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse className="advanced-filter">
            <Panel header={I18n.t('shared.advancedFilters.header')} key="0">
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.admin.items.credit.dataTable.columns.status.title')}
                    value={params && params.status}
                    onChange={(val) => this.fieldChange('status', val)}
                    options={[{title: 'Pendente', id: 'PENDING'},{ title: 'Concluído', id: 'FINISHED'}]}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24} className="advanced-filter__search-button text-right">
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilters.clearButtonText')}
                    onClick={() => this.getFinancialPaginated()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilters.filterButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.getFinancialPaginated({ ...params, offset: 0 })}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <div>
              <DataTable
                getMethod={(parameters) => this.getFinancialPaginated(parameters)}
                data={dataPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                params={params}
                showSizeChanger
                pageSizeOptions={
                  dataPaginated && dataPaginated.count && dataPaginated.count > 100
                    ? ['10', '50', '100', dataPaginated.count.toString()]
                    : ['10', '20', '50', '100']
                }
                columns={[
                  {
                    key: I18n.t('routes.panel.admin.items.credit.dataTable.columns.status.key'),
                    title: I18n.t('routes.panel.admin.items.credit.dataTable.columns.status.title'),
                    render: (value) => CreditStatus(value) || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.credit.dataTable.columns.offer.key'),
                    title: I18n.t('routes.panel.admin.items.credit.dataTable.columns.offer.title'),
                    render: (loan) => loan.id|| '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.credit.dataTable.columns.document.key'),
                    title: I18n.t('routes.panel.admin.items.credit.dataTable.columns.document.title'),
                    render: (loan) => <><p style={{margin: '0px'}}>{loan.document}</p><p style={{margin: '0px'}}>{loan.proponent}</p></>  || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.credit.dataTable.columns.actions.key'),
                    title: '',
                    render: (loan) => (
                      <div className="dataTable__item--right" style={{display: 'flex', justifyContent: 'center'}}>
                        <Modal 
                        style={{padding: '0px'}} 
                        open={openModal} 
                        onCancel={this.onCloseModal}
                        width={644}
                        footer={null}
                        >
                            <DocumentModal 
                              onClose={this.onCloseModal}
                              uploadDocuments={uploadCreditDocument}
                              key={loan.id}
                              loan={loan}
                            />
                        </Modal>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div  style={{display: 'flex', alignItems: 'center'}}>
                              <Button
                                  type="link"
                                  icon={<DownloadIcon />}
                                  onClick={() => this.setState({ openModal: true })}
                                />
                                <span>Documento de exceção</span>
                            </div>
                            <div style={{width: '100%',textAlign: 'start', display: 'flex', alignItems: 'center'}}>
                              <Button
                                type="link"
                                icon={<UploadIcon />}
                                onClick={() => this.setState({ openModal: true })}
                              >
                                
                              </Button>
                              <span>Enviar documento</span>
                            </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: FinancialSelectors.getDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getFinancialPaginated: (parameters) =>
    dispatch(ApiActions.getFinancialPaginated(parameters, ENDPOINTS.creditOffer, ACTION_SAVE_CREDIT_OFFERS_PAGINATED)),
  uploadCreditDocument: (parameters) => 
    dispatch(ApiActions.uploadCreditDocuments(parameters,ENDPOINTS.uploadCreditDocuments, ACTION_UPLOAD_CREDIT_DOCUMENT))
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialList);
