import { Card, Layout, Row, Typography, Divider, Collapse, Space, Radio, Button, Select, DatePicker, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import Chart from "react-apexcharts";

const { Content } = Layout;
const { Panel } = Collapse;
const { RangePicker, MonthPicker } = DatePicker;

const HeaderCard = ({ title, value }) => {
  return (
    <Card title={title} style={{ minWidth: 200 }}>
      <Typography style={{ textAlign: "center", fontWeight: "bold", fontSize: 18 }} >{value}</Typography>
    </Card>
  )
}

const AnalyticsView = (props) => {
  const {
    counters,
    subjectOptions,
    setFieldValue,
    submitForm,
    subFilterOptions,
    selectDefaultFilter,
    formValues,
    radialChartData,
    radialChart,
    resetFilter,
    loading
  } = props;

  // const chart = {
  //   options: {
  //     chart: {
  //       id: "basic-bar",
  //       fontFamily: "Roboto"
  //     },
  //     plotOptions: {
  //       bar: {
  //         borderRadius: 4,
  //         horizontal: true,
  //       },
  //     },
  //     xaxis: {
  //       categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
  //     },
  //     fill: {
  //       colors: ["#4197e3"],
  //     }
  //   },
  //   series: [
  //     {
  //       name: "series-1",
  //       data: [30, 40, 45, 50, 49, 60, 70, 91]
  //     }
  //   ]
  // }

  return (
    <Content style={{ padding: "0 32px" }}>
      <Row style={{ gap: 24, marginTop: 32 }} align="middle" justify="center">
        {counters.map((counter, index) =>
          <HeaderCard key={index} title={counter.title} value={counter.value} />
        )}
      </Row>

      <Divider dashed />

      <Collapse>
        <Panel header="Filtros">
          <Row style={{ gap: 16 }}>
            {/* <Select
              name="type"
              placeholder="Filtrar por"
              style={{ width: 200 }}
              size="large"
              options={subjectOptions}
              onChange={value => setFieldValue('type', value)}
              onSelect={selectDefaultFilter}
              defaultActiveFirstOption
            />

            {subFilterOptions &&
              <Select
                name="subType"
                placeholder="Categoria"
                style={{ width: 200 }}
                options={subFilterOptions}
                size="large"
                value={formValues.subType}
                onChange={value => setFieldValue('subType', value)}
                defaultActiveFirstOption
              />
            } */}

            <RangePicker
              name="dateRange"
              picker="date"
              format="DD/MM/YYYY"
              onChange={value => {
                setFieldValue("dateFrom", dayjs(value[0]).format("YYYY/MM/DD"));
                setFieldValue("dateTo", dayjs(value[1]).format("YYYY/MM/DD"));
                setFieldValue("rawDate", value);
              }}
              value={formValues.rawDate}
            />
          </Row>
          <Row style={{ marginTop: 16, justifyContent: "flex-end", gap: 16 }}>
            <Button
              type="ghost"
              size="large"
              style={{ width: 120 }}
              onClick={resetFilter}
            >
              Limpar
            </Button>
            <Button
              type="primary"
              size="large"
              style={{ width: 120 }}
              onClick={submitForm}
            >
              Aplicar
            </Button>
          </Row>
        </Panel>
      </Collapse>

      <Row style={{ padding: 24, gap: 24, alignItems: "center", justifyContent: "center" }}>
        <Card title="Uso dos métodos de pagamentos" className="card-chart" style={{ display: "flex", flexDirection: "column", padding: 20, alignItems: "center", justifyContent: "center" }}>
          {loading ? (
            <Spin
              wrapperClassName="card-chart"
              size="default"
            />
          ) : (
            <Chart
              options={radialChart.chartOptions}
              series={radialChart.series}
              type="pie"
              width="500"
            />
          )}
        </Card>

        {/* <Card title="OP's criadas" style={{ padding: 20, alignItems: "center", justifyContent: "center" }}>
          <Chart
            options={chart.options}
            series={chart.series}
            type="bar"
            width="500"
          />
        </Card> */}
      </Row>
    </Content >
  )
}

export { AnalyticsView };
