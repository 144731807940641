import Immutable from 'seamless-immutable';
import { PaymentOrderActions } from '../actions';

const initialState = Immutable({
  dataPaginated: null,
  dataDetails: null,
  dataTransactions: null,
  dataSafeBalance: null,
  dataWorkFlow: null,
  dataUserPaginated:null,
  dataCreditCard: null,
});

export default function paymentOrder(
  state = initialState,
  action,
) {
  switch (action.type) {
    case PaymentOrderActions.ACTION_SAVE_PAYMENT_ORDERS_PAGINATED:
      state = {
        ...state,
        dataPaginated: action.payload,
      };
      return state;
      case PaymentOrderActions.ACTION_SAVE_USER_PAYMENT_ORDERS_PAGINATED:
        state = {
          ...state,
          dataUserPaginated: action.payload,
        };
        return state;
    case PaymentOrderActions.ACTION_SAVE_PAYMENT_ORDER_DETAILS:
      state = {
        ...state,
        dataDetails: action.payload,
      };
      return state;
    case PaymentOrderActions.ACTION_CLEAN_PAYMENT_ORDER:
      state = {
        ...initialState,
      };
      return state;
    case PaymentOrderActions.ACTION_SAVE_PAYMENT_ORDER_TRANSACTIONS:
      state = {
        ...state,
        dataTransactions: action.payload,
      };
      return state;
    case PaymentOrderActions.ACTION_SAVE_PAYMENT_ORDER_SAFE_BALANCE:
      state = {
        ...state,
        dataSafeBalance: action.payload,
      };
      return state;
      case PaymentOrderActions.ACTION_SAVE_PAYMENT_ORDER_WORK_FLOW:
      state = {
        ...state,
        dataWorkFlow: action.payload,
      };
      return state
      case PaymentOrderActions.ACTION_SAVE_CREDITCARD_PAYMENT_ORDER:
      state = {
        ...state,
        dataCreditCard: action.payload
      }
    default:
      return state;
  }
}

export function getDataPaginated(state) {
  return state.paymentOrder.dataPaginated;
}

export function getUserDataPaginated(state) {
  return state.paymentOrder.dataUserPaginated;
}

export function getDataDetails(state) {
  return state.paymentOrder.dataDetails;
}

export function getDataTransactions(state) {
  return state.paymentOrder.dataTransactions;
}

export function getDataSafeBalance(state) {
  return state.paymentOrder.dataSafeBalance;
}

export function getDataWorkFlow(state) {
  return state.paymentOrder.dataWorkFlow;
}

export function getDataCreditCard(state){
  return state.paymentOrder.dataCreditCard;
}