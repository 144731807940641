import React from 'react';
import {
  Divider,
  Layout,
  Row,
  Col,
  Breadcrumb, 
  Collapse,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  SearchOutlined, DashboardOutlined
} from '@ant-design/icons';
import DataTable from '../../../../components/shared/DataTable/DataTable';
import { ApiActions } from '../../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, FeePayerSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedRangePicker from '../../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_FEE_PAYER_PAGINATED } from '../../../../app/redux/actions/fee-payer';
import { GetIcon } from '../../../../app/enum/iconList';

class AccoutingGuideList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        eventGuideId: null,
        paymentOrderId: null,
        transactionId: null,
        createdAt: null,
        createdAtRange: null,
        fee: null,
        primaryNSU: null,
        acquireNSU: null,
        orderAmount: null,
        totalAmount: null,
      },
    };
    this.dataTableRef = React.createRef();
  }

  validityChange(key, keyRange, dates) {
    const { params } = this.state;

    const value = dates[0].format('YYYY-MM-DD').toString().concat(',',
      dates[1].format('YYYY-MM-DD').toString()
    );

    this.setState({
      params: {
        ...params,
        [key]: value,
        [keyRange]: dates,
      },
    });
  }

  getPaginated(params) {
    params = {
      ...params,
    };
    const { getPaginated } = this.props;
    this.setState({ params: { ...params } });
    getPaginated(params);
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const { dataPaginated, loading } = this.props;
    const { params } = this.state;
    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.admin.items.accountingGuide.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={I18n.t('shared.advancedFilters.header')}
              key="0"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedRangePicker
                    label={I18n.t('shared.advancedFilters.createdAt')}
                    value={params && params.createdAtRange}
                    onChange={(val) => this.validityChange('createdAt', 'createdAtRange', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.eventGuideId')}
                    value={params && params.eventGuideId}
                    onChange={(val) => this.fieldChange('eventGuideId', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.transactionId')}
                    value={params && params.transactionId}
                    onChange={(val) => this.fieldChange('transactionId', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.sourceOp')}
                    value={params && params.paymentOrderId}
                    onChange={(val) => this.fieldChange('paymentOrderId', val)}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.primaryNSU')}
                    value={params && params.primaryNSU}
                    onChange={(val) => this.fieldChange('primaryNSU', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.acquireNSU')}
                    value={params && params.acquireNSU}
                    onChange={(val) => this.fieldChange('acquireNSU', val)}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilters.clearButtonText')}
                    onClick={() => this.getPaginated()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilters.filterButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.getPaginated({ ...params, offset: 0 })}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.admin.items.accountingGuide.icon'),
                      { fill: '#F37223', width: 24, height: 24 },
                    )}
                  </span>
                  {I18n.t('routes.panel.admin.items.accountingGuide.pageTitle')}
                </h2>
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginated(parameters)}
                data={dataPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                params={params}
                showSizeChanger
                pageSizeOptions={
                  dataPaginated
                    && dataPaginated.count
                    && dataPaginated.count > 100
                    ? ['10', '50', '100', dataPaginated.count.toString()] : ['10', '20', '50', '100']
                }
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.transactionId.key'),
                      title: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.transactionId.title'),
                      render: (value) => (value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.paymentOrderId.key'),
                      title: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.paymentOrderId.title'),
                      render: (value) => (value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.eventGuideId.key'),
                      title: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.eventGuideId.title'),
                      render: (value) => (value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.primaryNSU.key'),
                      title: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.primaryNSU.title'),
                      render: (value) => (value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.acquireNSU.key'),
                      title: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.acquireNSU.title'),
                      render: (value) => (value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.orderAmount.key'),
                      title: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.orderAmount.title'),
                      render: (value) => (value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.totalAmount.key'),
                      title: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.totalAmount.title'),
                      render: (value) => (value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.fee.key'),
                      title: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.fee.title'),
                      render: (value) => (value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.admin.items.accountingGuide.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: FeePayerSelectors.getDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(
    ApiActions.getGuiaContabilPaginated(parameters, ENDPOINTS.feePayer, ACTION_SAVE_FEE_PAYER_PAGINATED),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  AccoutingGuideList,
);
