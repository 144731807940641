import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider,
  Layout, message, Modal, Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { DashboardOutlined } from '@ant-design/icons';
import { ApiActions } from '../../../../app/redux/actions';
import { LoadingSelectors, PaymentOrderSelectors, UserSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import PaymentOrderForm from '../form/PaymentOrderForm';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_CLEAN_PAYMENT_ORDER, ACTION_SAVE_PAYMENT_ORDER_DETAILS } from '../../../../app/redux/actions/paymentOrder';
import { GetIcon } from '../../../../app/enum/iconList';
import { ACTION_SAVE_USER_BALANCE, ACTION_SAVE_USER_DETAILS } from '../../../../app/redux/actions/user';
import { ModalBoleto } from './ModalBoleto';
import { ModalSaldo } from './ModalSaldo';
import ReactModal from 'react-modal';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';

ReactModal.setAppElement('#root');
ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.5)';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
    height: '300px',
    padding: '40px',
    gap: '40px'
  },
};

class PaymentOrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.privatePay = this.privatePay.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getAvailableBalance = this.getAvailableBalance.bind(this);
    this.checkBilletBalance = this.checkBilletBalance.bind(this);
    this.state = {
      viewType: ViewType.EDIT,
      dataDetails: null,
      modalType: null,
      modalOpen: false,
      paymentMethods: [],
      balance: 0,
    };
  }

  

  async componentDidMount() {
    const { getById, id, clean, getBalance, getUserById } = this.props;

    this.setState({
      viewType: id !== 'add' ? ViewType.EDIT : ViewType.ADD,
    });

    try {
      await clean();
      await getById(id !== 'add' ? id : null);
      this.setState({ dataDetails: this.props.dataDetails });
      await getUserById(this.props.dataDetails.customer.account);
      await getBalance(this.props.user.accountId);
      const balance = this.getAvailableBalance();
      this.setState({ balance: balance});
    } catch (error) {
      console.log(error);
    }
  }

  closeModal () {
    this.setState({modalOpen: false})
  }

  openModal (type, paymentMethods) {
    this.setState({modalOpen: true})
    this.setState({modalType: type })
    this.setState({paymentMethods: paymentMethods });
  }

  async submit(id, data) {
    const { update, create } = this.props;

    try {
      if (this.state.viewType === ViewType.ADD) {
        await create(data, () => {
          message.success(I18n.t('shared.messages.success.addSuccess'));
          navigate(I18n.t('routes.panel.admin.items.paymentOrder.url'));
        });
      } else {
        await update(id, data, () => {
          message.success(I18n.t('shared.messages.success.successEdit'));
          navigate(I18n.t('routes.panel.admin.items.paymentOrder.url'));
        });
      }
    } catch (e) {
      //
    }
  }
  async privatePay(){
   const { pay, authSdk, id, getById } = this.props;
   const { paymentMethods, dataDetails } = this.state;
    try {
      const body = {
        selectPaymentMethod: paymentMethods,
    }
      const accountId = dataDetails.customer.account;
      const { token } =  await authSdk(accountId);
      await pay(body, dataDetails.id, token);
      this.closeModal();
      await getById(id !== 'add' ? id : null);
      this.setState({ dataDetails: this.props.dataDetails });
      message.success("Pagamento realizado com sucesso");
    } catch (error) {
      message.error("Falha ao realizar pagamento");
    }
  }

  getAvailableBalance(){
    return this.props.balance.filter((balance) => balance.balanceType === "AVAILABLE")[0].amount;
  }

  checkBilletBalance(){
    const { dataDetails, balance } = this.state;
    const totalAmount = parseFloat(dataDetails.totalAmount);
    return balance >= totalAmount;
  }

  render() {
    const { Content } = Layout;
    const { viewType, dataDetails, modalOpen, modalType, balance } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <>
        <ReactModal 
           isOpen={modalOpen}
           style={customStyles}
        >
          {loading > 0 ?
              <Spin
                spinning={loading > 0}
                tip={I18n.t('shared.loading')}
                >
              </Spin>
              :
              <>
                {modalType === "BOLETO" && <ModalBoleto dataDetails={dataDetails} balance={balance} />}
                {modalType === "SALDO" && <ModalSaldo dataDetails={dataDetails} balance={balance} />}
              <div style={{display: 'flex', gap: '30px', justifyContent: 'center'}}>
                <AdvancedButton style={{backgroundColor: 'gray'}} text={'Cancelar'} onClick={this.closeModal}/>
                <AdvancedButton disabled={modalType === 'SALDO' && balance && balance < parseFloat(dataDetails.totalAmount)} text={'Confirmar'} onClick={this.privatePay}/>
              </div>
              </>
          }
        </ReactModal>

        <Content id="content" className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.admin.items.paymentOrder.url')}>
                <span>{I18n.t('routes.panel.admin.items.paymentOrder.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.admin.items.paymentOrder.pageTitleAdd'
                      : 'routes.panel.admin.items.paymentOrder.pageTitleView',
                  )
                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="payment-order-details">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(
                      I18n.t('routes.panel.admin.items.paymentOrder.icon'),
                      { fill: '#F37223', width: 24, height: 24 },
                    )}
                  </span>
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.admin.items.paymentOrder.pageTitleAdd'
                        : 'routes.panel.admin.items.paymentOrder.pageTitleView',
                    )
                  }
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.admin.items.paymentOrder.pageDescriptionAdd'
                        : 'routes.panel.admin.items.paymentOrder.pageDescriptionView',
                    )
                  }
                </p>
              </div>
              <Divider />
              <div>
                <Row>
                  <Col span={24}>

                    {(dataDetails || viewType === ViewType.ADD) && (
                      <>
                      <PaymentOrderForm
                        id={id}
                        data={dataDetails}
                        submitFunction={(dataId, data) => this.submit(dataId, data)}
                        viewType={viewType}
                        loading={loading}
                        openModal={this.openModal}
                        checkBilletBalance={this.checkBilletBalance}
                      />

                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataDetails: PaymentOrderSelectors.getDataDetails(state),
  loading: LoadingSelectors.getLoading(state),
  balance: UserSelectors.getUserBalance(state),
  user: UserSelectors.getDataDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  getById: (id) => dispatch(ApiActions.getById(id, ENDPOINTS.paymentOrders, ACTION_SAVE_PAYMENT_ORDER_DETAILS)),
  getUserById: (id) => dispatch(ApiActions.getById(id, ENDPOINTS.searchUsers, ACTION_SAVE_USER_DETAILS)),
  update: (id, data, callback) => dispatch(ApiActions.put(id, data, ENDPOINTS.paymentOrders, callback)),
  create: (data, callback) => dispatch(ApiActions.post(data, ENDPOINTS.paymentOrders, callback)),
  clean: () => dispatch(ApiActions.clean(ACTION_CLEAN_PAYMENT_ORDER)),
  getBalance: (id) => dispatch(ApiActions.get(`${ENDPOINTS.searchUsers}/${id}${ENDPOINTS.userBalance}`, null, ACTION_SAVE_USER_BALANCE)),
  pay: (body, id, token) => dispatch(ApiActions.privatePay({body, token},`${ENDPOINTS.paymentOrders}/${id}${ENDPOINTS.privatePay}`)),
  authSdk: (body) => dispatch(ApiActions.authenticateSDK(body, `${ENDPOINTS.suser}${ENDPOINTS.authSDK}`)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentOrderDetails);
