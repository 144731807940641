export const PaymentTransactionStatus = {
  IN_PROGRESS: 1,
  AUTHORIZED: 2,
  PAID: 3,
  CANCELED: 4,
  BALANCE_LOCKED: 5,
  CANCEL_IN_PROGRESS: 6,
};

export const PaymentTransactionStatusMap = new Map([
  [PaymentTransactionStatus.IN_PROGRESS, 'in_progress'],
  [PaymentTransactionStatus.AUTHORIZED, 'authorized'],
  [PaymentTransactionStatus.PAID, 'paid'],
  [PaymentTransactionStatus.CANCELED, 'canceled'],
  [PaymentTransactionStatus.BALANCE_LOCKED, 'balance_locked'],
  [PaymentTransactionStatus.CANCEL_IN_PROGRESS, 'cancel_in_progress'],
]);
