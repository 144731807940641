import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import * as DateUtils from '../../../../app/utils/date';
import { statusText } from "../../../../app/utils/statusTransaction";
import getBrandImage from "../../../../app/utils/getBrand";

const SimilarTransactionsTable = ({ rows }) => {

    return (
        <TableContainer>
            <Table  aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">Data</TableCell>
                    <TableCell align="left">Valor</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Pagamento</TableCell>
                    <TableCell align="left">Identificador da ordem de pagamento</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="left">{DateUtils.humanizeDateTime(row?.createdAt, 'DD/MM/YYYY HH:mm')}</TableCell>
                    <TableCell align="left">{`${row?.totalAmount} BRL`}</TableCell>
                    <TableCell align="left">{statusText(row?.status)}</TableCell>
                    <TableCell align="left">{getBrandImage(row?.paymentOrder?.paymentCredit?.brand)}&nbsp;{`****${row?.paymentOrder?.paymentCredit?.lastFourDigits}`}</TableCell>
                    <TableCell align="left">{row?.paymentOrderId}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>

    )
}

export default SimilarTransactionsTable;