import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import AuthRequests from '../../api/auth';
import AuthService from '../../services/auth';

import { addLoading, removeLoading } from './loading';

import * as ApiActions from './api';
import * as UserActions from './user';

export const ACTION_AUTH_LOGIN = 'ACTION_AUTH_LOGIN';
export const ACTION_AUTH_LOGOUT = 'ACTION_AUTH_LOGOUT';
export const ACTION_ME = 'ACTION_ME';

export const saveAuthentication = (authData) => ({
  type: ACTION_AUTH_LOGIN,
  auth: authData,
});

export const cleanAuth = () => async (dispatch) => {
  dispatch({
    type: ACTION_AUTH_LOGOUT,
  });
  dispatch(ApiActions.clean(UserActions.ACTION_CLEAN_USER));
  AuthService.reset();
};


export const getMe = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const payload = await AuthRequests.me();
     dispatch({
      type: ACTION_ME,
      payload,
    }); 
  } catch (err) {
    if (err.status !== 401) {
      message.error(I18n.t(`shared.messages.errors.${err.message}`));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const logout = () => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanAuth());
  dispatch(removeLoading());
};
