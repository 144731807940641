import AuthApi from './auth';
import getInstance from './config';
import getFinancialInstance from './financialConfig';

export default class Api {
  static async getPaginated(params, apiUrl) {
    const instance = await getInstance();
    const { data } = await instance.get(apiUrl, { params });
    if (apiUrl === '/settings') {
      data.rows = data.rows.map(({ key, ...rest }) => ({
        ...rest, nome: key
      }))
    }
    return data;

  }

  static async uploadCreditDocuments(params, apiUrl) {
    const instance = await getFinancialInstance();
    const { data } = await instance.post(apiUrl, { params });
    return data
  }

  static async getSimulations(params, apiUrl){
    const instance = await getFinancialInstance();
    const { data } = await instance.get(apiUrl, { params });
    return data;
  }

  static async exportSimulations(params, apiUrl){
    const timeout = 30000;
    const isFile = true
    const instance = await getFinancialInstance(timeout, isFile)
    const { data } = await instance.get(apiUrl, { params });
    return data;
  }

  static async getCreditOffers(params, apiUrl) {
    const instance = await getFinancialInstance();
    const { data } = await instance.get(apiUrl, { params });
    return data;
  }

  static async getGuiaContabilPaginated(params, apiUrl) {
    const instance = await getInstance();
    const { data } = await instance.get(`${apiUrl}/event-guide`, { params });
    data.rows = data.rows.map((data) => ({
      ...data,
      primaryNSU: data.paymentCredit.primaryNSU,
      acquireNSU: data.paymentCredit.acquireNSU,
      transactionId: data.feePayer[0].transactionId,
      eventGuideId: data.feePayer[0].eventGuideId,
    }))
    return data;
  }

  static async getById(id, apiUrl, params) {
    const instance = await getInstance();
    const { data } = await instance.get(`${apiUrl}/${id}`, { params });
    return data;
  }

  static async get(apiUrl, params) {
    const instance = await getInstance();
    const { data } = await instance.get(apiUrl, { params });
    return data;
  }

  static async put(id, newData, apiUrl) {
    const instance = await getInstance();
    const { data } = await instance.put(`${apiUrl}/${id}`, newData);
    return data;
  }

  static async post(newData, apiUrl) {
    const instance = await getInstance();
    const { data } = await instance.post(apiUrl, newData);
    return data;
  }

  static async resendCommand(id, apiUrl, params = {}) {
    const instance = await getInstance();
    const data = await instance.post(`${apiUrl}/${id}/send-callback`, { params });
    if (data) return true;
    return false
  }

  static async resendReCommand(id, apiUrl, params = {}) {
    const instance = await getInstance();
    const data = await instance.post(`${apiUrl}/${id}/insert-queue-command`, { params });
    if (data) return true;
    return false
  }

  static async alterCommand(id, apiUrl) {
    const instance = await getInstance();
    const { data } = await instance.put(`${apiUrl}/${id}/alter-command`);
    return data
  }

  static async resendAllCommand(apiUrl, params = {}) {
    const instance = await getInstance();
    let data
    if (params.createdAt && params.endAt) {
      data = await instance.post(`${apiUrl}/insert-command-pending?createdAt=${params.createdAt}&endAt=${params.endAt}`)
    }
    else {
      data = await instance.post(`${apiUrl}/insert-command-pending`)
    }
    if (data) return true
    return false
  }

  static async sendWebhook(id, apiUrl, params = {}) {
    const instance = await getInstance();
    const { data } = await instance.post(`${apiUrl}/${id}/send-hook`, { params });
    if (data) return true;
    return false
  }

  static async remove(id, apiUrl, params = {}) {
    const instance = await getInstance();
    const { data } = await instance.delete(`${apiUrl}/${id}`, { params });
    return data;
  }

  static async mine(apiUrl, params) {
    const instance = await getInstance();
    const { data } = await instance.get(`${apiUrl}/mine`, { params });
    return data;
  }

  static async sendConciliationFile(apiUrl, file) {
    const instance = await getInstance();
    const { data } = await instance.put(apiUrl, file);
    return data;
  }

  static async getWorkFlow(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/payment-order/${id}/workflow`);
    return data;
  }

  static async searchUsers(params) {
    const instance = await getInstance();

    const { data } = await instance.get('/user/', { params });
    return data;
  }

  static async customDelete(apiUrl, params) {
    const instance = await getInstance();
    const { data } = await instance.delete(apiUrl, { params });
    return data;
  }

  static async accountClosure(apiUrl, params) {
    const instance = await getInstance();
    const { data } = await instance.delete(apiUrl, { params });
    return data;
  }


  static async customGet(apiUrl, params, noTimeout) {
    const instance = await getInstance(noTimeout);
    const { data } = await instance.get(apiUrl, { params });
    return data;
  }

  static async reprocessConciliationFile(id, apiUrl, params) {
    const instance = await getInstance();
    const { data } = await instance.post(`${apiUrl}/${id}/reprocess-conciliation-file`, { params });
    return data;
  }

  static async resendPendinConciliationFiles(apiUrl, params) {
    const instance = await getInstance();
    const { data } = await instance.post(apiUrl, { params });
    return data;
  }

  static async accountMigrate(apiUrl, params) {
    const instance = await getInstance();
    const { data } = await instance.post(apiUrl, { params });
    return data;
  }

  static async privatePay(apiUrl, params) {
    const instance = await AuthApi.payInstance(params.token);
    const { data } = await instance.post(apiUrl, params.body );
    return data;
  }
}