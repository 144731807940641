import React from 'react'

const Table = ({ data, columns }) => {

    return (
        <table style={{textAlign: 'center'}}  className="table">
            <thead>
                <tr>
                    {columns.map((col) => (
                        <th key={col.key}>{col.title}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        {columns.map((col) => (
                            
                            <td style={row.data.conciliationStatus === "OK" ?  {backgroundColor: "#d6ffe1"} : {backgroundColor: "#f79e9e"}} key={col.key}>
                                {col.key === "message" ? row.message : row.data[col.key] || '--'}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
  };


  const ErrorSuccessTables = ({ data, filter }) => {
    const columnsCard = [
        { key: "conciliationStatus", title: "Status" },
        { key: "paymentOrderId", title: "Id da ordem de pagamento" },
        { key: "primaryNSU", title: "NSU" },
        { key: "installment", title: "Parcela" },
        { key: "message", title: "Mensagem" },
        { key: "depositId", title: "Id do depósito" },
        { key: "titleGenerateId", title: "Id de Geração" },
        { key: "titleInstallmentId", title: "Id da Parcela" },
        { key: "titleGroupId", title: "Id do Grupo" },
        { key: "mdrSell", title: "MDR de Venda" },
        { key: "amountSell", title: "Valor da Venda" },
        { key: "grossAmountSell", title: "Valor Bruto da Venda" }
    ];

    const columnsPix = [
        { key: "conciliationStatus", title: "Status" },
        { key: "paymentOrderId", title: "Id da ordem de pagamento" },
        { key: "txid", title: "TXID" },
        { key: "depositId", title: "Id do depósito" },
        { key: "titleGenerateId", title: "Id de Geração" },
        { key: "titleInstallmentId", title: "Id da Parcela" },
        { key: "titleGroupId", title: "Id do Grupo" },
        { key: "amount", title: "Valor" },
        { key: "message", title: "Mensagem" }
    ];
  
    return (
      <div style={{marginTop: '20px'}}>
        <Table data={data} columns={filter === "CARTAO_CREDITO" ? columnsCard : columnsPix} />
      </div>
    );
  };


export default ErrorSuccessTables;

