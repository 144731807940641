import React from 'react';
import { Layout, Button, Row, Col, Collapse, Tooltip, message } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { PlusCircleOutlined, RetweetOutlined, SearchOutlined } from '@ant-design/icons';
import DataTable from '../../../../components/shared/DataTable';
import { ApiActions } from '../../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, QrCodeSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import AdvancedRangePicker from '../../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';
import * as DateUtils from '../../../../app/utils/date';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../../components/shared/AdvancedSelect/AdvancedSelect';
import { ACTION_SAVE_QR_CODES_PAGINATED } from '../../../../app/redux/actions/qrCode';
import { getPixConciliationStatusOptions, getQrCodeStatusOptions } from '../../../../app/utils/selectOptions';
import { QRCodeStatusMap } from '../../../../app/enum/qrCodeStatus';
import { ConciliationPixStatusMap } from '../../../../app/enum/pixConciliationStatus';

class QrCodeList extends React.Component {
  constructor(props) {
    super(props);
    this.reprocessConciliation = this.reprocessConciliation.bind(this);
    this.state = {
      params: {
        createdAt: null,
        createdAtRange: null,
        status: null,
        txid: null,
        transactionId: null,
        paidAt: null,
        paidAtRange: null,
        expirationDate: null,
        expirationDateRange: null,
        paymentOrderId: null,
        name: null,
        userTaxIdentifier: null,
        conciliationStatus: null
      },
      individualTransaction: null
    };
    this.dataTableRef = React.createRef();
  }

  validityChange(key, keyRange, dates) {
    const { params } = this.state;

    const value = dates[0].format('YYYY-MM-DD').toString().concat(',',
      dates[1].format('YYYY-MM-DD').toString()
    );

    this.setState({
      params: {
        ...params,
        [key]: value,
        [keyRange]: dates,
      },
    });
  }

  getPaginated(params) {
    params = {
      ...params
    };
    const { getPaginated } = this.props;
    this.setState({ params: { ...params } });
    getPaginated(params);
  }

  async reprocessConciliation(id){
    const { reconciliate } = this.props;
      reconciliate(id).then(() => {
        message.success('Reprocessamento de conciliação iniciado com sucesso');
      }).catch(() => {
        message.error('Erro ao iniciar reprocessamento de conciliação');
      });
  }
  



  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value
    this.setState({ params });
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const {  loading, dataPaginated } = this.props;
    const { params } = this.state;
    return (
      <>
      {/* filtro */}
        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse className="advanced-filter">
            <Panel header={I18n.t('shared.advancedFilters.header')} key="0">
              <Row gutter={16}>
                <Col span={8}>
                <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.txid.title')}
                    value={params && params.txid}
                    onChange={(val) => this.fieldChange('txid', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput  label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.transactionId.title')} 
                  value={params && params.transactionId}
                  onChange={(val) => this.fieldChange('transactionId', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedSelect
                  label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.status.title')} 
                  value={params && params.status}
                  onChange={(val) => this.fieldChange('status', val)}
                  defaultValue={params && params.status}
                  options={getQrCodeStatusOptions()}
                  />
                </Col>
                <Col span={8}>
                <AdvancedRangePicker
                    label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.paidAt.title')}
                    value={params && params.paidAtRange}
                    onChange={(val) => this.validityChange('paidAt', 'paidAtRange', val)}
                  />
                </Col>
                <Col span={8}>
                <AdvancedRangePicker
                    label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.expirationDate.title')}
                    value={params && params.expirationDateRange}
                    onChange={(val) => this.validityChange('expirationDate', 'expirationDateRange', val)}
                  />
                </Col>
                <Col span={8}>
                <AdvancedRangePicker
                    label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.createdAt.title')}
                    value={params && params.createdAtRange}
                    onChange={(val) => this.validityChange('createdAt', 'createdAtRange', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput  label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.paymentOrderId.title')} 
                  value={params && params.paymentOrderId}
                  onChange={(val) => this.fieldChange('paymentOrderId', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput  label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.name.title')} 
                  value={params && params.name}
                  onChange={(val) => this.fieldChange('name', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput  label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.userTaxIdentifier.title')} 
                  value={params && params.userTaxIdentifier}
                  onChange={(val) => this.fieldChange('userTaxIdentifier', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedSelect  
                  label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.conciliationStatus.title')} 
                  value={params && params.conciliationStatus}
                  onChange={(val) => this.fieldChange('conciliationStatus', val)}
                  defaultValue={params && params.conciliationStatus}
                  options={getPixConciliationStatusOptions()}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24} className="advanced-filter__search-button text-right">
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilters.clearButtonText')}
                    onClick={() => this.getPaginated()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilters.filterButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.getPaginated({ ...params, offset: 0 })}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginated(parameters)}
                data={dataPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                params={params}
                showSizeChanger
                pageSizeOptions={
                  dataPaginated && dataPaginated.count && dataPaginated.count > 100
                    ? ['10', '50', '100', dataPaginated.count.toString()]
                    : ['10', '20', '50', '100']
                }
                columns={[
                    {
                        key: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.value.key'),
                        title: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.value.title'),
                        render: (value) => `${value} BRL` || '--',
                    },
                  {
                    key: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.paidAt.key'),
                    title: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.paidAt.title'),
                    render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm')  || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.status.key'),
                    title: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.status.title'),
                    render: (value) => QRCodeStatusMap.get(value) || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.paymentOrderId.key'),
                    title: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.paymentOrderId.title'),
                    render: (transaction) => transaction?.paymentOrderId || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.transactionId.key'),
                    title: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.transactionId.title'),
                    render: (transaction) => transaction?.id || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.conciliationStatus.key'),
                    title: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.conciliationStatus.title'),
                    render: (transaction) => ConciliationPixStatusMap.get(transaction?.pixConciliationControl[0]?.status) || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.userTaxIdentifier.key'),
                    title: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.userTaxIdentifier.title'),
                    render: (transaction) => transaction?.paymentOrder?.customer?.taxIdentifier || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.receiverId.key'),
                    title: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.receiverId.title'),
                    render: (transaction) => transaction?.pixConciliationControl[0]?.receiverId || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.depositId.key'),
                    title: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.depositId.title'),
                    render: (transaction) => transaction?.pixConciliationControl[0]?.depositId || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.receiptDate.key'),
                    title: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.receiptDate.title'),
                    render: (transaction) => DateUtils.humanizeDateTime(transaction?.pixConciliationControl[0]?.receiptDate, 'DD/MM/YYYY HH:mm') || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.titleGenerateId.key'),
                    title: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.titleGenerateId.title'),
                    render: (transaction) => transaction?.pixConciliationControl[0]?.titleGenerateId || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.id.key'),
                    title: '',
                    render: (id) =>   
                  <div className="dataTable__item--right" style={{display: 'flex'}}>  
                    <Tooltip
                      title={I18n.t('shared.details')}
                      overlayStyle={{ fontSize: 11 }}
                    >
                      <Button
                        type="link"
                        icon={<PlusCircleOutlined />}
                        onClick={() => window.open(`${I18n.t('routes.panel.admin.items.qrCode.url')}/${id}`, "_blank", "noreferrer")}
                      />
                    </Tooltip>
                        <Tooltip
                            title={I18n.t('shared.conciliation.qrCode')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<RetweetOutlined />}
                              disabled={loading > 0}
                              onClick={() => this.reprocessConciliation(id)}
                            />
                      </Tooltip>
                    </div>   
                  },
                ]}
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: QrCodeSelectors.getDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(ApiActions.getPaginated(parameters, ENDPOINTS.pix, ACTION_SAVE_QR_CODES_PAGINATED)),
  reconciliate: (id) => dispatch(ApiActions.post(null, `${ENDPOINTS.pix}/${id}/requeue-conciliation`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QrCodeList);
