import Immutable from 'seamless-immutable';
import { FinancialActions } from '../actions';

const initialState = Immutable({
  dataPaginated: null,
  dataDetails: null,
});

export default function creditOffers(
  state = initialState,
  action,
) {
  switch (action.type) {
    case FinancialActions.ACTION_SAVE_CREDIT_OFFERS_PAGINATED:
      state = {
        ...state,
        dataPaginated: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getDataPaginated(state) {
  return state.creditOffer.dataPaginated;
}