export const ENDPOINTS = {
  admins: '/admin',
  paymentOrders: '/payment-order',
  commands: '/command-processor',
  workflows: '/workflow',
  searchUsers: '/user',
  suser: '/suser',
  settings: '/settings',
  dashboard: '/dashboard',
  utils: '/utils',
  feePayer: '/fee-payer',
  dataMiner:'/data-miner',
  creditOffer: '/credit-offer',
  uploadCreditDocuments: '/credit-offer/upload-documents',
  simulations: '/loan',
  simulationsCSV: '/loan/export',
  transactions: '/payment-order/transaction/get',
  paymentTry: '/utils/payment-try',
  creditCard: '/payment-order/credit-card/get',
  installments: '/payment-order/transaction-installment/get',
  accountType: '/user/account-permission',
  reprocessConciliation: '/matera-conciliation-payments/reprocess',
  paymentOrderTransaction: '/payment-order-transaction',
  historyCommand: '/history-command-to-process',
  userBalance: '/balance',
  privatePay: '/pay',
  authSDK: '/sdk',
  pix: '/pix',
};
