import React from 'react';
import { PaymentOrderMethods } from '../../../../../app/enum/paymentOrderMethod';
import * as DateUtils from '../../../../../app/utils/date';
 
const getDataflowBoleto = (transactions, currentId, origin, index) => {
 
  const nodes = [
    {
      id: `e${origin}-${currentId + 1}`, source: `${origin}`, target: `${currentId + 1}`, label: '',
    },
    {
      id: `${currentId + 1}`,
      position: { x: 250 + (300 * index), y: (origin + 1) * 100 },
      data: {
        label:
        (<>
          <div>{transactions.type} pago</div>
          <div>{DateUtils.humanizeDateTime(transactions.paidAt, 'DD/MM/YYYY HH:mm')}</div>
        </>),
      },
    },
    {
      id: `e${currentId + 1}-${currentId + 2}`, source: `${currentId + 1}`, target: `${currentId + 2}`, label: '',
    },
    {
      id: `${currentId + 2}`,
      position: { x: 250 + (300 * index), y: (origin + 2) * 100 },
      data: {
        label: (<>
          <div>Bloqueado em</div>
          <div>{DateUtils.humanizeDateTime(transactions.lockAt, 'DD/MM/YYYY HH:mm')}</div>
        </>),
      },
    },
  ];
 
  return { nodes, lastId: currentId + 2 };
};
 
const getDataflowCartao = (transactions, currentId, origin, index) => {
  const nodes = [
    {
      id: `e${origin}-${currentId + 1}`, source: `${origin}`, target: `${currentId + 1}`, label: '',
    },
    {
      id: `${currentId + 1}`,
      position: { x: 75 + (300 * index), y: (origin + 1) * 75 },
      data: {
        label: (<>
          <div>{transactions.type} Pago</div>
          <div>{DateUtils.humanizeDateTime(transactions.paidAt, 'DD/MM/YYYY HH:mm')}</div>
        </>),
      },
    },
    {
      id: `e${currentId + 1}-${currentId + 2}`, source: `${currentId + 1}`, target: `${currentId + 2}`, label: '',
    },
    {
      id: `${currentId + 2}`,
      position: { x: 75 + (300 * index), y: (origin + 2) * 100 },
      data: {
        label: (<>
          <div>Bloqueado em</div>
          <div>{DateUtils.humanizeDateTime(transactions.lockAt, 'DD/MM/YYYY HH:mm')}</div>
        </>),
      },
    },
  ];
  return { nodes, lastId: currentId + 2};
};
 
const getDataflowSaldo = (transactions, currentId, origin, index) => {
  const nodes = [
    {
      id: `e${origin}-${currentId + 1}`, source: `${origin}`, target: `${currentId + 1}`, label: '',
    },
    {
      id: `${currentId + 1}`,
      position: { x: 250 + (300 * index), y: (origin + 1) * 75 },
      data: {
        label: (<>
          <div>{transactions.type} Pago</div>
          <div>{DateUtils.humanizeDateTime(transactions.paidAt, 'DD/MM/YYYY HH:mm')}</div>
        </>),
      },
    },
    {
      id: `e${currentId + 1}-${currentId + 2}`, source: `${currentId + 1}`, target: `${currentId + 2}`, label: '',
    },
    {
      id: `${currentId + 2}`,
      position: { x: 250 + (300 * index), y: (origin + 2) * 100 },
      data: {
        label: (<>
          <div>Bloqueado em</div>
          <div>{DateUtils.humanizeDateTime(transactions.lockAt, 'DD/MM/YYYY HH:mm')}</div>
        </>),
      },
    },
  ];
  return { nodes, lastId: currentId + 2 };
};
 
const getDataflowCommand = (command, currentId, origin, index) => {
  const nodes = [
    {
      id: `e${origin}-${currentId + 1}`, source: `${origin}`, target: `${currentId + 1}`, label: '',
    },
    {
      id: `${currentId + 1}`,
      position: { x: 250 + (300 * index), y: (origin + 1) * 100 },
      data: {
        label: (<>
          <div>Saldo desbloqueado em</div>
          <div>{DateUtils.humanizeDateTime(command.unlockAt, 'DD/MM/YYYY HH:mm')}</div>
        </>),
      },
    },
    {
      id: `e${currentId + 1}-${currentId + 2}`, source: `${currentId + 1}`, target: `${currentId + 2}`, label: '',
    },
    {
      id: `${currentId + 2}`,
      position: { x: 250 + (300 * index), y: (origin + 2) * 100 },
      data: {
        label: (<>
          <div>P2P</div>
          <div>{DateUtils.humanizeDateTime(command.transferAt, 'DD/MM/YYYY HH:mm')}</div>
        </>),
      },
    },
    {
      id: `e${currentId + 2}-${currentId + 3}`, source: `${currentId + 2}`, target: `${currentId + 3}`, label: '',
    },
    {
      id: `${currentId + 3}`,
      position: { x: 250 + (300 * index), y: (origin + 3) * 100 },
      data: {
        label: (<>
          <div>Bloqueado no destino</div>
          <div>{DateUtils.humanizeDateTime(command.lockAt, 'DD/MM/YYYY HH:mm')}</div>
        </>),
      },
    },
  ];
  return {
    nodes, lastId: currentId + 3,
  };
};

const getGeneralBlocked = (transactions, currentId, origins, index) => {
const nodes = origins.map( originId => ({
  id: `e${originId}-${currentId + 1}`, source: `${originId}`, target: `${currentId + 1}`, 
  label: '',
}));

nodes.push({
      id: `${currentId + 1}`,
      position: { x: 250 + (300 * index), y:  500},
      data: {
        label: (<>
          <div>Bloqueado geral em</div>
          <div>{DateUtils.humanizeDateTime(transactions.lockAt, 'DD/MM/YYYY HH:mm')}</div>
        </>),
      },
    });

  return { nodes, lastId: currentId + 1 };
};
 
export const getDataflow = (dataWorkFlow) => {
  if (!dataWorkFlow) { return []; }
 
  let returnArray = [
    {
      id: '1',
      type: 'input',
      data: {
        label: (
          <>
            <strong>Ordem de pagamento</strong>
            <div>{DateUtils.humanizeDateTime(dataWorkFlow.createdAt, 'DD/MM/YYYY HH:mm')}</div>
          </>
        ),
      },
      position: { x: 250, y: 0 },
    },
    {
      id: 'e1-2', source: '1', target: '2', label: '',
    },
    {
      id: '2',
      data: {
        label: (
          <>
            <div>Forma de pagamento selecionada</div>
            <div>{dataWorkFlow.selectPaymentMethod} - {dataWorkFlow.transactions.length > 0 ? DateUtils.humanizeDateTime(dataWorkFlow.transactions[0].createdAt, 'DD/MM/YYYY') : ''}</div>
          </>
        ),
      },
      position: { x: 250, y: 100 },
    },
  ];
 
  let currentId = 2;
 
  const originTransaction = currentId;
 
  const blockOrigins = [];

  const billetTransactions = dataWorkFlow.transactions.filter((o) => o.type === PaymentOrderMethods.BOLETO);
  for (let i = 0; i < billetTransactions.length; i++) {
    const { nodes, lastId } = getDataflowBoleto(billetTransactions[i], currentId, originTransaction, i);
    returnArray = returnArray.concat(nodes);
    blockOrigins.push(returnArray[returnArray.length -1 ].id);
    currentId = lastId;
  }
 
  const balanceTransactions = dataWorkFlow.transactions.filter((o) => o.type === PaymentOrderMethods.SALDO);
  for (let i = 0; i < balanceTransactions.length; i++) {
    const { nodes, lastId } = getDataflowSaldo(balanceTransactions[i], currentId, originTransaction, i);
    returnArray = returnArray.concat(nodes);
    blockOrigins.push(returnArray[returnArray.length -1 ].id);
    currentId = lastId;
  }
 
  const cardTransactions = dataWorkFlow.transactions.filter((o) => o.type === PaymentOrderMethods.CARTAO_CREDITO);


  for (let i = 0; i < cardTransactions.length; i++) {
    const { nodes, lastId} = getDataflowCartao(cardTransactions[i], currentId, originTransaction, i);
    returnArray = returnArray.concat(nodes);
    blockOrigins.push(returnArray[returnArray.length -1 ].id);
    currentId = lastId;
  }
 

  if (dataWorkFlow.transactions && dataWorkFlow.transactions.length > 0) {
      const { nodes, lastId } = getGeneralBlocked(dataWorkFlow.transactions[0], currentId, blockOrigins, 0);
      returnArray = returnArray.concat(nodes);
      currentId = lastId;
  }
 
  const originCommand = currentId;
  if (dataWorkFlow.commands) {
    for (let i = 0; i < dataWorkFlow.commands.length; i++) {
      const { nodes, lastId } = getDataflowCommand(dataWorkFlow.commands[i], currentId, originCommand, i);
      returnArray = returnArray.concat(nodes);
      currentId = lastId;
    }
  }
  return returnArray;
};