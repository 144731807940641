import { Checkbox, Spin } from 'antd';
import React from 'react';

import Dropzone from './dropzone';
import {ReactComponent as PdfIcon} from '../../../app/assets/img/pdf_icon.svg';
import {ReactComponent as DeleteIcon} from '../../../app/assets/img/delete.svg';


class DocumentModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleFinish = this.handleFinish.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.onDelete = this.handleComplete.bind(this);
    this.state = {
        files: [],
        documentTypes: [],
        loading: false
    } 
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async handleFinish() {
    this.setState({loading: true})
   const { uploadDocuments, loan } = this.props;
    const files = [];
    if(this.state.files.length !== 0){
      await Promise.all(
        this.state.files.map(async (file) => {
          let base64 = await this.getBase64(file)
          let fileBody = {
            name: file.name, 
            type: file.type, 
            buffer: base64
          }
        files.push(fileBody);
      })
    )
    let body = {
        loanId: loan.id,
        documentType: 'PDF',
        file: files
    }
      try {
        uploadDocuments(body);
        
      } catch (error) {
        console.log(error)
        this.setState({loading: false})
      }
    }
    
    this.setState({loading: false})
    return;
  }




  handleComplete(selectedFile) {
    let existFile = this.state.files.indexOf(selectedFile);
    if(existFile === -1){
      this.setState({ files: [...this.state.files, selectedFile[0] ]});
    }
    else {
      return;
    }
  }

  onFileDelete (file) {
    let newArray = this.state.files.filter((el) => { return el.name !== file.name })
    this.setState( {files: newArray} )
  }

  onChange (e) {
    if(!this.state.documentTypes.includes(e.target.value)){
        this.setState( { documentTypes: [...this.state.documentTypes, e.target.value] })
    }
    else {
      let newArray = this.state.documentTypes.filter((el) => { return el !== e.target.value })
      this.setState({ documentTypes: newArray})
    }
  }

   sizeInMb (sizeInBytes) {
     return (sizeInBytes / (1024 * 1024)).toFixed(2) 
    }

  render() {

    const { files, documentTypes, loading } = this.state;
    const { onClose } = this.props
    return (
      <>
       
        <div className='financial__modalContainer'>
            {loading ? <div className='financial__loadingContainer'><Spin size='large'/></div> :
            <>
            <span className='financial__modalTitle'>Enviar documento</span>
            <div className='financial__lineDivider'></div>
            <span className='financial__modalsubTitle'>Tipo de documento</span>
            <div style={{marginBottom: '8px'}}>
                <Checkbox value='Nota Fiscal' 
                  defaultChecked={documentTypes.includes('Nota fiscal') ? true : false} 
                  onChange={(e) => this.onChange(e)} 
                >
                    Nota Fiscal
                </Checkbox>
                <Checkbox 
                  defaultChecked={documentTypes.includes('DUT') ? true : false} 
                  value='DUT' onChange={(e) => this.onChange(e)}
                >
                    DUT (documento único de tranferência)
                </Checkbox>
                <Checkbox 
                  defaultChecked={documentTypes.includes('Outro') ? true : false} 
                  value='Outro' onChange={(e) => this.onChange(e)} 
                >
                    Outro
                </Checkbox>
            </div>
            {files.length !== 0 && files.map((file, index) => {
                  return (
                    
                    <div className='financial__files' key={index}>
                      <PdfIcon />
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span className="financial__text">{file.name}</span>
                        <span className="financial__subTitle">{this.sizeInMb(file.size)}MB</span>
                      </div>
                      <button 
                      className="financial__deleteFile"
                      onClick={() => this.onFileDelete(file)}
                      >
                        <DeleteIcon/>
                        Excluir
                      </button>
                    </div>
                  )
              })}
            <Dropzone
                mt={1}
                width="100%"
                onDropComplete={this.handleComplete}
                options={{
                  multiple: false,
                  accept: {
                    "application/pdf": []
                  }
                }}
                files={files}
              />
            <div style={{display: 'flex',flexDirection: 'column', marginTop: '8px'}}>
                <span className='financial__text'>Formatos de arquivos suportados: pdf</span>
                <span className='financial__text'>Tamanho máximo: 10MB</span>
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '12px'}}>
                <button key="back" className='financial__cancelButton' onClick={onClose}>
                  Cancelar
                </button>
                <button onClick={this.handleFinish} key="submit" className='financial__documentButton' >
                  Enviar documentos
                </button>
            </div> 
            </>}
            
        </div>
      </>
    );
  }
}


export default DocumentModal;