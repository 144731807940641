import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { addLoading, removeLoading } from './loading';
import Api from '../../api/api';
import { PaymentOrderActions } from '.';
import AuthApi from '../../api/auth';

export const clean = (actionType) => ({ type: actionType });

export const getPaginated = (parameters, apiUrl, actionType) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const data = await Api.getPaginated(parameters, apiUrl);
    dispatch({
      type: actionType,
      payload: data,
    });

    return data;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const getFinancialPaginated = (parameters, apiUrl, actionType) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    status: (parameters && parameters.status) || '', 
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0
  };

  try {
    const data = await Api.getCreditOffers(parameters, apiUrl);
    dispatch({
      type: actionType,
      payload: data,
    });

    return data;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const getSimulationsCSV = (parameters, apiUrl,callback, actionType) => async(dispatch) => {
  dispatch(addLoading());
  try {
    const data = await Api.exportSimulations(parameters, apiUrl);

    dispatch({
      type: actionType,
      payload: data,
    });

    if (callback && typeof callback === 'function') {
      callback(data);
    }

    return data;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }

}

export const getSimulations = (parameters, apiUrl, actionType) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    status: (parameters && parameters.status) || '', 
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0
  };

  try {
    const data = await Api.getSimulations(parameters, apiUrl);
    dispatch({
      type: actionType,
      payload: data,
    });

    return data;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};


export const uploadCreditDocuments = (parametres, apiUrl, actionType) => async (dispatch) => {
  dispatch(addLoading())
  try {
    const data = await Api.uploadCreditDocuments(parametres, apiUrl);
    dispatch({
      type: actionType,
      payload: data,
    });

    return data;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
}

export const getTransactionById = (id, apiUrl, actionType) => 
  async (dispatch) => {
    dispatch(addLoading());
    try {
      let data = null;
      if (id) {
        let params = { 'transactionId': id }
        data = await Api.get(apiUrl, params)
      }
      dispatch({
        type: actionType,
        payload: data,
      });
      return data;
    } catch (err) {
      if (err.status !== 401) {
        if (err.message) {
          message.error(I18n.t(`shared.messages.errors.${err.message}`));
        } else {
          message.error(I18n.t('shared.messages.errors.generic'));
        }
      }
    } finally {
      dispatch(removeLoading());
    }
  };


export const getById =
  (id, apiUrl, actionType, params = null) =>
    async (dispatch) => {
      dispatch(addLoading());
      try {
        let data = null;
        if (id) {
          data = await Api.getById(id, apiUrl, params);
        }
        dispatch({
          type: actionType,
          payload: data,
        });
        return data;
      } catch (err) {
        if (err.status !== 401) {
          if (err.message) {
            message.error(I18n.t(`shared.messages.errors.${err.message}`));
          } else {
            message.error(I18n.t('shared.messages.errors.generic'));
          }
        }
      } finally {
        dispatch(removeLoading());
      }
    };

export const put = (id, data, apiUrl, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await Api.put(id, data, apiUrl);
    if (callback && typeof callback === 'function') {
      callback();
    }
    return response;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const sendFileConciliation = (file, apiUrl, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await Api.sendConciliationFile(apiUrl, file);
    if (callback && typeof callback === 'function') {
      callback();
    }
    return response;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.generic`));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
}

export const getGuiaContabilPaginated = (parameters, apiUrl, actionType) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const data = await Api.getGuiaContabilPaginated(parameters, apiUrl);
    dispatch({
      type: actionType,
      payload: data,
    });

    return data;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const post = (data, apiUrl, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await Api.post(data, apiUrl);
    if (callback && typeof callback === 'function') {
      callback();
    }
    return response;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const remove = (id, apiUrl, callback, params) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await Api.remove(id, apiUrl, params);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const customGet = (apiUrl, actionType, params, callback, noTimeout) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await Api.customGet(apiUrl, params, noTimeout);

    dispatch({
      type: actionType,
      payload: data,
    });

    if (callback && typeof callback === 'function') {
      callback(data);
    }

    return data;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const customDelete = (apiUrl, callback, params) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await Api.customDelete(apiUrl, params);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const accountClosure = (apiUrl, callback, params) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await Api.accountClosure(apiUrl, params);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      }
    }
    throw new Error();
  } finally {
    dispatch(removeLoading());
  }
};

export const reprocessConciliationFile = (id, apiUrl, callback, params) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await Api.reprocessConciliationFile(id, apiUrl, params);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const resendPendinConciliationFiles = (apiUrl, callback, params) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await Api.resendPendinConciliationFiles(apiUrl, params);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const resendCommand = (id, apiUrl, callback, params) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await Api.resendCommand(id, apiUrl, params);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};
export const resendReCommand = (id, apiUrl, callback, params) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await Api.resendReCommand(id, apiUrl, params);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const resendAllCommand = (params, apiUrl, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const res = await Api.resendAllCommand(apiUrl, params);
    if (callback && typeof callback === 'function') {
      callback(res);
    }
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const alterCommand = (id, apiUrl, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const command = await Api.alterCommand(id, apiUrl);
    if (callback && typeof callback === 'function') {
      callback();
    }
    return command;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const sendWebhook = (id, apiUrl, callback, params) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await Api.sendWebhook(id, apiUrl, params);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const mine = (apiUrl, params, actionType) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await Api.mine(apiUrl, params);
    dispatch({
      type: actionType,
      payload: data,
    });
    return data;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const getChart = (apiUrl, params, actionType) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const dataMap = await Api.get(`${apiUrl}/${params.chartSubject}`, params);

    const chartData = {
      data: [],
      metadata: [dataMap].slice(-1),
    };

    if (Array.isArray(dataMap)) {
      if (Array.isArray(dataMap[0])) {
        if (dataMap[0][0].includes('_mode')) {
          Object.entries(dataMap[0][1].map).map((value, index) => {
            chartData.data.push({
              x: index,
              y: Number(value[1]).toFixed(),
              label: value[0],
              value: Number(value[1]).toFixed(),
            });
          });
        }

        if (dataMap[0][0].includes('_count')) {
          chartData.data.push(dataMap[0][1]);
        }
      }
    }

    dispatch({
      type: actionType,
      payload: chartData.data,
    });

    return chartData.data;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};
export const get = (apiUrl, params, actionType) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await Api.get(apiUrl, params);
    dispatch({
      type: actionType,
      payload: data,
    });
    return data;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const getWorkFlow = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await Api.getWorkFlow(id);
    dispatch({
      type: PaymentOrderActions.ACTION_SAVE_PAYMENT_ORDER_WORK_FLOW,
      payload: data,
    });
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.${err.message}`));
      } else {
        message.error(I18n.t('shared.messages.errors.generic'));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const accountMigrate = (apiUrl, callback, params) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await Api.accountMigrate(apiUrl, params);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.generic`));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const authenticateSDK = (accountId, apiUrl) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let body = {
      accountId
    }
    const data = await Api.post(body, apiUrl);
    return data;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.generic`));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const privatePay = (params,apiUrl) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await Api.privatePay(apiUrl, params);
    return data;
  } catch (err) {
    if (err.status !== 401) {
      if (err.message) {
        message.error(I18n.t(`shared.messages.errors.generic`));
      }
    }
  } finally {
    dispatch(removeLoading());
  }
}
