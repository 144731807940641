import Keycloak from 'keycloak-js';
import config from './app.config';

const keycloak = Keycloak({
  url: `${config.identityServer.baseUrl}/auth`,
  realm: config.identityServer.realm,
  clientId: config.identityServer.clientId,
});

export default keycloak;
