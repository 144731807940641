import Immutable from 'seamless-immutable';
import { UserActions } from '../actions';

const initialState = Immutable({
  dataPaginated: null,
  dataDetails: null,
  dataType: null,
  dataBalance: null,
});

export default function user(
  state = initialState,
  action,
) {
  switch (action.type) {
    case UserActions.ACTION_SAVE_USERS_PAGINATED:
      state = {
        ...state,
        dataPaginated: action.payload,
      };
      return state;
    case UserActions.ACTION_SAVE_USER_DETAILS:
      state = {
        ...state,
        dataDetails: action.payload,
      };
      return state;
    case UserActions.ACTION_CLEAN_USER:
      state = {
        ...initialState,
      };
      return state;
    case UserActions.ACTION_SAVE_USER_TYPE:
      state = {
        ...state,
        dataType: action.payload
      };
    case UserActions.ACTION_SAVE_USER_BALANCE:
      state = {
        ...state,
        dataBalance: action.payload
      };
    default:
      return state;
  }
}

export function getDataPaginated(state) {
  return state.user.dataPaginated;
}

export function getDataDetails(state) {
  return state.user.dataDetails;
}

export function getUserType(state){
  return state.user.dataType
}

export function getUserBalance(state){ 
  return state.user.dataBalance
}
