import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Modal } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';

class CommandChangeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        id: null,
        externalId: null,
        sourceOp: null,
        operation: null,
        status: null,
        commandProcessed: null,
        transferAt: null,
        unlockAt: null,
        commandProcessedAt: null,
      },
    };
  }

  componentDidMount() {
    const { data, id } = this.props;
    if (data && id !== 'add') {
      this.setState({
        form: {
          id: data && data.id,
          externalId: data && data.externalId,
          sourceOp: data && data.sourceOp,
          operation: data && data.operation,
          status: data && data.status,
          commandProcessed: data && data.commandProcessed,
          transferAt: data && data.transferAt,
          unlockAt: data && data.unlockAt,
          commandProcessedAt: data && data.commandProcessedAt,
        },
      });
    }
  }

  confirmOnSubmit(e) {
    e.preventDefault();

    Modal.confirm({
      title: I18n.t('routes.panel.admin.items.commandChange.details.form.warning.confirmation'),
      content: (
        <>

        </>
      ),
      onOk: () => this.onSubmit(),
    });
  }

  onSubmit() {
    const { data, submitFunction } = this.props;

    try {
      submitFunction(data.id);
    } catch (error) {
      //
    }
  }

  render() {
    const { loading, id } = this.props;

    return (
      <Row>
        {loading === 0 && (
          <Col span={24}>
            <form
              name="commandChangeForm"
              onSubmit={(ev) => this.confirmOnSubmit(ev)}
            >
              <div className="panel__layout__content__details__form">
                <ul>
                    {
                      I18n.t(
                        'routes.panel.admin.items.commandChange.details.form.warning.confirmation'
                      )
                    }
                </ul>
              </div>

              <Divider/ >

              <Row gutter={16}>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.cancel')}
                    href={`${I18n.t('routes.panel.admin.items.search.url')}/${id}`}
                  />
                  <Divider className="form-button-divider" type="vertical" />
                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.confirm')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

export default connect(
  mapStateToProps,
  null,
)(
  CommandChangeForm,
);
