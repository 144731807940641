import Axios from 'axios';
import AuthServices from '../services/auth';
import { handleAxiosError } from '../utils/api';
import config from '../../app.config';

function getInstance(noTimeout) {
  const auth = AuthServices.get();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: auth && auth.token ? `Bearer ${auth.token}` : undefined,
  };

  const axiosInstance = Axios.create({
    baseURL: config.apiUrl,
    timeout: noTimeout ? 0 : 30000,
    headers,
  });

  axiosInstance.interceptors.response.use((response) => response,
    async (err) => {
      return Promise.reject(handleAxiosError(err));
    });

  return axiosInstance;
}

export default getInstance;
