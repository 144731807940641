import React from 'react';
import { Row, Col, Divider } from 'antd';
import { I18n } from 'react-redux-i18n';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import { ViewType } from '../../../../../app/enum/viewType';

class UserSearchFormOperation extends React.Component {
  render() {
    const { viewType, operation } = this.props;
    return (
      <>
        <Divider />
        <label>{I18n.t('routes.panel.admin.items.command.details.form.operation.label')}</label>

        <Row gutter={16}>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.command.details.form.operation.type.label')}
              value={operation.type}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
        </Row>

        {operation.to?.length && operation.to.map((o) => (
          <Row gutter={16}>
            <Col span={4}>
              <AdvancedInput
                label={I18n.t('routes.panel.admin.items.command.details.form.operation.value.label')}
                value={o.value}
                disabled={viewType === ViewType.EDIT}
              />
            </Col>
            <Col span={8}>
              <AdvancedInput
                label={I18n.t('routes.panel.admin.items.command.details.form.operation.senderComment.label')}
                value={o.senderComment}
                disabled={viewType === ViewType.EDIT}
              />
            </Col>
            <Col span={8}>
              <AdvancedInput
                label={I18n.t('routes.panel.admin.items.command.details.form.operation.recipientComment.label')}
                value={o.recipientComment}
                disabled={viewType === ViewType.EDIT}
              />
            </Col>
            <Col span={4}>
              <AdvancedInput
                label={I18n.t('routes.panel.admin.items.command.details.form.operation.hasToLock.label')}
                value={o.hasToLock ? I18n.t('shared.yes') : I18n.t('shared.no')}
                disabled={viewType === ViewType.EDIT}
              />
            </Col>
          </Row>
        ))}
      </>
    )
  }
}

export default UserSearchFormOperation;
