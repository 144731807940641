import Immutable from 'seamless-immutable';
import { UtilsActions } from '../actions';

const initialState = Immutable({
	dataPaginated: null,
	dataDetails: null,
});

export default function utils(
	state = initialState,
	action,
) {
	switch (action.type) {
		case UtilsActions.ACTION_SAVE_UTILS_PAGINATED:
			state = {
				...state,
				dataPaginated: action.payload,
			};
			return state;
		case UtilsActions.ACTION_SAVE_UTILS_DETAILS:
      state = {
        ...state,
        dataDetails: action.payload,
      };
      return state;
		case UtilsActions.ACTION_CLEAN_UTILS:
				state = {
					...initialState,
				};
				return state;
		default:
			return state;
	}
}

export function getDataPaginated(state) {
  return state.utils.dataPaginated;
}

export function getDataDetails(state) {
  return state.utils.dataDetails;
}
