export const PaymentOrderMethods = {
  BOLETO: 'BOLETO',
  SALDO:  'SALDO',
  CARTAO_CREDITO: 'CARTAO_CREDITO',
  BOLETO_PARCELADO: 'BOLETO_PARCELADO',
  PIX_QR_CODE: 'PIX_QR_CODE',
};

export const PaymentOrderMethodsMap = new Map([
  [PaymentOrderMethods.BOLETO, 'BOLETO'],
  [PaymentOrderMethods.SALDO, 'SALDO'],
  [PaymentOrderMethods.CARTAO_CREDITO, 'CARTAO_CREDITO'],
  [PaymentOrderMethods.BOLETO_PARCELADO, 'BOLETO_PARCELADO'],
  [PaymentOrderMethods.PIX_QR_CODE, 'PIX_QR_CODE'],
]);