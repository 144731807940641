import React, { Component } from 'react';
import { Col, Radio } from 'antd';

class AdvancedRadio extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, options, optionType, onChange, value, buttonStyle, disabled } = this.props;

    return (
      <Col>
        <label style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{label}</span>
          <Radio.Group
            options={options}
            onChange={(ev) => onChange(ev.target.value)}
            value={value}
            optionType={optionType || 'button'}
            buttonStyle={buttonStyle || 'outline'}
            disabled={disabled || false}
            style={{ display: 'flex', flexDirection: 'column' }}
          />
        </label>
      </Col>
    );
  }
}

export default AdvancedRadio;
