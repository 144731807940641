import React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  DatePicker,
} from 'antd';

const { RangePicker } = DatePicker;

export const dateFormat = 'DD/MM/YYYY';

const AdvancedRangePicker = (
  {
    label,
    onChange,
    value,
  },
) => (
  <div className="advanced-rangepicker">
    <label className="advanced-rangepicker__label">
      <span className="advanced-rangepicker__label__inner">{label}</span>
      <RangePicker
        format={dateFormat}
        onChange={onChange}
        value={value && [value[0], value[1]]}
        placeholder={[
          I18n.t('shared.advancedFilters.rangeStart'),
          I18n.t('shared.advancedFilters.rangeEnd'),
        ]}
        style={
          { width: '100%' }
        }
      />
    </label>
  </div>
);

export default AdvancedRangePicker;
