import React from 'react';
import { Row, Col, Divider } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { ApiActions } from '../../../../../app/redux/actions';
import { ENDPOINTS } from '../../../../../app/endpoints/endpoints';
import { ACTION_SAVE_PAYMENT_ORDER_SAFE_BALANCE } from '../../../../../app/redux/actions/paymentOrder';
import { LoadingSelectors, PaymentOrderSelectors } from '../../../../../app/redux/reducers';
import { ViewType } from '../../../../../app/enum/viewType';
import { SafeBalanceStatusMap } from '../../../../../app/enum/safeBalanceStatus';
import * as DateUtils from '../../../../../app/utils/date';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';

class PaymentOrderFormSafeBalance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      safeBalance: this.props.safeBalances
    };
  }
  async componentDidMount() {
    const { get, id, safeBalances } = this.props;
    let validator = 0;
    if (!safeBalances && validator === 0) {
    const safeBalance = await get(id);
    this.setState({ safeBalance })
    validator +=1
    }
  }

  render() {
    const { viewType } = this.props;
    const { safeBalance }= this.state

    return (
      <>
        {safeBalance?.length ?
          safeBalance.map((o, index) => (
            <>
              {index !== 0 ? <Divider /> : null}

              <label>
                {`
                ${I18n.t('routes.panel.admin.items.paymentOrder.details.form.safeBalance.id.label')}
                ${o.id}
              `}
              </label>

              <Row gutter={16}>
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t(
                      'routes.panel.admin.items.paymentOrder.details.form.safeBalance.externalIdentifier.label'
                    )}
                    value={o.externalIdentifier}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                {o.installment && (
                  <Col span={6}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.safeBalance.installment.label')}
                      value={o.installment}
                      disabled={viewType === ViewType.EDIT}
                    />
                  </Col>
                )}
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.safeBalance.amount.label')}
                    value={o.amount}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.safeBalance.status.label')}
                    value={o.status && SafeBalanceStatusMap.get(o.status)}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                {o.lockedAt && (
                  <Col span={6}>
                    <AdvancedInput
                      type="datetime"
                      label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.safeBalance.locketAt.label')}
                      value={o.lockedAt && DateUtils.humanizeDateTime(o.lockedAt, 'DD/MM/YYYY HH:mm')}
                      disabled={viewType === ViewType.EDIT}
                    />
                  </Col>
                )}
                {o.key && (
                  <Col span={6}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.safeBalance.key.label')}
                      value={o.key}
                      disabled={viewType === ViewType.EDIT}
                    />
                  </Col>
                )}
              </Row>
            </>
          )):
          <Row>
          <div>
           <span>{`${I18n.t('shared.nothingToShow')}`}</span>
         </div>
         </Row>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  safeBalances: PaymentOrderSelectors.getDataSafeBalance(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  get: (id) =>
    dispatch(
      ApiActions.get(`${ENDPOINTS.paymentOrders}/${id}/safebalance`, null, ACTION_SAVE_PAYMENT_ORDER_SAFE_BALANCE)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOrderFormSafeBalance);
