import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from 'react-redux-i18n';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import { ViewType } from '../../../../../app/enum/viewType';

class PaymentOrderFormItems extends React.Component {
  render() {
    const { viewType, items } = this.props;
    return (
      <>
        {items.length && items.map((o) => (
          <Row gutter={16}>
            <Col span={8}>
              <AdvancedInput
                label={
                  I18n.t('routes.panel.admin.items.paymentOrder.details.form.items.description.label')
                }
                value={o.description}
                disabled={viewType === ViewType.EDIT}
              />
            </Col>
            <Col span={8}>
              <AdvancedInput
                label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.items.price.label')}
                value={o.price}
                disabled={viewType === ViewType.EDIT}
              />
            </Col>
            <Col span={8}>
              <AdvancedInput
                label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.items.quantity.label')}
                value={o.quantity}
                disabled={viewType === ViewType.EDIT}
              />
            </Col>
          </Row>
        ))}
      </>
    )
  }
}

export default PaymentOrderFormItems;