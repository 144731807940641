import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider,
  Layout, message, Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { DashboardOutlined } from '@ant-design/icons';
import { ApiActions } from '../../../../app/redux/actions';
import { LoadingSelectors, PaymentOrderSelectors, TransactionSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_CLEAN_PAYMENT_ORDER, ACTION_SAVE_CREDITCARD_PAYMENT_ORDER, ACTION_SAVE_PAYMENT_ORDER_DETAILS } from '../../../../app/redux/actions/paymentOrder';
import { GetIcon } from '../../../../app/enum/iconList';
import AdvancedTabs, { TabPane } from '../../../../components/shared/AdvancedTabs/AdvancedTabs';
import TransactionData from './transactionData';
import CardSteps from './cardSteps';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import TransactionValues from './transactionValues';
import { ACTION_SAVE_FEE_PAYER, ACTION_SAVE_SIMILAR_TRANSACTIONS, ACTION_SAVE_TRANSACTION_DETAIL, ACTION_SAVE_TRANSACTION_INSTALLMENTS, ACTION_SAVE_TRANSACTIONS_PAGINATED } from '../../../../app/redux/actions/transactions';
import DataTable from '../../../../components/shared/DataTable/DataTable';
import * as DateUtils from '../../../../app/utils/date';
import { statusText } from '../../../../app/utils/statusTransaction';
import SimilarTransactionsTable from './similarTransactions';
import ConciliationSteps from './conciliationSteps';

class TransactionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      dataDetails: null,
      orderDetails: null,
      activeGeneralTab: '1',
      activeCardTab: '1',
      individualTransaction: null,
      creditCard: null,
      similarTransactions: null,
      params: null,
      installments: null,
      feePayer: null,
    };
    this.dataTableRef = React.createRef();
  }

  async componentDidMount() {
    const { 
      getById, 
      getOrderById, 
      id, 
      clean, 
      getDataCreditCard, 
      getSimilarTransactions, 
      getDataInstallments,
      getFeePayer }
       = this.props;

    this.setState({
      viewType: id !== 'add' ? ViewType.EDIT : ViewType.ADD,
    });

    try {
      await getById(id)
      this.setState({ dataDetails: this.props.dataDetail.rows, individualTransaction: this.props.dataDetail.rows[this.props.dataDetail.count - 1]  });
      await getOrderById(this.state.dataDetails[0].paymentOrder);

      let params = { paymentOrderId: this.state.dataDetails[0].paymentOrder};
      await getDataCreditCard(params);

      this.setState({creditCard: this.props.creditCardDetails.rows[0] })
      this.setState({ orderDetails: this.props.orderDetail });

      let transactionParams = {
        transactionStatus: 3,
        paymentType: 3,
        userId: this.state.individualTransaction.userId,
        limit: 2
      }
      this.setState({ params: transactionParams})
      let data = await getSimilarTransactions(transactionParams);
      this.setState({ similarTransactions: data.rows})

      let installmentData = await getDataInstallments(id);
      this.setState( { installments: installmentData.rows});

      let feePayerData = await getFeePayer(id);
      this.setState( { feePayer: feePayerData.rows[0] } )

    } catch (error) {
      console.log(error);
    }
  }

  async submit(id, data) {
    const { update, create } = this.props;

    try {
      if (this.state.viewType === ViewType.ADD) {
        await create(data, () => {
          message.success(I18n.t('shared.messages.success.addSuccess'));
          navigate(I18n.t('routes.panel.admin.items.paymentOrder.url'));
        });
      } else {
        await update(id, data, () => {
          message.success(I18n.t('shared.messages.success.successEdit'));
          navigate(I18n.t('routes.panel.admin.items.paymentOrder.url'));
        });
      }
    } catch (e) {
      //
    }
  }

  resend(id) {
    this.props.resend(id, () => {
      message.success(I18n.t('shared.messages.success.resendSuccess'));
    });
  }

  render() {
    const { Content } = Layout;
    const { viewType, 
      dataDetails, 
      activeGeneralTab, 
      activeCardTab, 
      orderDetails, 
      individualTransaction, 
      creditCard, 
      params,
      similarTransactions,
      installments,
      feePayer
      
    } = this.state;
    const {
      loading,
      id,
      getById,
      getSimilarTransactions
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.admin.items.transactions.url')}>
                <span>{I18n.t('routes.panel.admin.items.transactions.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t('routes.panel.admin.items.transactions.pageDetail')
                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="payment-order-details">
            <AdvancedTabs            
                active={activeCardTab}
                onChange={(val) => {
                this.setState({ activeCardTab: val });
                }}
            >
                <TabPane key="1" tab={I18n.t('routes.panel.admin.items.transactions.form.general')}>
                    {individualTransaction && <CardSteps  paymentTry={dataDetails} data={individualTransaction}/> }
                </TabPane>
                <TabPane key="2" tab={I18n.t('routes.panel.admin.items.transactions.form.conciliation')}>
                  {(individualTransaction && installments && orderDetails) ? <ConciliationSteps installments={installments} orderDetail={orderDetails} feePayer={feePayer} /> :  <Spin spinning={loading > 0} tip={I18n.t('shared.loading')}></Spin>}      
                </TabPane>
            
            </AdvancedTabs>
            <Divider />
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="transaction__sectionTitle">
                    Dados do pagamento
                </h2>
              </div>
            </Spin>
            <AdvancedTabs            
                active={activeGeneralTab}
                onChange={(val) => {
                this.setState({ activeGeneralTab: val });
                }}
            >
                <TabPane key="1" tab={I18n.t('routes.panel.admin.items.transactions.form.general')}>
                    {individualTransaction && orderDetails ? <TransactionData creditCard={creditCard} orderDetail={orderDetails} data={individualTransaction} /> : <Spin spinning={loading > 0} tip={I18n.t('shared.loading')}></Spin>}
                </TabPane>
                <TabPane key="2" tab={I18n.t('routes.panel.admin.items.transactions.form.values')}>
                    {orderDetails && <TransactionValues  orderDetail={orderDetails}/> }
                </TabPane>

            </AdvancedTabs>
            <Divider />
            <div className="panel__layout__content__title">
                <h2 className="transaction__sectionTitle">
                    Pagamentos relacionados
                </h2>
              </div>
               {similarTransactions && <SimilarTransactionsTable rows={similarTransactions} /> }
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
    dataDetail: TransactionSelectors.getDataPaginated(state),
    orderDetail: PaymentOrderSelectors.getDataDetails(state),
    creditCardDetails: PaymentOrderSelectors.getDataCreditCard(state),
    similarTransactions: TransactionSelectors.getSimilarTransactions(state),
    dataInstallments: TransactionSelectors.getDataIntallments(state),
    dataFeePayer: TransactionSelectors.getFeePayer(state),
    loading: LoadingSelectors.getLoading(state)
  });
  
  const mapDispatchToProps = (dispatch) => ({
    getById: (id) => dispatch(ApiActions.getTransactionById(id, ENDPOINTS.paymentTry,  ACTION_SAVE_TRANSACTION_DETAIL)),
    getOrderById: (id) => dispatch(ApiActions.getById(id, ENDPOINTS.paymentOrders, ACTION_SAVE_PAYMENT_ORDER_DETAILS)),
    getDataCreditCard: (params) => dispatch(ApiActions.get(ENDPOINTS.creditCard, params, ACTION_SAVE_CREDITCARD_PAYMENT_ORDER)),
    getSimilarTransactions: (params) => dispatch(ApiActions.get(ENDPOINTS.transactions, params, ACTION_SAVE_SIMILAR_TRANSACTIONS)),
    getDataInstallments: (id) => dispatch(ApiActions.getTransactionById(id, ENDPOINTS.installments, ACTION_SAVE_TRANSACTION_INSTALLMENTS)),
    getFeePayer: (id) => dispatch(ApiActions.getTransactionById(id, ENDPOINTS.feePayer, ACTION_SAVE_FEE_PAYER)),
    resend: (id, callback) => dispatch(ApiActions.sendWebhook(id, ENDPOINTS.paymentOrders, callback)),
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionDetails);
