import * as DateUtils from '../utils/date';

export default function mountConciliationSteps(installments, orderDetail, feePayer) {

    let installmentSelected = orderDetail.customerSelectedInstallments;

    const defaultSteps = ['Provisionamento Integrado', 'Título de cartão integrado', 'Grupo de baixa integrado', 'Depósito realizado', 'Guia de despesa antecipada integrada'];
    let statusArray = [false,false,false,false,false];

    if(installments.length > 0){
        let installmentObject = installments.filter((obj) => obj.installment === installmentSelected)[0];
    
        if(installmentObject.entryReceiveAccountId){
            statusArray[0] = true;
        }
        if(installmentObject.titleGenerateId){
            statusArray[1] = true;
        }
        if(installmentObject.titleGroupId){
            statusArray[2] = true;
        }
        if(installmentObject.depositId){
            statusArray[3] = true
        }
        if(feePayer?.eventGuide?.id){
            statusArray[4] = true
        }
    }


    let stepsObj = {
        steps: defaultSteps,
        stepsStatus: statusArray
    }

    return stepsObj;

}