import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from 'react-redux-i18n';
import AdvancedInput from '../../../../../../components/shared/AdvancedInput/AdvancedInput';
import PaymentOrderFormReceivers from '../../receivers/PaymentOrderFormReceivers';
import { ViewType } from '../../../../../../app/enum/viewType';

class PaymentOrderFormBalance extends React.Component {
  render() {
    const { viewType, balance } = this.props;
    return (
      <>
        <Row gutter={16}>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.balance.expirationDate.label')}
              value={balance.expirationDate}
              type="date"
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.balance.instructions.label')}
              value={balance.instructions}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
          <Col span={8}>
            <AdvancedInput
              label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.config.balance.amount.label')}
              value={balance.amount}
              disabled={viewType === ViewType.EDIT}
            />
          </Col>
        </Row>

        {balance.receivers && <PaymentOrderFormReceivers receivers={balance.receivers} viewType={viewType} />}
      </>
    )
  }
}

export default PaymentOrderFormBalance;