const envVariables = {
  identityServer: {
    "baseUrl": "https://sso.stage.superbid.net",
    "clientId": "dashboard",
    "realm":"s4pay-backoffice",
  },
  timezone: "America/Sao_Paulo",
  language: "pt-br",
  apiUrl: "https://api.stage.s4pay.com.br/internal",
  apiFinancial: "https://fin-api.stage.s4pay.com.br"
};

export default envVariables;
