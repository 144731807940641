import moment from 'moment-timezone';

export default {
  application: {
    name: 'S4Pay - Dashboard',
    shortDescription: 'S4Pay',
    title: 'S4Pay',
    version: {
      title: 'Versão',
      number: '1.0.0',
    },
    footer: {
      poweredBy: 'Powered by',
      poweredByBrand: 'mb.labs',
      poweredByBrandUrl: 'https://mblabs.com.br',
    },
  },
  shared: {
    Webhook: {
      command: 'Reenviar para fila Webhook',
      op: 'Reenviar Webhook',
      commandRe: 'Reenviar para fila de reprocessamento',
    },
    conciliation: {
      qrCode: 'Reprocessar conciliação',
    },
    reprocessFile: {
      reprocess: 'Reprocessar arquivo de conciliacao',
    },
    person: {
      natural: 'PF',
      legal: 'PJ',
    },
    gender: {
      male: 'Masculino',
      female: 'Feminino',
    },
    notFoundSearch: 'Nada encontrado.',
    selectSomeValue: 'Selecione...',
    typeToContinue: 'Digite algo para pesquisar...',
    clickHere: 'clique aqui',
    type: 'Digite...',
    ok: 'OK',
    download: 'Efetuar download',
    pending: 'Pendente',
    save: 'Salvar',
    closure: 'Encerrar',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    remove: 'Remover',
    edit: 'Editar',
    details: 'Detalhes',
    file: 'Arquivo',
    send: 'Enviar',
    useValues: 'Utilizar selecionados',
    all: 'Todos',
    active: 'Ativo',
    inactive: 'Inativo',
    customer: 'Cliente',
    receiver: 'Receptor',
    yes: 'Sim',
    no: 'Não',
    loading: 'Carregando...',
    confirmTitle: 'Você tem certeza?',
    search: 'Pesquisar',
    logout: {
      menuText: 'Sair do sistema',
      confirmation: 'Você tem certeza que deseja sair?',
      confirmButton: 'Tenho certeza, sair.',
    },
    datatable: {
      showing: 'Exibindo',
      showingOf: 'de',
      showingResultsTotal: 'resultados',
    },
    nothingToShow: 'Nada para exibir, verifique os parâmetros e tente novamente.',
    hours: 'horas',
    minutes: 'minutos',
    seconds: 'segundos',
    distanceUnit: 'metros',
    distanceUnitSmall: 'm',
    cantAccess: 'Você não tem permissão para visualizar esta página.',
    searchSomething: 'Digite algo para pesquisar...',
    dateMask: 'DD/MM/YYYY',
    timeMask: 'HH:mm',
    panelActions: {
      export: 'Exportar',
    },
    advancedFilters: {
      id: 'Id',
      header: 'Filtros avançados',
      title: 'Título',
      rangeLabel: 'Período',
      userTaxIdentifier: 'CPF/CNPJ',
      externalIdentifier: 'Identificador externo',
      safeBalance: 'Safe Balance',
      selectPaymentAt: 'Data de ínicio de pagamento',
      selectPaymentMethod: 'Método de pagamento escolhido',
      feePayer: 'Pagador de taxas',
      expirationDate: 'Data de expiração',
      commandProcessed: 'Comando processado',
      transactionId: 'Id da transacao',
      transferAt: 'Data de transferência',
      sourceOp: 'Ordem de pagamento',
      email: 'E-mail',
      key: 'Chave',
      name: 'Nome',
      filterButtonText: 'Filtrar',
      exportButtonText: 'Exportar',
      clearButtonText: 'Limpar filtros',
      rangeStart: 'Data inicial',
      rangeEnd: 'Data final',
      code: 'Código',
      createdAtRange: 'Data de cadastro',
      status: 'Situação',
      value: 'Valor',
      paymentType: 'Tipo de pagamento',
      createdAt: 'Data de criacao',
      primaryNSU: 'NSU Primario',
      acquireNSU: 'Acquire NSU',
      eventGuideId: 'ID da guia contabil',
    },
    messages: {
      success: {
        addSuccess: 'Adicionado com sucesso.',
        deleteSuccess: 'Removido com sucesso.',
        accountClosureSuccess: 'Conta encerrada com sucesso.',
        resendSuccess: 'Reenviado com sucesso.',
        successEdit: 'Atualizado com sucesso.',
        welcome: 'Bem vindo(a) novamente.',
        export: 'Em breve você irá receber o relatório em seu e-mail de login.',
        createLinkedAccountSuccess: 'Subconta criada com sucesso.',
        ofuscateAccountSuccess: 'Conta ofuscada com sucesso.'
      },
      errors: {
        fields: 'Verifique os campos e tente novamente',
        resendError: 'Não foi reenviado',
        generic: 'Algo deu errado. Tente novamente mais tarde.',
        balance_must_be_zero: 'Saldo da conta deve estar zerado',
        user_not_valid: 'Conta inválida',
        invalid_key: 'Chave inválida',
        invalid_value: 'Valor inválido',
        linked_account_already_registered: 'Erro: subconta ja existente',
        balance_must_be_zero: 'Não é possível encerrar a conta com saldo disponível/bloqueado',
        ofuscateAccountError: 'Não foi possível ofuscar a conta',
      },
    },
  },

  routes: {
    panel: {
      pageTitle: 'Painel',
      dash: {
        sidebarVisible: 'y',
        sidebarTitle: 'Dashboard',
        icon: 'registration',
        items: {
          dashboard: {
            url: '/',
            sidebarTitle: 'Dashboard',
            pageTitle: 'Dashboard',
            pageContent: 'Bem-vindo ao painel administrativo do S4PAY',
            icon: 'registration',
            details: {
              systemStatus: 'Informações atualizadas do sistema:',
              status: 'Situação: ',
              minDate: 'Data mínima de lançamento: ',
              checkingDate: 'Data corrente sistema: ',
            },
            concilie: {
              sectionTitle: 'Conciliações',
              icon: 'registration',
              totalToConcilie: 'Conciliações totais',
              totalConciliated: 'Conciliações processadas',
              missingToConcilie: 'Conciliações pendentes',
              createdAt: 'Data da conciliação'
            },
          },
          financial: {
            url: '/financial',
            sidebarTitle: 'Dashboard Financial'
          },
        },
      },
      admin: {
        sidebarVisible: 'y',
        sidebarTitle: 'Relatório',
        icon: 'registration',
        items: {
          qrCode: {
            url: '/qrcode',
            pageTitle: 'QR code',
            sidebarTitle: 'QR codes',
            pageTitleView: 'Visualizar QR code',
            pageDescriptionView: 'Visualize as informações do QR code abaixo',
            goToPaymentOrder: 'Ir para ordem de pagamento',
            reprocessConciliation: 'Reprocessar conciliação',
            tabs: {
              general: 'Geral',
              conciliation: 'Conciliação'
            },
            dataTable: {
              columns: {
                txid: {
                  key: 'txId',
                  title: 'TxId',
                },
                transactionId: {
                  key: 'transaction',
                  title: 'Id da transação',
                },
                status: {
                  key: 'status',
                  title: 'Status',
                },
                paidAt: {
                  key: 'paidAt',
                  title: 'Data de pagamento',
                },
                expirationDate: {
                  key: 'expirationDate',
                  title: 'Data de expiração',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de criação',
                },
                paymentOrderId: {
                  key: 'transaction',
                  title: 'Id da ordem de pagamento',
                },
                name: {
                  key: 'name',
                  title: 'Nome do usuário',
                },
                userTaxIdentifier: {
                  key: 'transaction',
                  title: 'CPF/CNPJ do usuário',
                },
                conciliationStatus: {
                  key: 'transaction',
                  title: 'Status da conciliação',
                },
                value: {
                  key: 'value',
                  title: 'Valor',
                },
                id: {
                  key: 'id',
                  title: 'Ações',
                },
                externalId: {
                  key: 'externalId',
                  title: 'Identificador externo',
                },
                receiverId: {
                  key: 'transaction',
                  title: 'Id do recebedor',
                },
                depositId: {
                  key: 'transaction',
                  title: 'Id do depósito',
                },
                titleGenerateId: {
                  key: 'transaction',
                  title: 'Id da geração do título',
                },
                titleInstallmentId: {
                  key: 'transaction',
                  title: 'Id da parcela do título',
                },
                titleGroupId: {
                  key: 'transaction',
                  title: 'Id do grupo do título',
                },
                receiptDate: {
                  key: 'transaction',
                  title: 'Data de recebimento',
                }
              },
            },
          },
          administrator: {
            url: '/administrador',
            sidebarTitle: 'Administradores',
            pageTitle: 'Gerenciar administradores',
            icon: 'registration',
            addNewButtonText: 'Adicionar administrador',
            pageTitleAdd: 'Adicionar administrador',
            pageTitleEdit: 'Editar administrador',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um administrador',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um administrador',
            details: {
              changePasswordPhrase: 'Desejo alterar a senha do administrador',
              form: {
                name: {
                  label: 'Nome completo',
                },
                email: {
                  label: 'E-mail',
                },
                oldPassword: {
                  label: 'Senha anterior',
                },
                password: {
                  label: 'Senha nova',
                },
                passwordConfirmation: {
                  label: 'Confirmação de senha',
                },
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                },
              },
            },
          },
          adjustmentConciliation: {
            url: '/ajuste-conciliacao',
            sidebarTitle: 'Ajuste Conciliação',
            pageTitle: 'Ajuste Conciliação',
          },
          conciliationFile: {
            url: '/reprocess-conciliation-file',
            resendAllButton: 'Reprocessar todos arquivos de conciliação',
            sidebarTitle: 'Arquivos de conciliação',
            pageTitle: 'Gerenciar arquivos de conciliação',
            status: 'Status',
            icon: 'registration',
            dataTable: {
              columns: {
                transactionId: {
                  key: 'transactionId',
                  title: 'Id da transação',
                },
                id: {
                  key: 'id',
                  title: 'Identificador da linha',
                },
                fileType: {
                  key: 'fileType',
                  title: 'Tipo do arquivo',
                },
                status: {
                  key: 'Status',
                  title: 'Status',
                },
                objectLine: {
                  key: 'objectLine',
                  title: 'Linha',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
              },
            },
          },

          accountingGuide: {
            url: '/guias-contabeis',
            resendAllButton: 'Reprocessar todos arquivos de conciliação',
            sidebarTitle: 'Guias Contabeis',
            pageTitle: 'Gerenciar Guias Contabeis',
            icon: 'registration',
            dataTable: {
              columns: {
                transactionId: {
                  key: 'transactionId',
                  title: 'Id da transação',
                },
                id: {
                  key: 'id',
                  title: 'Id',
                },
                paymentOrderId: {
                  key: 'paymentOrderId',
                  title: 'ID da Ordem de pagamento',
                },
                eventGuideId: {
                  key: 'eventGuideId',
                  title: 'ID da guia contabil',
                },
                primaryNSU: {
                  key: 'primaryNSU',
                  title: 'NSU Primario',
                },
                acquireNSU: {
                  key: 'acquireNSU',
                  title: 'NSU Aquire',
                },
                orderAmount: {
                  key: 'orderAmount',
                  title: 'Valor da ordem',
                },
                totalAmount: {
                  key: 'totalAmount',
                  title: 'Valor total',
                },
                fee: {
                  key: 'fee',
                  title: 'Taxa',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
              },
            },
          },
          command: {
            url: '/comando',
            resendAllButton: 'Reenviar todos Pendentes',
            sidebarTitle: 'Comandos',
            pageTitle: 'Gerenciar comandos',
            icon: 'registration',
            addNewButtonText: 'Adicionar comando',
            pageTitleAdd: 'Adicionar comando',
            pageTitleEdit: 'Editar comando',
            pageTitleView: 'Visualizar comando',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um comando',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um comando',
            pageDescriptionView: 'Visualize as informações abaixo de um comando',
            details: {
              form: {
                id: {
                  label: 'Id',
                },
                externalId: {
                  label: 'Identificador externo',
                },
                status: {
                  label: 'Situação',
                },
                commandProcessed: {
                  label: 'Comando processado',
                },
                commandProcessedAt: {
                  label: 'Data do processamento',
                },
                transferAt: {
                  label: 'Data da transferência',
                },
                unlockAt: {
                  label: 'Data da liberação',
                },
                paymentOrder: {
                  label: 'Visualizar ordem de pagamento',
                },
                operation: {
                  label: 'Operação',
                  type: {
                    label: 'Tipo',
                  },
                  value: {
                    label: 'Valor',
                  },
                  hasToLock: {
                    label: 'Necessário travar',
                  },
                  recipientComment: {
                    label: 'Comentário do destinatário',
                  },
                  senderComment: {
                    label: 'Comentário do remetente',
                  },
                },
                actions: {
                  label: 'Ações disponiveis',
                  commandChange: 'Alterar status do comando',
                  auditCommand: 'Auditoria'
                },
              },
            },
            dataTable: {
              columns: {
                externalId: {
                  key: 'externalId',
                  title: 'Identificador externo',
                },
                sourceOp: {
                  key: 'sourceOp',
                  title: 'Identificador da ordem de pagamento',
                },
                operation: {
                  key: 'operation',
                  type: {
                    title: 'Tipo',
                  },
                  value: {
                    title: 'Valor',
                  },
                },
                status: {
                  key: 'status',
                  title: 'Situação',
                },
                commandProcessed: {
                  key: 'commandProcessed',
                  title: 'Comando processado',
                },
                transferAt: {
                  key: 'transferAt',
                  title: 'Data da transferência',
                },
                unlockAt: {
                  key: 'unlockAt',
                  title: 'Data da liberação',
                },
                commandProcessedAt: {
                  key: 'commandProcessedAt',
                  title: 'Data do processamento',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                },
              },
            },
          },
          paymentOrder: {
            url: '/ordem-pagamento',
            sidebarTitle: 'Ordens de pagamento',
            pageTitle: 'Gerenciar ordens de pagamento',
            icon: 'registration',
            addNewButtonText: 'Adicionar ordem de pagamento',
            pageTitleAdd: 'Adicionar ordem de pagamento',
            pageTitleEdit: 'Editar ordem de pagamento',
            pageTitleView: 'Visualizar ordem de pagamento',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar uma ordem de pagamento',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar uma ordem de pagamento',
            pageDescriptionView: 'Visualize as informações abaixo de uma ordem de pagamento',
            downloadBillet: 'Baixar Boleto',
            cancelTransaction: 'Cancelar transação',
            details: {
              form: {
                tabs: {
                  general: 'Geral',
                  creditCard: 'Cartão de crédito',
                  bankSlip: 'Boleto',
                  bankSlipParcel: 'Boleto parcelado',
                  balance: 'Saldo',
                  items: 'Itens',
                  config: 'Formas de pagamento',
                  transactions: 'Transações',
                  safeBalance: 'Safe Balance',
                  workflow: 'Fluxo de trabalho',
                },
                code: {
                  label: 'Código',
                },
                externalIdentifier: {
                  label: 'Identificador externo',
                },
                totalAmount: {
                  label: 'Valor total',
                },
                status: {
                  label: 'Situação',
                },
                message: {
                  label: 'Mensagem',
                },
                expirationDate: {
                  label: 'Data de expiração',
                },
                safeBalance: {
                  label: 'Safe balance',
                  id: {
                    label: 'Identificador: ',
                  },
                  externalIdentifier: {
                    label: 'Identificador externo',
                  },
                  amount: {
                    label: 'Valor',
                  },
                  installment: {
                    label: 'Parcelas',
                  },
                  status: {
                    label: 'Situação',
                  },
                  locketAt: {
                    label: 'Data de fechamento',
                  },
                  key: {
                    label: 'Key',
                  },
                },
                selectPaymentAt: {
                  label: 'Data de ínicio de pagamento',
                },
                customerSelectedInstallments: {
                  label: 'Quantidade de parcelas escolhida',
                },
                selectPaymentMethod: {
                  label: 'Método de pagamento escolhido',
                },
                customer: {
                  name: {
                    label: 'Nome do cliente',
                  },
                  taxIdentifier: {
                    label: 'Documento',
                  },
                },
                version: {
                  label: 'Versão'
                },
                config: {
                  enableMultiplePaymentMethods: {
                    label: 'Permite múltiplos métodos de pagamento',
                  },
                  acceptedPaymentMethods: {
                    label: 'Métodos de pagamento aceitos',
                  },
                  bankSlip: {
                    expirationDate: {
                      label: 'Data de expiração do boleto',
                    },
                    instructions: {
                      label: 'Instruções',
                    },
                    amount: {
                      label: 'Valor do boleto',
                    },
                  },
                  balance: {
                    expirationDate: {
                      label: 'Data de expiração do saldo',
                    },
                    instructions: {
                      label: 'Instruções',
                    },
                    amount: {
                      label: 'Valor do saldo',
                    },
                  },
                  bankSlipParcel: {
                    firstExpirationDate: {
                      label: 'Data do primeiro vencimento',
                    },
                    format: {
                      label: 'Formato do parcelamento',
                    },
                    instructions: {
                      label: 'Instruções',
                    },
                    intervalConfig: {
                      label: 'CONFIGURAÇÃO DE INTERVALO',
                      installments: {
                        label: 'Parcelas',
                      },
                      amount: {
                        label: 'Valor da parcela',
                      },
                      feeAmount: {
                        label: 'Valor da taxa',
                      },
                      ratio: {
                        label: 'Ratio',
                      },
                    },
                  },
                  creditCard: {
                    maxInstallments: {
                      label: 'Quantidade máxima de parcelas',
                    },
                    intervalConfig: {
                      label: 'CONFIGURAÇÃO DE INTERVALO',
                      installments: {
                        label: 'Parcelas',
                      },
                      amount: {
                        label: 'Valor da parcela',
                      },
                      feeAmount: {
                        label: 'Valor da taxa',
                      },
                    },
                  },
                  receivers: {
                    label: 'Receptores',
                    externalId: {
                      label: 'Identificador externo',
                    },
                    amount: {
                      label: 'Valor máximo a receber',
                    },
                    user: {
                      name: {
                        label: 'Nome do receptor',
                      },
                      userTaxIdentifier: {
                        label: 'Documento',
                      },
                    },
                  },
                },
                transaction: {
                  id: {
                    label: 'Identificador: ',
                  },
                  installment: {
                    label: 'Parcelas',
                  },
                  fee: {
                    label: 'Valor da taxa',
                  },
                  totalAmount: {
                    label: 'Valor total',
                  },
                  orderAmount: {
                    label: 'Valor da ordem',
                  },
                  key: {
                    label: 'Key',
                  },
                  status: {
                    label: 'Situação',
                  },
                  paymentType: {
                    label: 'Tipo do pagamento',
                  },
                  creditCardFlag: {
                    label: 'Bandeira do cartão de crédito',
                  },
                },
                workflow: {},
                items: {
                  description: {
                    label: 'Descrição',
                  },
                  price: {
                    label: 'Preço',
                  },
                  quantity: {
                    label: 'Quantidade',
                  },
                },
              },
            },
            dataTable: {
              columns: {
                customer: {
                  key: 'customer',
                  name: {
                    title: 'Nome do cliente',
                  },
                  userTaxIdentifier: {
                    title: 'Documento',
                  },
                },
                code: {
                  key: 'code',
                  title: 'Código',
                },
                externalIdentifier: {
                  key: 'externalIdentifier',
                  title: 'Identificador externo',
                },
                totalAmount: {
                  key: 'totalAmount',
                  title: 'Valor total',
                },
                status: {
                  key: 'status',
                  title: 'Situação',
                },
                expirationDate: {
                  key: 'expirationDate',
                  title: 'Data de expiração',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                selectedInstallments: {
                  key: 'customerSelectedInstallments',
                  title: 'Parcelas selecionadas'
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                },
              },
            },
          },
          accountChange: {
            sidebarVisible: 'n',
            url: '/usuario/cancel-adm-account',
            pageTitle: 'Alteração de conta',
            pageTitleEdit: 'Alteração de conta normal',
            details: {
              form: {
                warning: {
                  confirmation: 'Deseja realmente alterar esta conta?',
                  ofuscateAccount: 'Deseja realmente ofuscar essa conta?',
                  balance: 'O saldo da conta deve estar zerado',
                  extract: 'O extrato será perdido',
                  irreversible: 'Essa ação é irreversível',
                },
                rules: {
                  important: 'Leia com atenção: ',
                  balance: 'O saldo da conta deve estar zerado',
                },
              },
            },
          },
          accountUpdate: {
            sidebarVisible: 'n',
            url: '/usuario',
            pageTitle: 'Atualizacao de conta',
            pageTitleEdit: 'Alterar dado do usuario',
          },
          accountClosure: {
            sidebarVisible: 'n',
            url: '/encerrar-conta',
            pageTitle: 'Encerramento de conta',
            pageTitleEdit: 'Encerremanto da conta do usuario',
          },
          accountMigrate: {
            sidebarVisible: 'n',
            url: '/migrar-conta',
            pageTitle: 'Alterar conta ADM para conta pgto comum',
            pageTitleEdit: 'Alteração de conta administrativa',
          },
          commandChange: {
            sidebarVisible: 'n',
            url: '/comando',
            pageTitle: 'Alteração no status do comando',
            pageTitleEdit: 'Alterar status do comando',
            details: {
              form: {
                warning: {
                  confirmation: 'Deseja realmente alterar o status deste comando, de PENDING para OK ?',
                },
              },
            },
          },
          commandAudit: {
            sidebarVisible: 'n',
            url: '/comando',
            pageTitle: 'Auditoria',
            pageTitleEdit: 'Visualizar comando',
            pageSubtitle: 'Visualize as informações abaixo de um comando',
          },
          search: {
            sidebarVisible: 'y',
            sidebarTitle: 'Usuários',
            icon: 'search',
            url: '/usuarios',
            pageTitle: 'Listagem de usuários',
            pageTitleAdd: 'Adicionar Usuário',
            pageTitleView: 'Visualizar Usuário',
            pageDescriptionAdd: 'Crie um Usuário abaixo',
            pageDescriptionUpdate: 'Atualize um usuário abaixo',
            pageDescriptionView: 'Visualize as informações do usuário abaixo',
            pageUpdateUserDescription: 'Atualize as informações do usuário abaixo',
            pageContent: 'Bem-vindo ao painel de busca de usuários do S4PAY',
            pageTitleSearch: 'Usuários',
            pageDescriptionSearch: 'Procure por usuários aqui',
            searchButton: 'Buscar',
            results: 'Resultados',
            error: {
              Nt: 'Não foi encontrado nenhum usuário',
              Default: 'Faça sua busca',
            },
            form: {
              label: {
                id: 'Id',
                email: 'E-mail',
                name: 'Nome',
                taxIdentifier: 'CPF ou CNPJ',
                accountId: 'Conta matera',
              },
              dataTable: {
                columns: {
                  name: {
                    key: 'name',
                    title: 'Nome',
                  },
                  email: {
                    key: 'email',
                    title: 'E-mail',
                  },
                  personType: {
                    key: 'personType',
                    title: 'Tipo',
                  },
                },
              },
            },
            details: {
              form: {
                tabs: {
                  general: 'Geral',
                  paymentOrder: 'Ordens de Pagamento',
                },
                id: 'Id',
                accountType: 'Tipo de conta',
                externalId: 'Id externo',
                name: 'Nome',
                photoUrl: 'Url da Foto',
                linkedAccountId: "Subconta",
                email: 'E-mail',
                cellphone: 'Telefone',
                taxIdentifier: 'Registro',
                personType: 'Tipo de pessoa',
                mothersName: 'Nome da mãe',
                accountId: 'Conta Matera',
                birthDate: 'Data de nascimento',
                gender: 'Gênero',
                address: 'Endereço:',
                city: 'Cidade',
                complement: 'Complemento',
                country: 'País',
                district: 'Bairro',
                number: 'Número',
                state: 'Estado',
                street: 'Rua',
                uf: 'Uf',
                zipcode: 'CEP',
                actions: {
                  label: 'Ações disponiveis',
                  accountChange: 'Alterar para conta de repasse',
                  accountUpdate: 'Atualizar cadastro',
                  accountClosure: 'Encerrar a conta',
                  accountMigrate: 'Alterar conta de usuário (Conta ADM para NORMAL)',
                  ofuscateAccount: 'Ofuscar conta',
                  createLinkedAccount: 'Criar subconta',
                },
              },
            },
          },
          settings: {
            sidebarVisible: 'y',
            sidebarTitle: 'Configurações',

            url: '/busca/configuracoes',
            pageTitle: 'Configurações',
            pageTitleAdd: 'Adicionar Chave',
            pageTitleView: 'Visualizar Chave',
            pageTitleEdit: 'Editar Chave',
            pageDescriptionAdd: 'Crie um Chave abaixo',
            pageDescriptionEdit: 'Edite uma Chave abaixo',
            pageDescriptionView: 'Visualize as informações da chave abaixo',
            pageContent: 'Bem-vindo ao painel de busca de configurações do S4PAY',
            pageTitleSearch: 'Configurações',
            pageDescriptionSearch: 'Procure por chaves aqui',
            searchButton: 'Buscar',
            addNewButtonText: 'Adicionar Configuração',
            results: 'Resultados',
            error: {
              Nt: 'Não foi encontrado nenhum usuário',
              Default: 'Faça sua busca',
            },
            form: {
              label: {
                email: 'E-mail',
                name: 'Nome',
                taxIdentifier: 'CPF ou CNPJ',
                cellphone: 'Telefone',
                accountId: 'Conta da Matera',
              },
              dataTable: {
                columns: {
                  key: {
                    key: 'nome',
                    title: 'Chave',
                  },
                  value: {
                    key: 'value',
                    title: 'Valor',
                  },
                },
              },
            },
            details: {
              form: {
                key: 'Chave',
                value: 'Valor',
              },
            },
          },
          credit: {
            sidebarTitle: 'Crédito',
            url: '/credit',
            dataTable: {
              columns: {
                status: {
                  key: 'status',
                  title: 'Status',
                },
                offer: {
                  key: 'loan',
                  title: 'Oferta',
                },
                document: {
                  key: 'loan',
                  title: 'CPF/CNPJ Arrematante',
                },
                actions: {
                  key: 'loan',
                  title: 'Ações',
                },
              },
            },
          },
          transactions: {
            sidebarTitle: 'Cartão de crédito',
            url: '/credit-card',
            pageTitle: 'Transações cartão de crédito',
            pageDetail: 'Visualizar transação cartão de crédito',
            form: {
              general: 'Geral',
              conciliation: 'Conciliação',
              values: 'Valores'
            },
            dataTable: {
              columns: {
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de pagamento',
                },
                value: {
                  key: 'totalAmount',
                  title: 'Valor'
                },
                status: {
                  key: 'status',
                  title: 'Situação'
                },
                taxIdentifier: {
                  key: 'paymentOrder',
                  title: 'CPF/CNPJ'
                },
                customer: {
                  key: 'paymentOrder',
                  title: 'Cliente'
                },
                id: {
                  key: 'paymentOrderId',
                  title: 'Identificador da ordem de pagamento'
                },
                transaction: {
                  key: 'id'
                }
              },
            },
          },
          simulation: {
            sidebarTitle: 'Simulações',
            url: '/simulations',
            dataTable: {
              columns: {
                id: {
                  key: 'id',
                  title: 'Id',
                },
                proponent: {
                  key: 'proponent',
                  title: 'Proponent',
                },
                document: {
                  key: 'document',
                  title: 'CPF/CNPJ',
                },
                phone: {
                  key: 'phone',
                  title: 'Telefone'
                },
                value: {
                  key: 'totalValue',
                  title: 'Valor da oferta'
                },
                user: {
                  key: 'userExternalId',
                  title: 'External Id'
                },
                entrance: {
                  key: 'downPayment',
                  title: 'Entrada'
                },
                installment: {
                  key: 'installments',
                  title: 'Parcelas'
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de criação'
                }
              },
            },
          }
        },
      },
      analytics: {
        sidebarVisible: 'y',
        sidebarTitle: 'Analytics',
        panelTitle: 'Analytics',
        icon: 'registration',

        items: {
          views: {
            sidebarTitle: 'Gráficos e métricas',
            pageTitle: 'Métricas (Operacional)',
            icon: 'registration',
            url: '/analytics',
            counters: {
              ops: {
                title: 'Ops Criadas',
                params: {
                  chartSubject: 'orders',
                  keys: 'id',
                  methods: 'count',
                },
              },
              cashouts: {
                title: 'Cashouts Realizados',
                params: {
                  chartSubject: 'transactions',
                  keys: 'id',
                  methods: 'count',
                },
              },
              queueSize: {
                title: 'Fila de webhooks',
                params: {
                  chartSubject: 'queue',
                  keys: 'id',
                  methods: 'count',
                },
              },
              users: {
                title: 'Usuários Cadastrados',
                params: {
                  chartSubject: 'users',
                  keys: 'id',
                  methods: 'count',
                },
              },
            },
          },
          queueWatcher: {
            sidebarTitle: 'Filas',
            pageTitle: 'Métricas (Filas)',
            icon: 'registration',
            url: '/queue',
            counters: {
              messages: {
                title: 'Nº aprox. de mensagens',
                params: {
                  sqsParams: ['ApproximateNumberOfMessages'],
                  chartSubject: 'queue',
                },
              },
              delayed: {
                title: 'Mensagens atrasadas',
                params: {
                  sqsParams: ['ApproximateNumberOfMessagesDelayed'],
                  chartSubject: 'queue',
                },
              },
            },
          },
        },
      },
    },
  },
  enum: {
    profileType: {
      admin: 'Administrador',
    },
  },
};
