import React from 'react';
import { Divider, Layout, Button, Row, Col, message, Breadcrumb, Collapse, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { EditOutlined, SearchOutlined, DashboardOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import DataTable from '../../../../components/shared/DataTable';
import { ApiActions } from '../../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, UserSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { ACTION_SAVE_USERS_PAGINATED } from '../../../../app/redux/actions/user';
import { GetIcon } from '../../../../app/enum/iconList';

class AdministratorList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        name: null,
        email: null,
      },
    };
    this.dataTableRef = React.createRef();
  }

  getPaginated(params) {
    params = {
      ...params,
    };
    const { getPaginated } = this.props;
    this.setState({ params: { ...params } });
    getPaginated(params);
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('shared.messages.success.deleteSuccess'));
      this.dataTableRef.current.reset();
    });
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const { dataPaginated, loading } = this.props;
    const { params } = this.state;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardOutlined /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.admin.items.administrator.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse className="advanced-filter">
            <Panel header={I18n.t('shared.advancedFilters.header')} key="0">
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.name')}
                    value={params && params.name}
                    onChange={(val) => this.fieldChange('name', val)}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('shared.advancedFilters.email')}
                    value={params && params.email}
                    onChange={(val) => this.fieldChange('email', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24} className="advanced-filter__search-button text-right">
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilters.clearButtonText')}
                    onClick={() => this.getPaginated()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilters.filterButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.getPaginated({ ...params, offset: 0 })}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(I18n.t('routes.panel.admin.items.administrator.icon'), {
                      fill: '#F37223',
                      width: 24,
                      height: 24,
                    })}
                  </span>
                  {I18n.t('routes.panel.admin.items.administrator.pageTitle')}
                </h2>
              </Col>
              <Col className="text-right" span={12}>
                <AdvancedButton
                  text={I18n.t('routes.panel.admin.items.administrator.addNewButtonText')}
                  href={`${I18n.t('routes.panel.admin.items.administrator.url')}/add`}
                  icon={GetIcon(I18n.t('routes.panel.admin.items.administrator.icon'))}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginated(parameters)}
                data={dataPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                params={params}
                showSizeChanger
                pageSizeOptions={
                  dataPaginated && dataPaginated.count && dataPaginated.count > 100
                    ? ['10', '50', '100', dataPaginated.count.toString()]
                    : ['10', '20', '50', '100']
                }
                columns={[
                  {
                    key: I18n.t('routes.panel.admin.items.administrator.dataTable.columns.name.key'),
                    title: I18n.t('routes.panel.admin.items.administrator.dataTable.columns.name.title'),
                    render: (value) => value || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.administrator.dataTable.columns.email.key'),
                    title: I18n.t('routes.panel.admin.items.administrator.dataTable.columns.email.title'),
                    render: (value) => value || '--',
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.administrator.dataTable.columns.createdAt.key'),
                    title: I18n.t('routes.panel.admin.items.administrator.dataTable.columns.createdAt.title'),
                    render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                  },
                  {
                    key: I18n.t('routes.panel.admin.items.administrator.dataTable.columns.actions.key'),
                    title: '',
                    render: (id) => (
                      <div className="dataTable__item--right">

                        <Tooltip title={I18n.t('shared.edit')} overlayStyle={{ fontSize: 11 }}>
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => navigate(`${I18n.t('routes.panel.admin.items.administrator.url')}/${id}`)}
                          />
                        </Tooltip>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataPaginated: UserSelectors.getDataPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) =>
    dispatch(ApiActions.getPaginated(parameters, ENDPOINTS.suser, ACTION_SAVE_USERS_PAGINATED)),
  remove: (id, callback) => dispatch(ApiActions.remove(id, ENDPOINTS.suser, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorList);
