import 'bootstrap/dist/css/bootstrap-grid.min.css';

import { Router } from '@reach/router';
import React from 'react';
import { I18n } from 'react-redux-i18n';

import Panel from './Panel';

import DashboardDetails from '../../containers/panel/dashboard/details/DashboardDetails';
import AdministratorList from '../../containers/panel/administrator/list/AdministratorList';
import AdministratorDetails from '../../containers/panel/administrator/details/AdministratorDetails';
import PaymentOrderList from '../../containers/panel/paymentOrder/list/PaymentOrderList';
import PaymentOrderDetails from '../../containers/panel/paymentOrder/details/PaymentOrderDetails';
import CommandList from '../../containers/panel/command/list/CommandList';
import CommandDetails from '../../containers/panel/command/details/CommandDetails';
import WorkFlowDetails from '../../containers/panel/paymentOrder/form/workflow/WorkflowDetails';
import UsersSearch from '../../containers/panel/users/list/UserSearchList';
import UserSearchDetails from '../../containers/panel/users/details/UserSearchDetails';
import UserAccountChangeDetails from '../../containers/panel/accountChange/details/UserAccountChangeDetails';
import SettingsList from '../../containers/panel/settings/list/SettingsList'
import SettingsDetails from '../../containers/panel/settings/details/SettingsDetails'
import CommandChangeDetails from '../../containers/panel/commandChange/details/CommandChangeDetails'
import UserAccountUpdateDetails from '../../containers/panel/accountUpdate/details/UserAccountUpdateDetails'
import ConciliationList from '../../containers/panel/reprocessConciliation/list/ConciliationList'
import AccountingGuideList from '../../containers/panel/accountingGuide/list/AccoutingGuideList'
import UserAccountClosureDetails from '../../containers/panel/accountClosure/details/UserAccountClosureDetails'
import Financial from '../../containers/panel/financial/financial';

import Analytics from '../../containers/panel/analytics';
import FinancialList from '../../containers/panel/financial/list/financialList';
import SimulationList from '../../containers/panel/financial/list/simulationList';
import TransactionsList from '../../containers/panel/transactions/list/transactionsList';
import TransactionsDetails from '../../containers/panel/transactions/details/transactionsDetails';
import AdjustmentConciliation from '../../containers/panel/adjustmentConciliation/adjustmentConciliation';
import UserAccountMigrateDetails from '../../containers/panel/accountMigrate/details/UserAccountMigrateDetails';
import CommandAuditDetails from '../../containers/panel/commandAudit/details/CommandAuditDetails';
import QrCodeList from '../../containers/panel/qrCode/list/qrCodeList';
import QrCodeDetails from '../../containers/panel/qrCode/details/qrCodeDetails';


class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  toggle = () => {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed,
    });
  };

  render() {
    return (
      <Router>
        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={'/'}
          container={<DashboardDetails />}
          title={`${I18n.t('routes.panel.dash.items.dashboard.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.dash.items.financial.url')}`}
          container={<Financial />}
          title={`${I18n.t('routes.panel.dash.items.financial.sidebarTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={I18n.t('routes.panel.workflow.items.flow.url')}
          container={<WorkFlowDetails />}
          title={`${I18n.t('routes.panel.workflow.items.flow.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={I18n.t('routes.panel.admin.items.administrator.url')}
          container={<AdministratorList />}
          title={`${I18n.t('routes.panel.admin.items.administrator.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.administrator.url')}/:id`}
          container={<AdministratorDetails />}
          title={`${I18n.t('routes.panel.admin.items.administrator.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={I18n.t('routes.panel.admin.items.paymentOrder.url')}
          container={<PaymentOrderList />}
          title={`${I18n.t('routes.panel.admin.items.paymentOrder.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.paymentOrder.url')}/:id`}
          container={<PaymentOrderDetails />}
          title={`${I18n.t('routes.panel.admin.items.paymentOrder.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={I18n.t('routes.panel.admin.items.accountingGuide.url')}
          container={<AccountingGuideList />}
          title={`${I18n.t('routes.panel.admin.items.accountingGuide.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={I18n.t('routes.panel.admin.items.command.url')}
          container={<CommandList />}
          title={`${I18n.t('routes.panel.admin.items.command.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.command.url')}/:id`}
          container={<CommandDetails />}
          title={`${I18n.t('routes.panel.admin.items.command.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.search.url')}/:id`}
          container={<UserSearchDetails />}
          title={`${I18n.t('routes.panel.admin.items.search.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.search.url')}`}
          container={<UsersSearch />}
          title={`${I18n.t('routes.panel.admin.items.search.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.accountChange.url')}/:id`}
          container={<UserAccountChangeDetails />}
          title={`${I18n.t('routes.panel.admin.items.accountChange.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.accountUpdate.url')}/:id`}
          container={<UserAccountUpdateDetails />}
          title={`${I18n.t('routes.panel.admin.items.accountUpdate.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.accountClosure.url')}/:id`}
          container={<UserAccountClosureDetails />}
          title={`${I18n.t('routes.panel.admin.items.accountClosure.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.accountMigrate.url')}/:id`}
          container={<UserAccountMigrateDetails />}
          title={`${I18n.t('routes.panel.admin.items.accountMigrate.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.commandChange.url')}/:id/alter-command`}
          container={<CommandChangeDetails />}
          title={`${I18n.t('routes.panel.admin.items.commandChange.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.commandAudit.url')}/:id/audit-command`}
          container={<CommandAuditDetails />}
          title={`${I18n.t('routes.panel.admin.items.commandAudit.pageTitle')} - ${I18n.t('application.name')}`}
        />  

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.settings.url')}/:id`}
          container={<SettingsDetails />}
          title={`${I18n.t('routes.panel.admin.items.settings.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.conciliationFile.url')}`}
          container={<ConciliationList />}
          title={`${I18n.t('routes.panel.admin.items.conciliationFile.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.credit.url')}`}
          container={<FinancialList />}
          title={`${I18n.t('routes.panel.admin.items.credit.sidebarTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.simulation.url')}`}
          container={<SimulationList />}
          title={`${I18n.t('routes.panel.admin.items.simulation.sidebarTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.transactions.url')}`}
          container={<TransactionsList />}
          title={`${I18n.t('routes.panel.admin.items.transactions.sidebarTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.qrCode.url')}`}
          container={<QrCodeList />}
          title={`${I18n.t('routes.panel.admin.items.qrCode.sidebarTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.qrCode.url')}/:id`}
          container={<QrCodeDetails />}
          title={`${I18n.t('routes.panel.admin.items.qrCode.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.adjustmentConciliation.url')}`}
          container={<AdjustmentConciliation />}
          title={`${I18n.t('routes.panel.admin.items.adjustmentConciliation.sidebarTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.transactions.url')}/:id`}
          container={<TransactionsDetails />}
          title={`${I18n.t('routes.panel.admin.items.transactions.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.admin.items.settings.url')}`}
          container={<SettingsList />}
          title={`${I18n.t('routes.panel.admin.panelTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          path={`${I18n.t('routes.panel.analytics.items.views.url')}`}
          container={<Analytics />}
          title={`${I18n.t('routes.panel.analytics.panelTitle')} - ${I18n.t('application.name')}`}
        />

      </Router>
    );
  }
}

export default Content;
