import React from 'react';
import { Row, Col, Divider, message } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { ApiActions } from '../../../../../app/redux/actions';
import { ENDPOINTS } from '../../../../../app/endpoints/endpoints';
import { ACTION_SAVE_PAYMENT_ORDER_TRANSACTIONS } from '../../../../../app/redux/actions/paymentOrder';
import { LoadingSelectors, PaymentOrderSelectors } from '../../../../../app/redux/reducers';
import { PaymentTransactionStatusMap } from '../../../../../app/enum/paymentTransactionStatus';
import { ViewType } from '../../../../../app/enum/viewType';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import { ExportOutlined, StopOutlined } from '@ant-design/icons';

class PaymentOrderFormTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: this.props.form.transactions,
    };
  }

  async componentDidMount() {
    const { get, id, form } = this.props;
    let validator = 0;
    const { transactions: transaction } = form;
    
      if (!transaction && validator === 0) {
      const transaction = await get(id);
      this.setState({ transaction })
      validator +=1
    } else if(validator === 0){
      this.setState( { transaction });
      validator +=1;
    }
 }

 async postUnlockTransaction(id) {
  const { unlockTransaction } = this.props;
  try {
    await unlockTransaction(id);
    message.success("Desbloqueada com sucesso")
  } catch (error) {
    message.error("Falha ao desbloquear saldo")
  }
 }

  render() {
    const { viewType } = this.props;
    const { transaction }= this.state

    return (
      <>
        {transaction?.length ?
          transaction.map((o, index) => (
                                  
            <>
              {index !== 0 ? <Divider /> : null}

              <label>
                {`
                ${I18n.t('routes.panel.admin.items.paymentOrder.details.form.transaction.id.label')}
                ${o.id}
              `}
              </label>

              <Row gutter={16}>
                {o.installment && (
                  <Col span={4}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.transaction.installment.label')}
                      value={o.installment}
                      disabled={viewType === ViewType.EDIT}
                    />
                  </Col>
                )}
                <Col span={4}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.transaction.fee.label')}
                    value={o.fee}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={4}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.transaction.orderAmount.label')}
                    value={o.orderAmount}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={4}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.transaction.totalAmount.label')}
                    value={o.totalAmount}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={4}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.transaction.status.label')}
                    value={o.status && PaymentTransactionStatusMap.get(o.status)}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={4}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.transaction.paymentType.label')}
                    value={o.paymentType}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                {o.creditCardFlag && (
                  <Col span={6}>
                    <AdvancedInput
                      label={I18n.t(
                        'routes.panel.admin.items.paymentOrder.details.form.transaction.creditCardFlag.label'
                      )}
                      value={o.creditCardFlag}
                      disabled={viewType === ViewType.EDIT}
                    />
                  </Col>
                )}
                {o.key && (
                  <Col span={6}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.transaction.key.label')}
                      value={o.key}
                      disabled={viewType === ViewType.EDIT}
                    />
                  </Col>
                )}
                  <Row align='middle'>
                      {o.billets && (
                      <Col span={4}>
                          <a href={o.billets.billUrl} download target='_blank'>
                          <AdvancedButton
                          text={I18n.t('routes.panel.admin.items.paymentOrder.downloadBillet')}
                          icon={<ExportOutlined/>}/>
                          </a>
                      </Col>
                      )}
                  </Row>
              </Row>
              <Row>
                <Col span={4}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.admin.items.paymentOrder.details.form.version.label')}
                      value={o.version && o.version}
                      disabled={viewType === ViewType.EDIT}
                    />
                  </Col>
              </Row>
              {
                o.status === 5 &&
                <Row gutter={16} >
                  <Col span={4}>
                          <AdvancedButton
                              onClick={() => this.postUnlockTransaction(o.id)}
                              text={I18n.t('routes.panel.admin.items.paymentOrder.cancelTransaction')}
                              icon={<StopOutlined />}
                          />
                  </Col>
                </Row>
              }
            </>
          )):
          <Row>
           <div>
            <span>{`${I18n.t('shared.nothingToShow')}`}</span>
          </div>
        </Row>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({

  transactions: PaymentOrderSelectors.getDataTransactions(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  get: (id) =>
    dispatch(
      ApiActions.get(`${ENDPOINTS.paymentOrders}/${id}/transaction`, null, ACTION_SAVE_PAYMENT_ORDER_TRANSACTIONS)
    ),
  unlockTransaction: (transactionId) => dispatch (
    ApiActions.post(null, `${ENDPOINTS.paymentOrderTransaction}/${transactionId}/unlock`, null)
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOrderFormTransactions);
