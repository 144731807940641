import React from 'react';
import { Row, Col, Divider } from 'antd';
import { I18n } from 'react-redux-i18n';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import { ViewType } from '../../../../../app/enum/viewType';
import UserSearchFormOperation from '../operation/UserSearchFormOperation'
import * as DateUtils from '../../../../../app/utils/date';

class UsersFormGeneral extends React.Component {
  render() {
    const { viewType, general } = this.props;
    return (
      <>
       <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.id')}
                    value={general.id}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.accountId')}
                    value={general.accountId}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.externalId')}
                    value={general.externalId}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.name')}
                    value={general.name}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.email')}
                    value={general.email}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.accountType')}
                    value={general.dataType ? 'ADM' : 'PAGAMENTO'}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                </Row>
                <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.cellphone')}
                    value={general.cellphone}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.birthDate')}
                    value={
                      general.birthDate
                      && DateUtils.humanizeDateTime(general.birthDate, 'DD/MM/YYYY HH:mm')
                    }
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.gender')}
                    value={general.gender=== 1
                      ?  I18n.t('shared.gender.male')
                      : I18n.t('shared.gender.female')
                    }
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                </Row>
                <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.personType')}
                    value={general.personType === "PERSON"
                      ? I18n.t('shared.person.natural')
                      : I18n.t('shared.person.legal')
                    }
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.mothersName')}
                    value={
                      general.mothersName
                    }
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.taxIdentifier')}
                    value={general.taxIdentifier}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                </Row>
                <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.photoUrl')}
                    value={general.photoUrl}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                      label={I18n.t('routes.panel.admin.items.search.details.form.linkedAccountId')}
                      value={general.linkedAccountId}
                      disabled={viewType === ViewType.EDIT}
                    />
                </Col>
              </Row>
              <Divider/>
              <p>{I18n.t('routes.panel.admin.items.search.details.form.address')}</p>
              <p></p>
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.street')}
                    value={general.street}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.number')}
                    value={general.number}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.complement')}
                    value={general.complement}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.district')}
                    value={general.district}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.city')}
                    value={general.city}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.state')}
                    value={general.state}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.country')}
                    value={general.country}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.uf')}
                    value={general.uf}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.admin.items.search.details.form.zipcode')}
                    value={general.zipcode}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
              </Row>

              {general.sourceOp &&
                <a
                  href={`${I18n.t('routes.panel.admin.items.paymentOrder.url')}/${general.sourceOp}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  type="link"
                >
                  {I18n.t('routes.panel.admin.items.command.details.form.paymentOrder.label')}
                </a>
              }

              {general.operation && <UserSearchFormOperation operation={general.operation} viewType={viewType} />}

              <Divider />
      </>
    )
  }
}

export default UsersFormGeneral;
