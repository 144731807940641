import * as DateUtils from '../utils/date';

export default function mountCardSteps(paymentTrys) {


    let stepsObj = {
        steps: [],
        stepsStatus: [],
        stepsError: []
    }
    let defaultSteps = [{label: "Pagamento inciado", description: DateUtils.humanizeDateTime(paymentTrys[0].createdAt,'lll') },
    {label: "Desafio 3ds", description: DateUtils.humanizeDateTime(paymentTrys[0].createdAt,'lll') }, 
    {label: "Transação aprovada", description: DateUtils.humanizeDateTime(paymentTrys[0].createdAt,'lll') }]

    let defaultStepsError = [{label: "Pagamento inciado", description: DateUtils.humanizeDateTime(paymentTrys[0].createdAt,'lll') },
    {label: "Desafio 3ds", description: DateUtils.humanizeDateTime(paymentTrys[0].createdAt,'lll') }, 
    {label: "Erro na transação", description: DateUtils.humanizeDateTime(paymentTrys[0].createdAt,'lll') }]

    if(paymentTrys.length === 1){
        if(paymentTrys[0].action === 2 && paymentTrys[0].status === 1){
            stepsObj.steps = defaultSteps;
            stepsObj.stepsStatus =  [true, true, false]; 
            stepsObj.stepsError =  [false,'challenge standby',false];
        }
        if(paymentTrys[0].action === 1 && paymentTrys[0].status === 1){
            stepsObj.steps = defaultSteps;
            stepsObj.stepsStatus =  [true, true, false]; 
            stepsObj.stepsError =  [false,'challenge standby',false];
        }
        if(paymentTrys[0].action===2 && paymentTrys[0].status === 2){
            stepsObj.steps = defaultSteps;
            stepsObj.stepsStatus  = [true, false, true]; 
            stepsObj.stepsError = [false,false,false];
        }
        if(paymentTrys[0].action===3 && paymentTrys[0].status === 2){
            stepsObj.steps = defaultSteps
            stepsObj.stepsStatus  = [true, true, true];
            stepsObj.stepsError = [false,false,false];
        }
        if(paymentTrys[0].status === 3 && paymentTrys[0].action === 2){
            stepsObj.steps = defaultStepsError;
            stepsObj.stepsStatus = [true, false, true];
            stepsObj.stepsError = [false,false,true];
        }
        if(paymentTrys[0].status === 3 && paymentTrys[0].action === 1){
            stepsObj.steps = defaultStepsError;
            stepsObj.stepsStatus = [true, false, true];
            stepsObj.stepsError = [false,false,true];
        }
        if(paymentTrys[0].status === 3 && paymentTrys[0].action === 3){
            stepsObj.steps =  defaultStepsError;
            stepsObj.stepsStatus =[true, true, true];
            stepsObj.stepsError = [false,false,true];
        }
    }

    else {
        paymentTrys.map((paymentTry) => {
            if((paymentTry.action === 1 && paymentTry.status === 1)|| (paymentTry.action === 2 && paymentTry.status === 1)){
                let steps = [
                    {label: "Pagamento inciado", description: DateUtils.humanizeDateTime(paymentTry.createdAt,'lll') },
                    {label: "Desafio 3ds", description: DateUtils.humanizeDateTime(paymentTry.createdAt,'lll') }
                            ]
                let stepsStatus = [true, true];
                let stepsError = [false,false];
                stepsObj.steps = steps;
                stepsObj.stepsStatus = stepsStatus;
                stepsObj.stepsError = stepsError;
            }
            else if (paymentTry.action === 2 && paymentTry.status === 2){
                let steps = [
                    {label: "Pagamento inciado", description: DateUtils.humanizeDateTime(paymentTry.createdAt,'lll') },
                    {label: "Desafio 3ds", description: DateUtils.humanizeDateTime(paymentTry.createdAt,'lll') }
                            ]
                let stepsStatus = [true, false];
                let stepsError = [false,false];
                stepsObj.steps = steps;
                stepsObj.stepsStatus = stepsStatus;
                stepsObj.stepsError = stepsError;
            }
    
            if(paymentTry.status === 2){
                let step = {label: "Transação aprovada", description: DateUtils.humanizeDateTime(paymentTry.createdAt,'lll') };
                stepsObj.steps.push(step)
                stepsObj.stepsStatus.push(true);
                stepsObj.stepsError.push(false);
            }
    
            if(paymentTry.status === 3){
                let step = {label: "Erro na transação", description: DateUtils.humanizeDateTime(paymentTry.createdAt,'lll') };
                stepsObj.steps.push(step)
                stepsObj.stepsStatus.push(true);
                stepsObj.stepsError.push(true);
            }
        })
    }

    return stepsObj;
}