import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, message, Divider } from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import AdvancedButton from '../../../../components/shared/AdvancedButton';


class UserAccountUpdateForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {
				name: null,
				email: null,
        cellphone: null,
				accountId: null,
				externalId: null,
				linkedAccountId: null,
			},
		};
	}

	componentDidMount() {
		const { data, id } = this.props;
		if (data && id !== 'add') {
			this.setState({
				form: {
					name: data && data.name,
					email: data && data.email,
          cellphone: data && data.cellphone,
					accountId: data && data.accountId,
					externalId: data && data.externalId,
					linkedAccountId: data && data.linkedAccountId
				},
			});
		}
	}

	onSubmit(e) {
		e.preventDefault();

		const { form } = this.state;

		const scheman = yup.object().shape({
			email: yup.string().nullable({ isNullable: false }).required(I18n.t('shared.messages.errors.invalid_email')),
			name: yup
        .string()
        .required(I18n.t('shared.messages.errors.invalid_name')),
		});

		scheman
			.validate(form)
			.then(() => {
				const { data, submitFunction } = this.props;
				try {
					submitFunction(data ? data.id : null, form);
				} catch (error) {
					//
				}
			})
			.catch((error) => {
				message.error(error.message);
			});
	}

  fieldChange(name, value) {
		const { form } = this.state;
		form[name] = value;
		this.setState({ form });
	}

	render() {
		const { loading } = this.props;
		const { form } = this.state;
		return (
			<Row>
				{loading === 0 && (
					<Col span={24}>
						<form name="accountUpdateForm" onSubmit={(ev) => this.onSubmit(ev)}>
							<Row gutter={16}>
								<Col span={12}>
									<AdvancedInput
										label={I18n.t('routes.panel.admin.items.search.details.form.name')}
										value={form && form.name}
										onChange={(val) => this.fieldChange('name', val)}
									/>
								</Col>
								<Col span={12}>
									<AdvancedInput
										label={I18n.t('routes.panel.admin.items.search.details.form.email')}
										value={form && form.email}
										onChange={(val) => this.fieldChange('email', val)}
									/>
								</Col>
                <Col span={12}>
									<AdvancedInput
										label={I18n.t('routes.panel.admin.items.search.details.form.cellphone')}
										value={form && form.cellphone}
										onChange={(val) => this.fieldChange('cellphone', val)}
									/>
								</Col>
                <Col span={12}>
									<AdvancedInput
										label={I18n.t('routes.panel.admin.items.search.details.form.accountId')}
										value={form && form.accountId}
										onChange={(val) => this.fieldChange('accountId', val)}
									/>
								</Col>
								<Col span={12}>
									<AdvancedInput
										label={I18n.t('routes.panel.admin.items.search.details.form.externalId')}
										value={form && form.externalId}
										onChange={(val) => this.fieldChange('externalId', val)}
									/>
								</Col>
								<Col span={12}>
									<AdvancedInput
										label={I18n.t('routes.panel.admin.items.search.details.form.linkedAccountId')}
										value={form && form.linkedAccountId}
										onChange={(val) => this.fieldChange('linkedAccountId', val)}
										/>
								</Col>
							</Row>



							<Divider />

							<Row>
								<Col span={24} className="text-right">
									<AdvancedButton
										type="link"
										text={I18n.t('shared.cancel')}
										href={I18n.t('routes.panel.admin.items.search.url')}
									/>
									<Divider className="form-button-divider" type="vertical" />

									<AdvancedButton htmlType="submit" text={I18n.t('shared.save')} icon={<SaveOutlined />}
		              />
								</Col>
							</Row>
						</form>
					</Col>
				)}
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: LoadingSelectors.getLoading(state),
});

export default connect(mapStateToProps, null)(UserAccountUpdateForm);
