export const ConciliationPixStatus = {
    NOT_STARTED: 0,
    STARTED: 1,
    TITLE_CREATED: 2,
    GROUP_CREATED: 3,
    DEPOSIT_CREATED: 4,
  };
  
  
  export const ConciliationPixStatusMap = new Map([
    [ConciliationPixStatus.NOT_STARTED, 'Não Iniciado'],
    [ConciliationPixStatus.STARTED, 'Iniciado'],
    [ConciliationPixStatus.TITLE_CREATED, 'Título criado'],
    [ConciliationPixStatus.GROUP_CREATED, 'Grupo criado'],
    [ConciliationPixStatus.DEPOSIT_CREATED, 'Deposito criado']
  ]);
  
  