import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider } from 'antd';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import AdvancedTabs, { TabPane } from '../../../../components/shared/AdvancedTabs/AdvancedTabs';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import { PaymentOrderStatus } from '../../../../app/enum/paymentOrderStatus';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import { QRCodeStatusMap } from '../../../../app/enum/qrCodeStatus';
import * as DateUtils from '../../../../app/utils/date';
import { ConciliationPixStatusMap } from '../../../../app/enum/pixConciliationStatus';
import { navigate } from '@reach/router';

class QrCodeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        expirationDate: null,
        status: null,
        transactionId: null,
        value: null,
        createdAt: null,
        paymentOrderId: null,
        name: null,
        useTaxIdentifier: null,
        externalId: null,
        paidAt: null,
        receiverId: null,
        depositId: null,
        titleGenerateId: null,
        titleInstallmentId: null,
        titleGroupId: null,
        receiptDate: null,
        conciliationStatus: null
      },
      activeGeneralTab: '1',
      activeConfigTab: '1',
    };
  }

  componentDidMount() {
    const { id, data } = this.props;
    
    if (data && id !== 'add') {
      this.setState({
        form: {
            expirationDate: data && DateUtils.humanizeDateTime(data?.expirationDate, 'DD/MM/YYYY HH:mm'),
            status: data && QRCodeStatusMap.get(data?.status),
            transactionId: data && data?.transactionId,
            value: data && data?.value,
            createdAt: data && DateUtils.humanizeDateTime(data?.createdAt, 'DD/MM/YYYY HH:mm'),
            paymentOrderId: data && data?.transaction?.paymentOrderId,
            name: data && data?.transaction?.paymentOrder?.customer?.name,
            useTaxIdentifier: data && data?.transaction?.paymentOrder?.customer?.taxIdentifier,
            externalId: data && data?.externalId,
            paidAt: data && DateUtils.humanizeDateTime(data?.paidAt, 'DD/MM/YYYY HH:mm'),
            receiverId: data && data?.transaction?.pixConciliationControl[0]?.receiverId,
            depositId: data && data?.transaction?.pixConciliationControl[0]?.depositId,
            titleGenerateId: data && data?.transaction?.pixConciliationControl[0]?.titleGenerateId,
            titleInstallmentId: data && data?.transaction?.pixConciliationControl[0]?.titleInstallmentId,
            titleGroupId: data && data?.transaction?.pixConciliationControl[0]?.titleGroupId,
            receiptDate: data && DateUtils.humanizeDateTime(data?.transaction?.pixConciliationControl[0]?.receiptDate, 'DD/MM/YYYY HH:mm'),
            conciliationStatus: data && ConciliationPixStatusMap.get(data?.transaction?.pixConciliationControl[0]?.status)
        },
        activeGeneralTab: '1',
        activeConfigTab: '1',
      });
    }
  }

  render() {
    const { loading, viewType, id, openModal, checkBilletBalance, reprocessConciliation } = this.props;
    const { form, activeGeneralTab, activeConfigTab } = this.state;

    return (
      <Row>
        {loading === 0 && (
          <Col span={24}>
            <form name="paymentOrderForm" onSubmit={(ev) => this.onSubmit(ev)}>
              <AdvancedTabs
                active={activeGeneralTab}
                onChange={(val) => {
                  this.setState({ activeGeneralTab: val });
                }}
              >
                <TabPane key="1" tab={I18n.t('routes.panel.admin.items.qrCode.tabs.general')}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.expirationDate.title')}
                            value={form.expirationDate}
                            disabled={true}
                            />
                        </Col>
                        <Col span={8}>
                            <AdvancedInput
                            value={form.transactionId}
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.transactionId.title')}
                            disabled={true}
                            />
                        </Col>
                        <Col span={8}>
                            <AdvancedInput
                            value={form.value}
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.value.title')}
                            disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.createdAt.title')}
                            value={form.createdAt}
                            disabled={true}
                            />
                        </Col>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.paymentOrderId.title')}
                            value={form.paymentOrderId}
                            disabled={true}
                            />
                        </Col>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.name.title')}
                            value={form.name}   
                            disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.userTaxIdentifier.title')}
                            value={form.useTaxIdentifier}
                            disabled={true}
                            />
                        </Col>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.externalId.title')}
                            value={form.externalId}
                            disabled={true}
                            />
                        </Col>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.paidAt.title')}
                            value={form.paidAt}
                            disabled={true}
                            />
                        </Col>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.status.title')}
                            value={form.status}
                            disabled={true}
                            />
                        </Col>
                    </Row>
                </TabPane>

                <TabPane key="2" tab={I18n.t('routes.panel.admin.items.qrCode.tabs.conciliation')}>
                <Row gutter={16}>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.receiverId.title')}
                            value={form.receiverId}
                            disabled={true}
                            />
                        </Col>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.depositId.title')}
                            value={form.depositId}
                            disabled={true}
                            />
                        </Col>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.titleGenerateId.title')}
                            value={form.titleGenerateId}
                            disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.titleInstallmentId.title')}
                            value={form.titleInstallmentId}
                            disabled={true}
                            />
                        </Col>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.titleGroupId.title')}
                            value={form.titleGroupId}
                            disabled={true}
                            />
                        </Col>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.receiptDate.title')}
                            value={form.receiptDate}
                            disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.conciliationStatus.title')}
                            value={form.conciliationStatus}
                            disabled={true}
                            />
                        </Col>
                        <Col span={8}>
                            <AdvancedInput
                            label={I18n.t('routes.panel.admin.items.qrCode.dataTable.columns.value.title')}
                            value={form.value}
                            disabled={true}
                            />
                        </Col>
                    </Row>
                </TabPane>
              </AdvancedTabs>

                <AdvancedButton
                    type="primary"
                    onClick={() => navigate(`${I18n.t('routes.panel.admin.items.paymentOrder.url')}/${form?.paymentOrderId}`)}
                    text={I18n.t('routes.panel.admin.items.qrCode.goToPaymentOrder')}
                />
                <AdvancedButton
                    type="primary"
                    style={{marginLeft: 10}}
                    onClick={() => reprocessConciliation(id)}
                    text={I18n.t('routes.panel.admin.items.qrCode.reprocessConciliation')}
                />
              <Divider />
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

export default connect(mapStateToProps, null)(QrCodeForm);
