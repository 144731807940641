import React from 'react';
import {
  LockOutlined, BarcodeOutlined, CreditCardOutlined, UserOutlined, PaperClipOutlined, InboxOutlined,
} from '@ant-design/icons';
import { ReactComponent as RegistrationIcon } from '../assets/img/registration_icon.svg';

export const IconsList = {
  REGISTRATION: 'registration',
  LOCK: 'lock',
  BARCODE: 'barcode',
  CREDIT_CARD: 'creditCard',
  USER: 'user',
  NOTE: 'note',
  ORDERS: 'orders',
};

export const IconsListArray = [
  { name: IconsList.REGISTRATION, icon: <RegistrationIcon /> },
  { name: IconsList.LOCK, icon: <LockOutlined /> },
  { name: IconsList.BARCODE, icon: <BarcodeOutlined /> },
  { name: IconsList.CREDIT_CARD, icon: <CreditCardOutlined /> },
  { name: IconsList.USER, icon: <UserOutlined /> },
  { name: IconsList.NOTE, icon: <PaperClipOutlined /> },
  { name: IconsList.ORDERS, icon: <InboxOutlined /> },
];

export const GetIcon = (iconName, props = { fill: '#fff' }) => {
  const iconItem = IconsListArray.find((o) => o.name === iconName);
  if (iconItem) {
    return React.cloneElement(iconItem.icon, props);
  }
  return <RegistrationIcon />;
};
