import React from 'react';
import moment from 'moment-timezone';
import { Col, Layout, Row, Divider, Typography } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { GetIcon } from '../../../../app/enum/iconList';
import { LoadingSelectors, DashboardSelectors } from '../../../../app/redux/reducers';
import { ApiActions } from '../../../../app/redux/actions';
import {
  ACTION_LOAD_CONCILIE_DATA,
  ACTION_SAVE_DASHBOARD_SYSTEM_STATUS,
} from '../../../../app/redux/actions/dashboard';
import { ENDPOINTS } from '../../../../app/endpoints/endpoints';
import { Collapse } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedRangePicker from '../../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';
class DashboardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        createdAt: null,
        createdAtRange: null,
      },
    };
  }

  async componentDidMount() {
    const { getSystemStatus, getConcilieData } = this.props;

    try {
      await getSystemStatus();
      await getConcilieData();
    } catch (error) {
      //
    }
  }

  validityChange(key, keyRange, dates) {
    const { params } = this.state;

    const value = dates[0].format('YYYY-MM-DD').toString().concat(',',
      dates[1].format('YYYY-MM-DD').toString()
    );

    this.setState({
      params: {
        ...params,
        [key]: value,
        [keyRange]: dates,
      },
    });
  }

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;

    const { systemStatus, concilieData, getConcilieData } = this.props;
    const { params } = this.state;

    return (
      <>
        <Content className="panel__layout__content">
          <div className="dashboard">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(I18n.t('routes.panel.dash.items.dashboard.icon'), {
                      fill: '#F37223',
                      width: 24,
                      height: 24,
                    })}
                  </span>
                  {I18n.t('routes.panel.dash.items.dashboard.pageTitle')}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <p>{I18n.t('routes.panel.dash.items.dashboard.pageContent')}</p>
              </Col>
            </Row>
            {systemStatus && (
              <>
                <Divider />
                <Row>
                  <Col span={14}>
                    <span>{I18n.t('routes.panel.dash.items.dashboard.details.systemStatus')}</span>

                    <ul>
                      {systemStatus.situacao && (
                        <li>
                          {I18n.t('routes.panel.dash.items.dashboard.details.status').concat(
                            ` ${systemStatus.situacao}`
                          )}
                        </li>
                      )}
                      {systemStatus.dataMinLancamento && (
                        <li>
                          {I18n.t('routes.panel.dash.items.dashboard.details.minDate').concat(
                            ` ${moment(systemStatus.dataMinLancamento).format('DD/MM/YYYY')}`
                          )}
                        </li>
                      )}
                      {systemStatus.dataCorrenteSistema && (
                        <li>
                          {I18n.t('routes.panel.dash.items.dashboard.details.checkingDate').concat(
                            ` ${moment(systemStatus.dataCorrenteSistema).format('DD/MM/YYYY')}`
                          )}
                        </li>
                      )}
                    </ul>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Content>

        <Content className="panel__layout__content">
          <div className="dashboard">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    {GetIcon(I18n.t('routes.panel.dash.items.dashboard.concilie.icon'), {
                      fill: '#F37223',
                      width: 24,
                      height: 24,
                    })}
                  </span>
                  {I18n.t('routes.panel.dash.items.dashboard.concilie.sectionTitle')}
                </h2>
              </Col>
            </Row>


            <Content className="panel__layout__content panel__layout__content--advanced-filter"
              style={{
                margin: 24,
                marginLeft: 0,
              }}>
              <Collapse
                className="advanced-filter"
              >
                <Panel
                  header={I18n.t('shared.advancedFilters.header')}
                  key="0"
                >
                  <Row gutter={16}>
                    <Col span={8}>
                      <AdvancedRangePicker
                        label={I18n.t('routes.panel.dash.items.dashboard.concilie.createdAt')}
                        value={params && params.createdAtRange}
                        onChange={(val) => this.validityChange('createdAt', 'createdAtRange', val)}
                      />
                    </Col>

                  </Row>
                  <Row gutter={16}>
                    <Col
                      span={24}
                      className="advanced-filter__search-button text-right"
                    >
                      <AdvancedButton
                        text={I18n.t('shared.advancedFilters.filterButtonText')}
                        icon={<SearchOutlined />}
                        onClick={() => getConcilieData({ ...params })}
                      />
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Content>
            {concilieData && (
              <Row justify="space-between">
                <Col>
                  <Typography
                    style={{
                      fontSize: '18px',
                    }}
                  >
                    {I18n.t('routes.panel.dash.items.dashboard.concilie.totalToConcilie')}
                  </Typography>
                  <Typography
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: '24px',
                    }}
                  >
                    {concilieData.totalToConcilie && concilieData.totalToConcilie.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                  </Typography>
                </Col>

                <Col>
                  <Typography
                    style={{
                      fontSize: '18px',
                    }}
                  >
                    {I18n.t('routes.panel.dash.items.dashboard.concilie.totalConciliated')}
                  </Typography>
                  <Typography
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: '24px',
                    }}
                  >
                    {concilieData.totalConciliated && concilieData.totalConciliated.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                  </Typography>
                </Col>

                <Col>
                  <Typography
                    style={{
                      fontSize: '18px',
                    }}
                  >
                    {I18n.t('routes.panel.dash.items.dashboard.concilie.missingToConcilie')}
                  </Typography>
                  <Typography
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: '24px',
                    }}
                  >
                    {concilieData.missingToConcilie && concilieData.missingToConcilie.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                  </Typography>
                </Col>
              </Row>
            )}
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  systemStatus: DashboardSelectors.getSystemStatus(state),
  concilieData: DashboardSelectors.getConcilieData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getSystemStatus: () =>
    dispatch(ApiActions.customGet(`${ENDPOINTS.dashboard}/system-status`, ACTION_SAVE_DASHBOARD_SYSTEM_STATUS)),
  getConcilieData: (parameters) =>
    dispatch(ApiActions.getPaginated(parameters, `${ENDPOINTS.utils}/conciliation-analytics`, ACTION_LOAD_CONCILIE_DATA)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDetails);
