import './styles/less/index.less';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import moment from 'moment-timezone';
import jstz from 'jstimezonedetect';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import Config from './app.config';

import AuthServices from './app/services/auth';
import i18nDictionary from './i18n';
import * as AuthActions from './app/redux/actions/auth';
import initializeRedux from './app/redux';
import Routes from './app/routes';
import './index.scss';
import keycloak from './keycloak';

const redux = initializeRedux();

const eventLogger = (event, error) => {
  console.log('onKeycloakEvent', { event, error });
  if (event === 'onAuthRefreshError') {
    keycloak.logout();
  }
};
const tokenLogger = async (tokens) => {
  AuthServices.create(tokens);
  await redux.store.dispatch(AuthActions.saveAuthentication(tokens));
  await redux.store.dispatch(AuthActions.getMe());
};

try {
  const tz = jstz.determine();
  const timezone = tz.name() || Config.timezone;
  let { language } = Config.language;
  if (!i18nDictionary[language]) ({ language } = Config);

  syncTranslationWithStore(redux.store);
  redux.store.dispatch(loadTranslations(i18nDictionary));
  redux.store.dispatch(setLocale(language));

  moment.tz.setDefault(timezone);
  moment.locale(language);
} catch (err) {
  console.log(err)
}

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    onEvent={eventLogger}
    onTokens={tokenLogger}
    refreshToken={tokenLogger}
    initOptions={{ onLoad: 'login-required' }}
  >
    <Provider store={redux.store}>
      <PersistGate loading={null} persistor={redux.persistor}>
        <ConfigProvider locale={ptBR}>
          <Routes />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </ReactKeycloakProvider>,
  document.getElementById('root')
);
