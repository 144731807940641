import React from "react"
import { Card, Col, Layout, Row, Spin, Modal, Input, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import {ReactComponent as ArrowDown} from '../../../app/assets/img/ic_arrowDown.svg';
import {ReactComponent as UploadIcon} from '../../../app/assets/img/ic_upload.svg';
import ApiFinancial from "../../../app/api/apiFinancial";
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement)

  const options = {
    responsive: true,
    elements: {
        point: {
            radius: 0
        }
    },
    scales : {
        y: {
            suggestedMin: 0,
            suggestedMax: 100,
            ticks: {
                callback: function(d) {
                    return d + '%'
                }
            }
        },
        x: {
            grid: {
                display: false
            },
        }
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: function(item) {
                        return item.dataset.data[item.dataIndex] + '%'
                }

            }
        } 
    },

  }
  const horizontalConfig = {
        indexAxis: 'y',
        responsive: true,
        scales: {
            x: {
                suggestedMax: 3000
            },
            y: {
                grid: {
                    display: false
                }
            }
        }

  }

  const secondBarOption = {
    responsive: true,
    layout: {
        padding: {
                bottom: 30
        }
    },
    scales: {
        y: {
            suggestedMax: 15000
        },
        x: {
            ticks: {
                minRotation: 90,
                maxRotation: 90,
            },
            grid: {
                display: false
            }
        }
        
    },
   
  }

class Financial extends React.Component {
    constructor() {
        super();
        this.refreshData = this.refreshData.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.state = {
          doughnutData: null,
          doughnutSetup: null,
          paretoData: null,
          paretoSetup: null,
          verticalBarData: null,
          verticalBarLabels: null,
          verticalBarSetup: null,
          horizontalBarData: null,
          horizontalBarLabels: null,
          horizontalBarSetup: null,
          verticalChartFilter: ['2019', '2020', '2021'],
          verticalBarPlugin: null,
          paretoFilter: 'Este mês',
          horizontalFilter: 'Este mês',
          usersCpf: 0,
          usersCnpj: 0,
          transactions: 0,
          newTransactions: 0,
          loading: true,
          updatedAt: null,
          open: false,
          documentId: null,
          sheets: null,
          confirmLoading: false,
          paretoLoading: false,
          horizontalLoading: false,
          verticalLoading: false,
        };
      }

    formatToBRL(value) {
        let brlString = value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        return brlString;
    }

    async componentDidMount() {
        let now = await this.getDataTime();
        this.setState({updatedAt: now})
        let initialFilter =  this.manageDateFilter('Este mês')
        let dataDoughnut;
        let dataPareto;
        let dataVertical;
        let dataHorizontal;
        let dataSummary;
        try {
            dataDoughnut = await ApiFinancial.getChartData('value?transactionYear[]=2019&transactionYear[]=2020&transactionYear[]=2021', 'person-type')
        } catch (error) {
            message.error('Falha ao carregar dados do gráfico rosca')
        }
        try {
            dataPareto = await ApiFinancial.getChartData(`product-type?${initialFilter}&isDESC=false`,'person-type')
        } catch (error) {
            message.error('Falha ao carregar dados do gráfico curva ABC')
        }
        try {
            dataVertical = await ApiFinancial.getChartData('auction-end-date?transactionYear[]=2019&transactionYear[]=2020&transactionYear[]=2021', 'person-type')
        } catch (error) {
            message.error('Falha ao carregar dados do gráfico barra vertical')
        }
        try {
            dataHorizontal = await ApiFinancial.getChartData(`amount-lots?${initialFilter}`, 'product-type');
        } catch (error) {
            message.error('Falha ao carregar dados do gráfico barra horizontal')
        }
        try {
            dataSummary =  await ApiFinancial.getChartData('','summary');
        } catch (error) {
            message.error("Falha ao carregar dados do sumário")
        }
        if(dataDoughnut) {
            this.setState({ doughnutData: dataDoughnut.datasets })
        }
        if(dataPareto) {
            this.setState({ paretoData: dataPareto});
        }
        if(dataVertical) {
            this.setState({ verticalBarData: dataVertical.datasets });
            this.setState({ verticalBarLabels: dataVertical.labels });
        }
        if(dataHorizontal){
            this.setState({ horizontalBarData: dataHorizontal.datasets });
            this.setState({ horizontalBarLabels: dataHorizontal.labels });
        }
        if(dataSummary){      
            this.setState({ usersCpf: dataSummary.totalUsersCpf});
            this.setState({ usersCnpj: dataSummary.totalUsersCnpj});
            this.setState({ transactions: dataSummary.totalTransactions});
            this.setState({newTransactions: dataSummary.totalNewTransactions });
        }
        await this.mountCharts();
        this.setState({loading: false})
    }

   async mountVerticalChartConfig() {
        let labelSize = this.state.verticalChartFilter.length;
        let defaultLabel = ['Jan', 'Fev', 'Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'];
        let finalArray = [];

        for(let i = 0; i < labelSize; i++){
            finalArray.push(...defaultLabel);
        }

        const subLabels = {
            id: 'subLabels',
            afterDatasetsDraw: chart => {
                const { ctx, chartArea: {bottom, width} } = chart;
        
        
                ctx.save();
        
                function subLabelText(text, x) { 
                    ctx.font = '700 16px Nunito';
                    ctx.fillStyle = '#BEC5CB';
                    ctx.fillText(text, x, bottom + 60);
                }
                let
                    years = this.state.verticalChartFilter.length;
   
                let numberYear = [];
                for(let i =0; i < years; i++){
                    numberYear.push(Number(this.state.verticalChartFilter[i]));
                }
                let sortedYears = numberYear.sort();
        
                for(let i =0; i < years; i++){
                    subLabelText(sortedYears[i], width/years * (i + 1) + 20);
                }
        
            }
          }
          
        this.setState({ verticalBarPlugin: subLabels })  
        this.setState({ verticalBarLabels: finalArray })
   } 


   async checkVerticalFilter(e){
        if(this.state.verticalChartFilter.includes(e.target.value)){
            let index = this.state.verticalChartFilter.indexOf(e.target.value);
            this.state.verticalChartFilter.splice(index, 1);
        }
        else {
            this.state.verticalChartFilter.push(e.target.value);
        }
        this.setState({ verticalLoading: true })    
        let filter = this.manageYearFilter();
        try {
            let dataVertical = await ApiFinancial.getChartData(`auction-end-date?${filter}`, 'person-type');
            this.setState({ verticalBarData: dataVertical.datasets });
            await this.mountVerticalChartConfig();
            this.setState({ verticalLoading: false })
        } catch (error) {
            this.setState({ verticalLoading: false })
            return;
        }
   }

   addZero(date) {
        if(date <= 9){
            return '0' + date.toString();
        }
        else {
            return date.toString();
        }
    }

   async handleOk() {
    if(this.state.sheets && this.state.documentId){
        let sheets = this.state.sheets.split(',');
        let body = {
            fileId: this.state.documentId,
            sheet: sheets
        }
        this.setState({confirmLoading: true})
        try {
            await ApiFinancial.postDocument(body);
            this.setState({confirmLoading: false})
            message.success('Sucesso ao importar documento')
        } catch (error) {
            this.setState({confirmLoading: false})
            message.error('Falha ao importar documento')
        }
        this.setState({open: false})
    }
    else {
        message.error('Falha ao importar documento')
        this.setState({open: false})
        return;
    }
   } 


   handleCancel(){
    this.setState({open: false})
   }

    async getDataTime() {
        let date = new Date();
        let mes = date.getMonth() + 1;
        let dia = date.getDate();
        let ano = date.getFullYear();
        let hora = date.getHours();
        let minutos = date.getMinutes();

        let string = `Atualizado em ${this.addZero(dia)}/${this.addZero(mes)}/${ano.toString()} às ${this.addZero(hora)}:${this.addZero(minutos)}`
        return string;
    }


    manageYearFilter() {
        let threeYears = this.state.verticalChartFilter.some( (element) => { return element === "Últimos 3 anos" })
        if(threeYears) {
            this.setState({verticalChartFilter: ['2020','2021','2022']})
            let filterString = `transactionYear[]=2020&transactionYear[]=2021&transactionYear[]=2022`
            return filterString;
        }
        let filterArray = this.state.verticalChartFilter.map((year) => `transactionYear[]=${year}&` )
        let filterString  = filterArray.join('');
        if(filterString[filterString.length - 1] === '&'){
            let newString = filterString.slice(0, -1);
            return newString;
        }
        else {
            return filterString;
        }

   }

   async setFilter(filter, type){
        let dateFilter;
        let filteredData;

        if(type === 'pareto'){
            this.setState({paretoLoading: true})
            this.setState( { paretoFilter: filter } )
            dateFilter = this.manageDateFilter(filter);
            try {
                filteredData = await ApiFinancial.getChartData(`product-type?${dateFilter}`, 'person-type');
                if(filteredData){
                    this.setState({ paretoData: filteredData});
                    await this.mountCharts() 
                }
                this.setState({paretoLoading: false})
            } catch (error) {
                this.setState({paretoLoading: false})
                return;
            }
        }
        else if (type === 'horizontal'){
            this.setState( { horizontalFilter: filter } )
            this.setState({horizontalLoading: true})
            dateFilter = this.manageDateFilter(filter);
            try {
                filteredData = await ApiFinancial.getChartData(`amount-lots?${dateFilter}`, 'product-type');
                if(filteredData){
                    this.setState({ horizontalBarSetup: filteredData});
                }
                this.setState({horizontalLoading: false})
            } catch (error) {
                this.setState({horizontalLoading: false})
                return;
            }
        }
   }

  manageDateFilter(filter) {
        let date = new Date();
        let formatedDate;
        switch(filter) {
            case 'Hoje':

                let today = date.toISOString().split('T')[0];
                return `auctionEndPeriodStart=${today}`

            case 'Este mês':

                let firstOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
                formatedDate = firstOfMonth.toISOString().split('T')[0];
                return `auctionEndPeriodStart=${formatedDate}`
            case 'YTD':
                let firstOfYear = new Date(date.getFullYear(),0,1);
                formatedDate = firstOfYear.toISOString().split('T')[0];
                return `auctionEndPeriodStart=${formatedDate}`
            case 'Últimos 12 meses':
                date.setMonth(date.getMonth() - 12);
                formatedDate = date.toISOString().split('T')[0];
                return `auctinEndPeriodStart=${formatedDate}`
            case 'Últimos 24 meses':
                date.setMonth(date.getMonth() - 24);
                formatedDate = date.toISOString().split('T')[0];
                return `auctinEndPeriodStart=${formatedDate}`
        }
  } 

  manageFullValue(){
    let total = this.state.doughnutSetup.datasets[0].data[0] + this.state.doughnutSetup.datasets[0].data[1];
    let length = total.toString().length;
    if(length >= 4 && length < 7){
        total = `${(total/1000).toFixed(0)}m`
        return total
    }
    else if(length >= 7 && length < 10){
        total = `${(total/1000000).toFixed(0)}M`
        return total
    }
    else if(length >= 10 && length < 13){
        total = `${ (total/1000000000).toFixed(0) }B`
        return total
    }
    else if(length >= 13 ){
        total = `${(total/1000000000000).toFixed(0)}T`
        return total
    }
    else{
        return total
    }
  }

    async mountCharts(refresh) {
        if(refresh) {
            if(this.state.paretoData.datasets){
                let datapoints = [];
                let paretoData;
                this.state.paretoData.datasets.forEach((el) => el.data.forEach((datapoint) => datapoints.push(datapoint)))
                if(datapoints.length !== 0){
                    const reduceArray = ((sum, datapoint) => sum + datapoint);
                    const totalSum = datapoints.reduce(reduceArray)
          
                   const arraySum = ((sum) => datapoint => sum += datapoint)(0);
                   const cumulativeDataPoints = datapoints.map(arraySum);
                   const percentageDataPoints = cumulativeDataPoints.map( (value) => {
                      const values = parseFloat(value/totalSum * 100).toFixed(1);
                      return values;
                   })
    
                   let line = { 
                    label: 'line',
                    data: percentageDataPoints,
                    type: 'line',
                    borderColor: '#4197E2',
                    drawActiveElementsOnTop: false,
                    fill: false
                  }
                  paretoData = {
                    labels: this.state.paretoData.labels,
                    datasets: [...this.state.paretoData.datasets,line ]
        
                  }
                }
                else {
                    paretoData = {
                        labels: this.state.paretoData.labels,
                        datasets: this.state.paretoData.datasets
                    }
                }
                 this.setState({ paretoSetup: paretoData });
            }
        }
        else {        
            let radialData;
            if(this.state.doughnutData){
                radialData = {
                   labels: [
                     "PF",
                     "PJ"
                   ],
                   datasets: [{
                     label: 'Acumulado',
                     data: [this.state.doughnutData[1].data[0], this.state.doughnutData[0].data[0]],
                     backgroundColor: [
                       '#FF6600',
                       '#BE00FF'
                     ],
                     borderWidth: 1,
                     hoverOffset: 4,
                     cutout: "92%",
                     borderRadius: 8
                   }]
                 };
                 this.setState({ doughnutSetup: radialData });
           }
           if(this.state.paretoData.datasets){
            let datapoints = [];
            let paretoData;
            this.state.paretoData.datasets.forEach((el) => el.data.forEach((datapoint) => datapoints.push(datapoint)))
            if(datapoints.length !== 0){
                const reduceArray = ((sum, datapoint) => sum + datapoint);
                const totalSum = datapoints.reduce(reduceArray)
      
               const arraySum = ((sum) => datapoint => sum += datapoint)(0);
               const cumulativeDataPoints = datapoints.map(arraySum);
               const percentageDataPoints = cumulativeDataPoints.map( (value) => {
                  const values = parseFloat(value/totalSum * 100).toFixed(1);
                  return values;
               })

               let line = { 
                label: 'line',
                data: percentageDataPoints,
                type: 'line',
                borderColor: '#4197E2',
                drawActiveElementsOnTop: false,
                fill: false
              }
              paretoData = {
                labels: this.state.paretoData.labels,
                datasets: [...this.state.paretoData.datasets,line ]
    
              }
            }
            else {
                paretoData = {
                    labels: this.state.paretoData.labels,
                    datasets: this.state.paretoData.datasets
                }
            }
             this.setState({ paretoSetup: paretoData });
        }
               await  this.mountVerticalChartConfig();
   
   
            if(this.state.verticalBarLabels){
               let verticalBarData;
               if(this.state.verticalBarData){
                    verticalBarData = {
                       labels: this.state.verticalBarLabels,
                       datasets: this.state.verticalBarData
                     }
               }
               else {
                   verticalBarData = {
                       labels: this.state.verticalBarLabels,
                       datasets: []
                   }
               }
               this.setState({ verticalBarSetup: verticalBarData });
            }   
   
            if(this.state.horizontalBarLabels){
               let horizontalBarData; 
               if(this.state.horizontalBarData){
                   horizontalBarData = {
                       labels: this.state.horizontalBarLabels,
                       datasets: this.state.horizontalBarData
                     }
               }
               else {
                   horizontalBarData = {
                       labels: this.state.horizontalBarLabels,
                       datasets: []
                   }
               }          
                this.setState({ horizontalBarSetup: horizontalBarData });
            }
        }
      
    } 

    async refreshData() {
        let now = await this.getDataTime();
        let refresh = true;
        this.setState({updatedAt: now})
        this.setState({ loading: true});
        let paretoFilterString = this.manageDateFilter(this.state.paretoFilter);
        let horizontalFilterString = this.manageDateFilter(this.state.horizontalFilter);
        let verticalFilterString = this.manageYearFilter(this.state.verticalChartFilter);
        try {
            const dataDoughnut = await ApiFinancial.getChartData('value?transactionYear[]=2019&transactionYear[]=2020&transactionYear[]=2021', 'person-type');
            const dataPareto = await ApiFinancial.getChartData(`product-type?${paretoFilterString}`, 'person-type');
            const dataVertical = await ApiFinancial.getChartData(`auction-end-date?${verticalFilterString}`, 'person-type');
            const dataHorizontal = await ApiFinancial.getChartData(`amount-lots?${horizontalFilterString}`, 'product-type');
            if(dataDoughnut){
                this.setState({ doughnutData: dataDoughnut.datasets })
            }
            if(dataPareto){
                this.setState({ paretoData: dataPareto});
            }
            if(dataVertical){
                this.setState({verticalBarSetup: dataVertical})
            }
            if(dataHorizontal){
                this.setState({ horizontalBarSetup: dataHorizontal})
            }
        } catch (error) {
            this.setState({loading: false})
            return;
        }
        await this.mountCharts(refresh);
        this.setState({loading: false})
    }
    

    render () {
        const { 
            doughnutSetup, 
            paretoSetup, 
            verticalBarSetup, 
            horizontalBarSetup, 
            usersCpf,
            usersCnpj, 
            transactions, 
            newTransactions,
            loading,
            updatedAt
            } = this.state;

        return (

            <Layout>
            {loading ? <Spin size="default"></Spin> : 
                <Content>
                    <Modal
                    title="Atualizar Planilha"
                    open={this.state.open}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    confirmLoading={this.state.confirmLoading}
                    >
                        <Input placeholder="Id do documento" style={{marginBottom: '10px'}} onChange={(e) => this.setState({documentId: e.target.value})}></Input>
                        <Input placeholder="Abas, exemplo: 'aba1,aba2,aba3'" onChange={(e) => this.setState({sheets: e.target.value})}></Input>

                    </Modal>
                    <div className="financial__header">
                        <div>
                            <p className="financial__headerTitle">Análise de compra</p>
                            <p className="financial__headerSubTitle">{updatedAt}</p>
                        </div>
                        <div><button className="financial__uploadButton" onClick={() => this.setState({open: true})}><UploadIcon/>Enviar planilha</button></div>
                    </div>
                    <div className="financial__buttonDiv">
                        <button className='financial__button' onClick={this.refreshData}>Atualizar</button>
                    </div>
                <Row style={{gap: '24px', justifyContent: 'center'}}>
                    <Col span={16}>
                    <Row justify="space-between">
                        <Col>
                            <Card 
                            className="financial__card"
                            bodyStyle={{padding: '0px'}}
                            >
                                <p className="financial__data">{this.formatToBRL(transactions)}</p>
                                <p className="financial__subtitle">Transacionados</p>
                            </Card>
                        </Col>
                        <Col>
                            <Card 
                            className="financial__card"
                            bodyStyle={{padding: '0px'}}
                            >
                                <p className="financial__data">{this.formatToBRL(newTransactions)}</p>
                                <p className="financial__subtitle">Novas transações</p>
                            </Card>
                        </Col>
                        <Col>
                            <Card 
                            className="financial__card"
                            bodyStyle={{padding: '0px'}}
                            >
                                <p className="financial__data">{`${usersCnpj} PJ | ${usersCpf} PF`}</p>
                                <p className="financial__subtitle">Usuários</p>
                            </Card>
                        </Col>


                    </Row>
                        <Row style={{marginTop: '24px'}}>
                            <Col style={{width: '100%'}}>
                        {paretoSetup ?
                            <Card style={{borderRadius: '16px'}} bodyStyle={{borderRadius: '16px'}}>    
                                <p className="financial__title" style={{marginBottom: '0px'}}>Curva ABC</p>
                                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '30px'}} >
                                    <div style={{display: 'flex', alignItems: 'center', marginRight: '6px'}} >
                                        <div className="financial__legend" style={{backgroundColor: '#BE00FF'}}></div><span className="financial__legendText">Categoria A</span>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', marginRight: '6px'}}>
                                        <div className="financial__legend" style={{backgroundColor: '#FF6600'}}></div><span className="financial__legendText">Categoria B</span>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', marginRight: '6px'}}>
                                        <div className="financial__legend" style={{backgroundColor: '#66696F'}}></div><span className="financial__legendText">Categoria C</span>
                                    </div>
                                </div>
                                {this.state.paretoLoading ? <div className="financial__loading"> <Spin  size="default"></Spin></div>  :   
                                <>                     
                                <div className="financial__filterContainer">
                                    <span className={`${this.state.paretoFilter === 'Hoje' ? 'financial__selected' : ''} financial__filter`} onClick={(e) => this.setFilter(e.target.innerText, 'pareto')}>Hoje</span>
                                    <span className={`${this.state.paretoFilter === 'Este mês' ? 'financial__selected' : ''} financial__filter`} onClick={(e) => this.setFilter(e.target.innerText, 'pareto')}>Este mês</span>
                                    <span className={`${this.state.paretoFilter === 'YTD' ? 'financial__selected' : ''} financial__filter`} onClick={(e) => this.setFilter(e.target.innerText, 'pareto')}>YTD</span>
                                    <span className={`${this.state.paretoFilter === 'Últimos 12 meses' ? 'financial__selected' : ''} financial__filter`} onClick={(e) => this.setFilter(e.target.innerText, 'pareto')}>Últimos 12 meses</span>
                                    <span className={`${this.state.paretoFilter === 'Últimos 24 meses' ? 'financial__selected' : ''} financial__filter`} onClick={(e) => this.setFilter(e.target.innerText, 'pareto')}>Últimos 24 meses</span>
                                </div>
                                <Bar data={paretoSetup} options={options}/>
                                </> }

                            </Card>: <p>Loading</p> }
                                
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        {doughnutSetup ? 
                        <Card className="financial__doughnut" >
                                <p className="financial__title">Acumulado</p>
                            <div className="financial__chartDiv">
                                <Doughnut data={doughnutSetup}></Doughnut>
                                <p className="financial__chartText">{this.manageFullValue()}</p>
                            </div> 
                            <div className="financial__doughnutFilter">
                                <p className="financial__doughnutFilterText">
                                    Year to date
                                    <ArrowDown  style={{marginLeft: '5px'}}/>
                                </p>
                            </div>
                            <div className="financial__doughnutTotal">
                                    <p className="financial__doughnutTotalText" style={{marginBottom: '30px'}}><span className="financial__purpleText">PF</span>{this.formatToBRL(this.state.doughnutSetup.datasets[0].data[0])}</p>
                                    <p className="financial__doughnutTotalText"><span className="financial__purpleText">PJ</span>{this.formatToBRL(this.state.doughnutSetup.datasets[0].data[1])}</p>
                            </div>
                        </Card> : <p>Loading</p> }

                    </Col>
                </Row>
                <Row style={{marginTop: '20px', justifyContent: 'center'}}>
                    <Col style={{width: '93.5%'}}>
                    {verticalBarSetup ? 
                    <Card style={ {borderRadius: '16px'}} bodyStyle={{height: '100%'}}>
                    <p className="financial__title">Faturamento Mensal</p>
                    {this.state.verticalLoading ?  <div className="financial__loading"> <Spin  size="default"></Spin></div> :
                    <>
                    <div className="financial__yearFilter"> 
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="checkbox" id='3years' defaultChecked={this.state.verticalChartFilter.includes('2020') && this.state.verticalChartFilter.includes('2021') && this.state.verticalChartFilter.includes('2022') } value="Últimos 3 anos" onChange={(e) => this.checkVerticalFilter(e)} name="Últimos 3 anos" />
                        <label className="financial__label" for="3years">Últimos 3 anos</label>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="checkbox" id='2022'  defaultChecked={this.state.verticalChartFilter.includes('2022')} onChange={(e) => this.checkVerticalFilter(e)} value='2022' name="2022" />
                        <label className="financial__label" for="2022">2022</label>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="checkbox" id='2021' defaultChecked={this.state.verticalChartFilter.includes('2021')} value='2021' onChange={(e) => this.checkVerticalFilter(e)} name="2021" />
                        <label className="financial__label" for="2021">2021</label>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>                
                        <input type="checkbox" id='2020' defaultChecked={this.state.verticalChartFilter.includes('2020')} onChange={(e) => this.checkVerticalFilter(e)} value='2020' name="2020" />
                        <label className="financial__label" for="2020">2020</label>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="checkbox" id='2019' defaultChecked={this.state.verticalChartFilter.includes('2019')} value='2019'  onChange={(e) => this.checkVerticalFilter(e)} name="2019" />
                        <label className="financial__label" for="2019">2019</label>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="checkbox" id='2018' value='2018' onChange={(e) => this.checkVerticalFilter(e)} name="2018" />
                        <label className="financial__label" for="2018">2018</label>
                    </div>
                </div>
                <Bar data={verticalBarSetup}  options={secondBarOption} plugins={[this.state.verticalBarPlugin]}/> 
                </>}
                    
                </Card> : <p>Loading</p>}
                    
                    </Col>
                </Row>
                <Row style={{marginTop: '20px', justifyContent: 'center'}}>
                    <Col style={{width: '93.5%'}}>
                    {horizontalBarSetup ? 
                    <Card style={{borderRadius: '16px'}}>
                        <p className="financial__title">Faturamento Mensal</p>
                        {this.state.horizontalLoading ? <div className="financial__loading"> <Spin  size="default"></Spin></div> :              
                        <>          
                        <div className="financial__filterContainer">
                            <span className={`${this.state.horizontalFilter === 'Hoje' ? 'financial__selected' : ''} financial__filter`} onClick={(e) => this.setFilter(e.target.innerText, 'horizontal')}>Hoje</span>
                            <span className={`${this.state.horizontalFilter === 'Este mês' ? 'financial__selected' : ''} financial__filter`} onClick={(e) => this.setFilter(e.target.innerText, 'horizontal')}>Este mês</span>
                            <span className={`${this.state.horizontalFilter === 'YTD' ? 'financial__selected' : ''} financial__filter`} onClick={(e) => this.setFilter(e.target.innerText, 'horizontal')}>YTD</span>
                            <span className={`${this.state.horizontalFilter === 'Últimos 12 meses' ? 'financial__selected' : ''} financial__filter`} onClick={(e) => this.setFilter(e.target.innerText, 'horizontal')}>Últimos 12 meses</span>
                            <span className={`${this.state.horizontalFilter === 'Últimos 24 meses' ? 'financial__selected' : ''} financial__filter`} onClick={(e) => this.setFilter(e.target.innerText, 'horizontal')}>Últimos 24 meses</span>
                        </div>
                        <Bar data={horizontalBarSetup} options={horizontalConfig} />
                        </> 
                        }

                    </Card> : <p>Loading</p>}
                    
                    </Col>
                </Row>
                </Content>
        }
            </Layout>
        )
    }
}


export default Financial;