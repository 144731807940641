import Immutable from 'seamless-immutable';
import { CommandActions } from '../actions';

const initialState = Immutable({
  dataPaginated: null,
  dataDetails: null,
  dataHistory: [],
});

export default function command(
  state = initialState,
  action,
) {
  switch (action.type) {
    case CommandActions.ACTION_SAVE_COMMANDS_PAGINATED:
      state = {
        ...state,
        dataPaginated: action.payload,
      };
      return state;
    case CommandActions.ACTION_SAVE_COMMAND_DETAILS:
      state = {
        ...state,
        dataDetails: action.payload,
      };
      return state;
    case CommandActions.ACTION_CLEAN_COMMAND:
      state = {
        ...initialState,
      };
      return state;
    case CommandActions.ACTION_SAVE_COMMAND_HISTORY:
      state = {
        ...state,
        dataHistory: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getDataPaginated(state) {
  return state.command.dataPaginated;
}

export function getDataDetails(state) {
  return state.command.dataDetails;
}

export function getDataHistory(state) {
  return state.command.dataHistory;
}
